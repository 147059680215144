import { useEffect, useState, useRef, DragEvent } from 'react';

const useDraggable = () => {
    const [isDragging, setIsDragging] = useState(false);
    const dragItemRef = useRef<HTMLElement>(null);

    useEffect(() => {
        const handleDragStart = (e: DragEvent) => {
            e.dataTransfer.setData('text/plain', (e.target as HTMLElement).id);
            setIsDragging(true);
        };

        const handleDragEnd = () => {
            setIsDragging(false);
        };

        const dragItem = dragItemRef?.current;
        if (!dragItem) return;

        dragItem.addEventListener('dragstart', handleDragStart);
        dragItem.addEventListener('dragend', handleDragEnd);

        return () => {
            if (dragItem) {
                dragItem.removeEventListener('dragstart', handleDragStart);
                dragItem.removeEventListener('dragend', handleDragEnd);
            }
        };
    }, [dragItemRef]);

    return { dragItemRef, isDragging };
};

export default useDraggable;
