import './index.scss';
import { Icon, X } from 'react-feather';

interface FloatMenuInterface {
    menus: {
        label: string;
        onClick: (e: React.MouseEvent<HTMLElement>) => void;
        icon?: Icon | JSX.Element;
        variant?: string;
        disabled?: boolean;
    }[];
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    fixedWidth?: number;
    onClose?: () => void;
}

const FloatMenu: React.FC<FloatMenuInterface> = ({
    menus = [],
    bottom,
    right,
    left,
    top,
    onClose,
    fixedWidth,
}) => {
    return (
        <div
            className="dg-float-menu animate__animated animate__fadeIn animate__faster"
            style={{
                top: top !== undefined ? top + 'px' : 'unset',
                left: left !== undefined ? left + 'px' : 'unset',
                bottom: bottom !== undefined ? bottom + 'px' : 'unset',
                right: right !== undefined ? right + 'px' : 'unset',
                width: fixedWidth ? fixedWidth + 'px' : 'unset',
            }}
        >
            {menus.map((menu) => (
                <div
                    key={menu.label}
                    className={`dg-float-menu__item dg-float-menu__item-${
                        menu.variant
                    } ${menu.disabled ? 'dg-float-menu__item-disabled' : ''}`}
                    onClick={menu.onClick}
                >
                    {menu.icon && <menu.icon />}
                    {menu.label}
                </div>
            ))}
            {onClose && (
                <div className="dg-float-menu__close">
                    <X />
                </div>
            )}
        </div>
    );
};

export default FloatMenu;
