import { PropsWithChildren, createContext, useState } from 'react';
import { AuthContextInterface } from '~/interfaces/contexts';
import {
    LoginUserDTO,
    User,
    UserSessionPeriod,
} from '../../interfaces/entities/User.interface';
import { sessionServices, authenServices } from '~/services';

export const AuthContext = createContext<AuthContextInterface | null>(null);

// it's necessary to get the user data from the  begginning to avoid returning null in the first render
const initialSessionData = sessionServices.getUserData();

const AuthProvider = ({ children }: PropsWithChildren) => {
    const [sessionData, setSessionData] = useState<UserSessionPeriod | null>(
        initialSessionData
    );

    const [user, setUser] = useState<User | null>(
        initialSessionData?.user || null
    );

    const login = async (credentials: LoginUserDTO) => {
        const session = await authenServices.createSession(credentials);

        if (session.sessionData) {
            const { user: sessionUser, ...rest } = session.sessionData;
            setUser(sessionUser);
            setSessionData(rest);
        }

        return session;
    };

    const logout = async () => {
        sessionServices.cleanSessionData();
        setUser(null);
    };

    const updateUser = (newUser: Partial<User>) => {
        if (!user) return;

        const updatedUser = { ...user, ...newUser };

        setUser(updatedUser);

        //update session data
        sessionServices.writeSessionData({ ...sessionData, user: updatedUser });
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                sessionData,
                isAuth: Boolean(user),
                login,
                logout,
                updateUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
