import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    PagedSearchResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    AISearchResponseDTO,
    DocumentSearchResponseDTO,
    ParagraphSearchResponseDTO,
    PostSearchAIDTO,
    PostSearchDTO,
} from '~/modules/Search/types/search.interface';
import {
    CreateSavedSearchDTO,
    SavedSearchInterface,
    SavedSearchInterfaceDetailed,
    UpdateSavedSearchDTO,
} from '~/modules/Search/types/savedSearch.interface';

const searchServices = {
    searchDocuments,
    searchParagraphs,
    saveSearch,
    updateSearch,
    deleteSearch,
    getSavedSearches,
    searchAI,
};
export default searchServices;

async function searchDocuments(params: PostSearchDTO) {
    const url = environment.apiUrl + '/searches/documents';
    return requestAdapterInstance.post<
        PagedSearchResponse<DocumentSearchResponseDTO>,
        PostSearchDTO
    >(url, params);
}

async function searchParagraphs(params: PostSearchDTO) {
    const url = environment.apiUrl + '/searches/paragraphs';
    return requestAdapterInstance.post<
        PagedSearchResponse<ParagraphSearchResponseDTO>,
        PostSearchDTO
    >(url, params);
}

// TODO?: create fc to save a search
async function saveSearch(search: CreateSavedSearchDTO) {
    const url = environment.apiUrl + '/saved_searches';
    return requestAdapterInstance.post<
        UnpagedResponse<SavedSearchInterface>,
        CreateSavedSearchDTO
    >(url, search);
}

// TODO?: create fc to get saved searches
async function getSavedSearches(options: Record<string, unknown> = {}) {
    const url = `${
        environment.apiUrl
    }/saved_searches/detailed?${queryString.stringify(options)}`;
    return requestAdapterInstance.get<
        PagedResponse<SavedSearchInterfaceDetailed>
    >(url);
}

async function updateSearch(search: UpdateSavedSearchDTO, searchId: string) {
    const url = `${environment.apiUrl}/saved_searches/${searchId}`;
    return requestAdapterInstance.put<
        UnpagedResponse<SavedSearchInterface>,
        UpdateSavedSearchDTO
    >(url, search);
}

async function deleteSearch(searchId: string) {
    const url = `${environment.apiUrl}/saved_searches/${searchId}`;
    return requestAdapterInstance.delete<null, null>(url);
}

// AI SEARCH
async function searchAI(params: PostSearchAIDTO) {
    const url = environment.apiUrl + '/ai_searches/paragraphs';
    return requestAdapterInstance.post<
        UnpagedResponse<AISearchResponseDTO>,
        PostSearchAIDTO
    >(url, params);
}
