import { useState } from 'react';
import './index.scss';
import TextField from '../TextField';
import { useTemporaryErrorDisplay } from '../../../hooks';
import Button from '../Button';
import { useIntl } from 'react-intl';

const mainClass = 'cg-basic-field-modal';

interface BasicFieldFormInterface {
    title?: string;
    confirmText?: string;
    onSubmit: (field: string) => void;
    className?: string;
    placeholder: string;
    loading: boolean;
    label?: string;
    initialValue?: string;
}

const BasicFieldForm: React.FC<BasicFieldFormInterface> = ({
    title = '',
    confirmText = '',
    onSubmit,
    className = '',
    placeholder,
    loading,
    label,
    initialValue = '',
}) => {
    const intl = useIntl();

    const [field, setField] = useState(initialValue);

    const { error, handleError } = useTemporaryErrorDisplay();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!field.trim()) {
            handleError('required_field');
            return;
        }

        onSubmit(field);
        setField('');
    };

    return (
        <div className={`${mainClass} ${className} `}>
            {title && <h4> {title} </h4>}
            <form onSubmit={handleSubmit}>
                <TextField
                    inputProps={{
                        onChange: (e) => setField(e.target.value),
                        value: field,
                        autoFocus: true,
                        placeholder,
                        disabled: loading,
                    }}
                    label={label}
                    error={error && intl.formatMessage({ id: error })}
                />

                <Button variant="accent" type="submit" disabled={loading}>
                    {confirmText}
                </Button>
            </form>
        </div>
    );
};

export default BasicFieldForm;
