import { useMemo, useState } from 'react';
import './CustomColumnHeader.scss';
import { useColumnsFilter, useFloatMenu } from '../../hooks';
import { BasicMenuList, DeleteConfirmationDialog } from '../UIElements';
import {
    ChevronDown,
    Edit3,
    Filter,
    MoreHorizontal,
    Trash2,
} from 'react-feather';
import NewColumnModal from './NewColumnModal';
import { Popover } from 'react-tiny-popover';
import FilterMenu from './FilterMenu';
import { IntlShape } from 'react-intl';
import {
    ColumnFilter,
    ColumnValueOption,
    SortingParameters,
    onApplyFilter,
    resetFilterColumn,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { DynamicPropertyInterfaceDetailed } from '~/interfaces/entities';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';

interface CustomColumnHeaderParams {
    column: DynamicPropertyInterfaceDetailed;
    onRemove: () => void;
    onEdit: (newCol: any) => void;
    intl: IntlShape;
    canEdit: boolean;
    resetFilterColumn: resetFilterColumn;
    columnFilters: ColumnFilter[];
    onApplyFilter: onApplyFilter;
    columnValues: ColumnValueOption[];
    sortParams: SortingParameters;
    sortFieldName: string;
}

const CustomColumnHeader: React.FC<CustomColumnHeaderParams> = ({
    column,
    onRemove,
    onEdit,
    intl,
    canEdit,
    //
    resetFilterColumn,
    columnFilters,
    onApplyFilter,
    columnValues,
    sortParams,
    sortFieldName,
}) => {
    const menu = useFloatMenu();
    const deleteMenu = useFloatMenu();
    const [editing, setEditing] =
        useState<DynamicPropertyInterfaceDetailed | null>(null);

    const MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'edit_column_name' }),
                icon: Edit3,
                onClick: () => {
                    menu.setShowMenu(false);
                    setEditing(column);
                },
            },
            {
                label: intl.formatMessage({ id: 'remove_column' }),
                icon: Trash2,
                onClick: () => {
                    menu.setShowMenu(false);
                    deleteMenu.setShowMenu(true);
                },
                variant: 'distructive',
            },
        ],
        [column]
    );

    const columnsFilterData = useColumnsFilter({
        columnName: column.id,
        resetFilterColumn,
        columnFilters,
        onApplyFilter,
        columnValues,
        sortParams,
        sortFieldName,
        isDynamicColumnFilter: true,
    });

    const isSortable =
        column?.type !== DYNAMIC_PROPERTY_TYPES.MULTI_SELECT &&
        column?.type !== DYNAMIC_PROPERTY_TYPES.SELECT;

    return (
        <div className="custom-column-header">
            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    title={intl.formatMessage({ id: 'remove_column' })}
                    text={intl.formatMessage({ id: 'delete_column_text' })}
                    buttonText={intl.formatMessage({ id: 'remove' })}
                    callback={() => {
                        deleteMenu.handleMenu();
                        onRemove();
                    }}
                />
            )}
            {editing && (
                <NewColumnModal
                    intl={intl}
                    handleClose={() => setEditing(null)}
                    column={editing}
                    callback={(newCol) => {
                        setEditing(null);
                        onEdit(newCol);
                    }}
                    isEdit
                />
            )}
            <span>{column.name} </span>

            <div className="custom-column-header__actions">
                <Popover
                    isOpen={columnsFilterData?.filterMenu.showMenu}
                    positions={['bottom', 'left', 'right']}
                    align="end"
                    content={
                        <FilterMenu
                            intl={intl}
                            isSortable={isSortable}
                            {...columnsFilterData}
                        />
                    }
                    onClickOutside={() =>
                        columnsFilterData?.filterMenu.setShowMenu(false)
                    }
                >
                    <div className="column-filter-header-body">
                        {columnsFilterData.isFiltered ? (
                            <Filter
                                onClick={
                                    columnsFilterData?.filterMenu.handleMenu
                                }
                            />
                        ) : (
                            <ChevronDown
                                onClick={
                                    columnsFilterData?.filterMenu.handleMenu
                                }
                            />
                        )}
                    </div>
                </Popover>

                {canEdit && (
                    <Popover
                        isOpen={menu.showMenu}
                        align={'end'}
                        positions={['bottom', 'right']}
                        content={<BasicMenuList menus={MENUS} />}
                        onClickOutside={() => menu.setShowMenu(false)}
                    >
                        <MoreHorizontal
                            onClick={(e) => {
                                menu.handleMenu();
                                e.stopPropagation();
                            }}
                        />
                    </Popover>
                )}
            </div>
        </div>
    );
};

export default CustomColumnHeader;
