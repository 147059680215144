import { useRef, useState, useEffect, useCallback, DragEvent } from 'react';
import { Check, MoreHorizontal } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import { PERMISSIONS } from '~/constants/memberPermissions';
import { ENTITIES } from '~/constants/entities';
import { FolderItemTypes } from '~/constants/folders';
import {
    FilledFolderTreeInterface,
    FolderInterface,
} from '~/interfaces/entities';
import useItem from './useItem.hook';
import { useDrop } from '~/hooks';
import { FolderIcon, OpenedFolderIcon } from '~/assets/icons/shared';
import { BasicMenuList } from '~/components/UIElements';
import RestrictedUI from '~/components/RestrictedUI';
import FolderDialogs from '../FolderDialogs';
import IconButton from '../../IconButton';
import classNames from 'classnames';

import styles from './FolderItem.module.scss';

interface FolderItemProps {
    folder: FilledFolderTreeInterface;
    showActions?: boolean;
    isChecked?: boolean;
    isExpanded: boolean;
    showItemsCount?: boolean;
    folderItemsType?: FolderItemTypes;
    forceExpandFolder?: (folderId: string) => void;
    onCreateFolderItem?: (itemName: string, parentId: string) => void;
    onCreateSubfolder?: (newFolderName: string, parentId: string) => void;
    onFolderClick: (folder: FilledFolderTreeInterface) => void;
    onFolderIconClick?: () => void;
    onUpdateFolder?: (folder: FolderInterface) => void;
    onDeleteFolder?: (folderId: string) => void;
    onMoveItem?: ({ id, folderId }: { id: string; folderId: string }) => void;
}

const FolderItem = ({
    folder = {} as FilledFolderTreeInterface,
    showActions = true,
    isChecked,
    isExpanded,
    showItemsCount = true,
    onFolderClick,
    onUpdateFolder,
    onDeleteFolder,
    onFolderIconClick,
    folderItemsType,
    onCreateFolderItem,
    onCreateSubfolder,
    forceExpandFolder,
    onMoveItem,
}: FolderItemProps) => {
    const {
        ACTION_MENUS,
        actionsMenu,
        renameMenu,
        deleteMenu,
        createSubfolderMenu,
        createTopicMenu,
        createTagMenu,
        folderMenuVisible,
        onClickIcon,
        handleFolderClick,
        handleFolderCreation,
        expandFolder,
        onCreateTag,
        handleDeleteFolder,
    } = useItem({
        folder,
        folderItemsType,
        showActions,
        isExpanded,
        onFolderClick,
        onUpdateFolder,
        onDeleteFolder,
        onFolderIconClick,
        onCreateFolderItem,
        onCreateSubfolder,
        forceExpandFolder,
    });

    const cardRef = useRef<HTMLDivElement | null>(null);
    const { handleDragOver, handleDragLeave, handleDrop, isDraggingOver } =
        useDrop(expandFolder);

    const [displayMenu, setDisplayMenu] = useState(false);

    useEffect(() => {
        setDisplayMenu(showActions && !!cardRef?.current);
    }, [showActions, cardRef?.current]);

    const getFolderIcon = useCallback(() => {
        if (isChecked) return <Check />;

        return isExpanded ? (
            <OpenedFolderIcon onClick={onClickIcon} />
        ) : (
            <FolderIcon onClick={onClickIcon} />
        );
    }, [isChecked, isExpanded]);

    const onDropFolder = (itemId: string) => {
        onMoveItem && onMoveItem({ folderId: folder.id, id: itemId });
    };

    return (
        <div
            ref={cardRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e: DragEvent<HTMLDivElement>) =>
                handleDrop(e, onDropFolder)
            }
            className={classNames(styles.cgFolderItem, {
                [styles['cgFolderItem--dragOver']]: isDraggingOver,
            })}
        >
            <div
                onClick={handleFolderClick}
                className={classNames(styles.cgFolderItem__content, {
                    [styles['cgFolderItem__content--checked']]: isChecked,
                    [styles['cgFolderItem__content--menuOpen']]:
                        folderMenuVisible,
                })}
            >
                {getFolderIcon()}
                <p className={styles.cgFolderItem__text}>
                    {folder.name}{' '}
                    {showItemsCount && (
                        <span className={styles.cgFolderItem__count}>
                            {folder.items_count}
                        </span>
                    )}
                </p>
            </div>

            {displayMenu && (
                <RestrictedUI entity={ENTITIES.TOPICS} to={PERMISSIONS.EDIT}>
                    <Popover
                        isOpen={actionsMenu.showMenu}
                        positions={['right']}
                        align="start"
                        onClickOutside={actionsMenu.handleMenu}
                        content={<BasicMenuList menus={ACTION_MENUS} />}
                        parentElement={cardRef.current!}
                    >
                        <IconButton
                            icon={MoreHorizontal}
                            onClick={actionsMenu.handleMenu}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    actionsMenu.handleMenu();
                                }
                            }}
                            tabIndex={0}
                            variant="secondary"
                            className={classNames(styles.cgFolderItem__button, {
                                [styles['cgFolderItem__button--visible']]:
                                    folderMenuVisible,
                            })}
                        />
                    </Popover>
                </RestrictedUI>
            )}

            <FolderDialogs
                cardRef={cardRef}
                folder={folder}
                deleteMenu={deleteMenu}
                createSubfolderMenu={createSubfolderMenu}
                renameMenu={renameMenu}
                createTagMenu={createTagMenu}
                createTopicMenu={createTopicMenu}
                onDeleteFolder={handleDeleteFolder}
                handleFolderCreation={handleFolderCreation}
                onCreateTag={onCreateTag}
                onCreateTopic={expandFolder}
            />
        </div>
    );
};

export default FolderItem;
