import { useState, useEffect } from 'react';
import { getAvailableSpaceForTable } from '~/helpers/getAvailableSpaceForTable';

/**
    The @containerClassname is the classname of the parent container of the table. This is what wraps the table but also the header of the table
    The offset is the number of pixels you want to subtract from the total height, and it is composed by the height of all the containers that are
    direct siblings of the table. In general, as sibling of the the table we have the header that appears when items are selected.
    Important: when calculating the offset, also take into account the GAP propperty between sibling containers
 */
const useFlexTableHeight = ({ containerClassName = '', offset = 0 }) => {
    const [tableHeight, setTableHeight] = useState(0);

    const calculateHeight = () => {
        setTableHeight(getAvailableSpaceForTable(containerClassName, offset));
    };

    useEffect(() => calculateHeight());
    return tableHeight;
};

export default useFlexTableHeight;
