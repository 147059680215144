import { forwardRef } from 'react';
import './index.scss';
import styleClass from '~/helpers/componentClassGenerator';
import { Icon } from 'react-feather';

interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'secondary' | 'tertiary';
    className?: string;
    disabled?: boolean;
    icon: Icon;
}

const IconButton = forwardRef(
    (
        {
            variant = 'primary',
            className = '',
            icon: Icon,
            disabled,
            ...rest
        }: IconButtonProps,
        ref
    ) => {
        const styles = styleClass([variant], 'dg-icon-button');
        return (
            <button
                className={`dg-icon-button ${className} ${styles}`}
                disabled={disabled}
                ref={ref}
                {...rest}
            >
                <Icon />
            </button>
        );
    }
);

export default IconButton;
