export enum PROJECT_MEMBER_ROLES {
    ADMIN = 'admin',
    READ_ONLY = 'read_only',
    USER = 'user',
}

export enum PROJECT_MEMBER_INVITATION_ROLES {
    ADMIN = PROJECT_MEMBER_ROLES.ADMIN,
    USER = PROJECT_MEMBER_ROLES.USER,
    READ_ONLY = PROJECT_MEMBER_ROLES.READ_ONLY,
}

export enum WORKSPACE_MEMBER_ROLES {
    OWNER = 'owner',
    ADMIN = 'admin',
    USER = 'user',
}

export enum WORKSPACE_MEMBER_INVITATION_ROLES {
    ADMIN = WORKSPACE_MEMBER_ROLES.ADMIN,
    USER = WORKSPACE_MEMBER_ROLES.USER,
}

export enum CREATE_WORKSPACE_MEMBER_INVITATION_MODEL {
    WORKSPACE_MEMBER = 'workspace_member',
    WORKSPACE_MEMBER_INVITATION = 'workspace_member_invitation',
}
