import React, { useMemo, useState } from 'react';
import './AddColumnHeader.scss';
import { BasicMenuList, IconButton } from '../UIElements';
import {
    AlignLeft,
    Hash,
    Plus,
    ToggleRight,
    List,
    ChevronDown,
} from 'react-feather';
import { useFloatMenu } from '../../hooks';
import { NewColumnModal } from '.';
import { Popover } from 'react-tiny-popover';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';
import { IntlShape } from 'react-intl';

export interface NewDynamicColumnInterface {
    id: string;
    name: string;
    type: DYNAMIC_PROPERTY_TYPES;
}

interface AddColumnHeaderInterface {
    onAdd: (column: NewDynamicColumnInterface) => void;
    intl: IntlShape;
}

const AddColumnHeader: React.FC<AddColumnHeaderInterface> = ({
    onAdd,
    intl,
}) => {
    const menu = useFloatMenu();

    const [column, setColumn] = useState<NewDynamicColumnInterface | null>(
        null
    );

    const MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'text' }),
                icon: AlignLeft,
                onClick: () => {
                    setColumn({
                        name: '',
                        type: DYNAMIC_PROPERTY_TYPES.TEXT,
                        id: crypto.randomUUID(),
                    });
                    menu.setShowMenu(false);
                },
            },
            {
                label: intl.formatMessage({ id: 'number' }),
                icon: Hash,
                onClick: () => {
                    setColumn({
                        name: '',
                        type: DYNAMIC_PROPERTY_TYPES.NUMBER,
                        id: crypto.randomUUID(),
                    });
                    menu.setShowMenu(false);
                },
            },
            {
                label: intl.formatMessage({ id: 'boolean' }),
                icon: ToggleRight,
                onClick: () => {
                    setColumn({
                        name: '',
                        type: DYNAMIC_PROPERTY_TYPES.BOOLEAN,
                        id: crypto.randomUUID(),
                    });
                    menu.setShowMenu(false);
                },
            },
            {
                label: intl.formatMessage({ id: 'select' }),
                icon: ChevronDown,
                onClick: () => {
                    setColumn({
                        name: '',
                        type: DYNAMIC_PROPERTY_TYPES.SELECT,
                        id: crypto.randomUUID(),
                    });
                    menu.setShowMenu(false);
                },
            },
            {
                label: intl.formatMessage({ id: 'multiple_select' }),
                icon: List,
                onClick: () => {
                    setColumn({
                        name: '',
                        type: DYNAMIC_PROPERTY_TYPES.MULTI_SELECT,
                        id: crypto.randomUUID(),
                    });
                    menu.setShowMenu(false);
                },
            },
        ],
        [intl]
    );

    return (
        <div className="add-column-header">
            {column && (
                <NewColumnModal
                    handleClose={() => setColumn(null)}
                    column={column}
                    callback={(newCol: NewDynamicColumnInterface) => {
                        setColumn(null);
                        onAdd(newCol);
                    }}
                    intl={intl}
                />
            )}

            <Popover
                isOpen={menu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="end"
                onClickOutside={() => menu.setShowMenu(false)}
                content={
                    <BasicMenuList
                        menus={MENUS}
                        top={35}
                        right={0}
                        fixedWidth={200}
                    />
                }
            >
                <IconButton
                    icon={Plus}
                    variant="secondary"
                    onClick={menu.handleMenu}
                />
            </Popover>
        </div>
    );
};

export default AddColumnHeader;
