import { RefObject } from 'react';
import { useIntl } from 'react-intl';
import { Popover } from 'react-tiny-popover';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import { useEntityContext } from '~/hooks';
import { TopicsContext } from '~/context/topics';
import { TagsContext } from '~/context/tags';
import { FilledFolderTreeInterface } from '~/interfaces/entities';
import BaseFloatMenu from '~/components/UIElements/BaseFloatMenu';
import BasicFieldForm from '~/components/UIElements/BasicFieldForm';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';

import styles from './Dialogs.module.scss';

interface CreateEditSubFolderDialogProps {
    folder: FilledFolderTreeInterface;
    createSubfolderMenu: FloatMenuInterface;
    renameMenu: FloatMenuInterface;
    cardRef: RefObject<HTMLDivElement>;
    onFolderCreation: (folderName: string) => void;
}

const CreateEditSubFolderDialog = ({
    folder,
    createSubfolderMenu,
    renameMenu,
    cardRef,
    onFolderCreation,
}: CreateEditSubFolderDialogProps) => {
    const intl = useIntl();

    const { loading: loadingTags } =
        useEntityContext<TagsProviderInterface>(TagsContext);
    const { loading: loadingTopics } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    if (!cardRef.current) return;

    return (
        <Popover
            isOpen={createSubfolderMenu.showMenu || renameMenu.showMenu}
            positions={['right']}
            align="start"
            onClickOutside={() => {
                createSubfolderMenu.setShowMenu(false);
                renameMenu.setShowMenu(false);
            }}
            parentElement={cardRef.current!}
            content={
                <BaseFloatMenu
                    className={styles.cgDialogFolder}
                    animated={false}
                >
                    <BasicFieldForm
                        confirmText={intl.formatMessage({
                            id: createSubfolderMenu.showMenu ? 'add' : 'save',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'folder_name',
                        })}
                        intl={intl}
                        loading={loadingTags || loadingTopics}
                        onSubmit={onFolderCreation}
                        label={intl.formatMessage({
                            id: createSubfolderMenu.showMenu
                                ? 'create_subfolders'
                                : 'rename_folder',
                        })}
                        initialValue={renameMenu.showMenu ? folder.name : ''}
                    />
                </BaseFloatMenu>
            }
        >
            <span></span>
        </Popover>
    );
};

export default CreateEditSubFolderDialog;
