import { useEffect, useRef } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const TextArea = ({ className, error, autoFocus, disabled, ...rest }) => {
    const inputElement = useRef(null);

    useEffect(() => {
        if (!inputElement.current) return;

        if (autoFocus && !disabled) {
            inputElement?.current?.focus();
        }
    }, [autoFocus, disabled]);
    return (
        <textarea
            disabled={disabled}
            ref={inputElement}
            className={`cg-text-area ${
                error ? 'cg-textarea-error' : ''
            } ${className}`}
            {...rest}
        />
    );
};

TextArea.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.string,
};

export default TextArea;
