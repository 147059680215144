import { UserData } from '~/interfaces/entities';

const sessionDataKey = 'sessionData';

function getTokenExpirationDate(
    expires_in: number,
    refresh_expires_in: number
) {
    const date1 = new Date();
    const date2 = new Date();
    const expiration = {
        expires_in: date1.setSeconds(date1.getSeconds() + expires_in),
        refresh_expires_in: date2.setSeconds(
            date2.getSeconds() + refresh_expires_in
        ),
    };
    return expiration;
}

function cleanSessionData() {
    localStorage.removeItem(sessionDataKey);
}

function getUserData() {
    const data = localStorage.getItem(sessionDataKey);
    if (!data) return null;
    return JSON.parse(data) as UserData;
}

function writeSessionData({
    expires_in,
    refresh_expires_in,
    ...rest
}: UserData) {
    const expirationsAsDates = getTokenExpirationDate(
        expires_in,
        refresh_expires_in
    );
    localStorage.setItem(
        sessionDataKey,
        JSON.stringify({ ...expirationsAsDates, ...rest })
    );
}

const sessionServices = {
    getTokenExpirationDate,
    cleanSessionData,
    getUserData,
    writeSessionData,
};
export default sessionServices;
