import { useRef } from 'react';
const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

interface InfiniteScrollInterface {
    callback: () => void;
    targetClassName: string;
}

// targetClassName is the className of the container we want to spy. It's to avoid spying an incorrect container
const useInfiniteScroll = ({
    callback,
    targetClassName,
}: InfiniteScrollInterface): {
    onScroll: (e: React.UIEvent<HTMLElement>) => void;
} => {
    const positionRef = useRef(0);
    const isRunning = useRef(false);
    const scrollLeft = useRef(0);

    // firefox has a bug with horizontal scrolling. Whenever something changes in the table, it scrolls to the left (reset the scroll position),
    // so we need to keep the scroll position in a ref and restore it whenever the table is re-rendered
    const checkScrollInFirefox = (e: React.UIEvent<HTMLElement>) => {
        const target = e.target as HTMLDivElement;
        if (
            target.scrollLeft !== 0 &&
            scrollLeft.current === target.scrollLeft
        ) {
            return;
        }

        const tableElement = document.getElementsByClassName(
            'p-datatable-wrapper'
        )[0];

        if (!tableElement) return;

        if (scrollLeft.current - tableElement.scrollLeft > 100)
            tableElement.scrollLeft = scrollLeft.current;
    };

    const onScroll = (e: React.UIEvent<HTMLElement>) => {
        const target = e.target as HTMLDivElement;

        const className = target.className;

        if (isFirefox) checkScrollInFirefox(e);

        scrollLeft.current = target.scrollLeft;

        if (className !== targetClassName) return;

        // detect only vertical scroll
        const x = target.scrollTop;
        if (x === positionRef.current) return;
        positionRef.current = x;
        const endOfPage =
            target.scrollHeight - target.scrollTop <= target.clientHeight + 50;
        if (endOfPage && !isRunning.current) {
            isRunning.current = true;

            setTimeout(() => {
                callback();
                isRunning.current = false;
            }, 700);
        }
    };

    return { onScroll };
};

export default useInfiniteScroll;
