import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { noValueRecordsMap } from '~/utils/parseColumnValues';

const normalizeBooleanColumnValues = (
    values: ColumnValueOption[]
): ColumnValueOption[] => {
    if (!values?.length) return values;

    const base = [];

    const truthyValues = values.find((v) => v.label === 'true')?.value;

    if (truthyValues)
        base.push({
            label: 'true',
            value: truthyValues,
            __id: 'true',
        });

    const falsyValues = values.find((v) => v.label === 'false')?.value;
    const blankValues = values.find(
        (v) => v.label === noValueRecordsMap.___no_value_records
    )?.value;

    const falsy = [falsyValues, blankValues].filter(Boolean).join('&');
    if (falsy.length)
        base.push({
            label: 'false',
            value: falsy,
            __id: 'false',
        });

    return base;
};

export default normalizeBooleanColumnValues;
