import { Context, useContext } from 'react';

/**
 * Used to allow Typescript to typehint the context avoiding its initial value, which is usually set
 * to 'null'.
 */
export default function useEntityContext<T>(
    context: Context<T | null>,
    workspaceName?: string
) {
    const ctx = useContext(context);

    if (ctx === null) {
        throw new Error(
            `Component using ${workspaceName} is not in its provider's reach`
        );
    }

    return ctx;
}
