import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AuthContext } from '~/context/auth';
import { ProjectContext } from '~/context/project';
import { useEntityContext } from '~/hooks';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import { QueryParamsInterface } from '~/types/queryParams.interface';
import { getQueryParams } from '~/utils/getQueryParams';
import useDocumentViewerStore from '../stores/documentViewerStore';
import toast from 'react-hot-toast';
import { commentsServices, documentServices } from '~/services';
import {
    CGDocumentInternalMetadata,
    CGDocumentMetadata,
} from '~/interfaces/entities';
import { checkTokenExpiration } from '~/utils';
import { environment } from '~/config/env';
import { useShallow } from 'zustand/react/shallow';
import { getErrorCode } from '~/utils/getErrorCode';

interface DocumentViewerHookInterface {
    handleViewDocument: (documentData: CGDocumentMetadata) => void;
    onDeleteDocument: () => void;
    checkDocumentViewerParams: () => void;
}

const queryParams =
    getQueryParams<QueryParamsInterface>('params') ||
    getQueryParams<QueryParamsInterface>('smartViewer'); // support for legacy links

const useDocumentViewer = (): DocumentViewerHookInterface => {
    const intl = useIntl();

    const { logout, user } =
        useEntityContext<AuthContextInterface>(AuthContext);

    const { selectedProject, handleSelectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [
        documentDisplayData,
        documentMetadata,
        resetViewer,
        handleLoading,
        handleShowViewer,
        handleDocumentDisplayData,
        handleDocumentMetadata,
    ] = useDocumentViewerStore(
        useShallow((state) => [
            state.documentDisplayData,
            state.documentMetadata,
            state.resetViewer,
            state.handleLoading,
            state.handleShowViewer,
            state.handleDocumentDisplayData,
            state.handleDocumentMetadata,
        ])
    );

    useEffect(() => {
        if (!selectedProject || !documentDisplayData) return;

        if (selectedProject.id !== documentDisplayData.project_id)
            resetViewer();
    }, [selectedProject, documentDisplayData]);

    const getDocument = async (documentData: CGDocumentMetadata) => {
        handleShowViewer(true);
        handleLoading(true);

        const response = await documentServices.getDocument(documentData.id);

        if (response.error) {
            toast.error(
                intl.formatMessage({ id: 'document_no_exist_or_forbidden' })
            );
            handleLoading(false);

            return;
        }

        const { document: doc } = response;

        // we need to be sure that the token is not expired, if it is, renew it before continue
        const result = await checkTokenExpiration();
        if (result.sessionHasExpired) return logout();

        // support for legacy links that don't contain the project_id
        if (selectedProject?.id !== doc.project_id)
            handleSelectedProject(doc.project);

        const url = `${environment.apiUrl}/files/${doc.file_id}/stream`;
        const payload: CGDocumentInternalMetadata = {
            PDFUrl: url,
            fileId: doc.file_id,
            userId: user?.id,
            initialPage: documentData.initialPage,
            markedText: documentData.text,
        };

        handleLoading(false);

        handleDocumentDisplayData(doc);
        handleDocumentMetadata(payload);
    };

    const onDeleteDocument = async () => {
        handleLoading(true);

        if (!documentDisplayData) return;

        const [error, response] = await documentServices.deleteFiles([
            documentDisplayData.id,
        ]);

        handleLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
            return;
        }

        toast.success(intl.formatMessage({ id: 'doc_deleted_successfully' }));
        resetViewer();
    };

    const checkDocumentViewerParams = async () => {
        if (!queryParams?.commentId && !queryParams?.documentId) return;

        let parsedParams: CGDocumentMetadata;

        if (queryParams.commentId) {
            const [error, response] = await commentsServices.getComments({
                ids: [queryParams.commentId],
            });

            if (error) {
                toast.error(intl.formatMessage({ id: 'general_error' }));
                resetViewer();
                return;
            }

            const { data } = response;

            const insight = data[0];

            parsedParams = {
                id: insight.document_id,
                initialPage: insight.page_number,
            };
        } else {
            parsedParams = {
                id: queryParams.documentId as string,
                ...queryParams,
            };
        }

        getDocument(parsedParams);
    };

    const handleViewDocument = (documentData: CGDocumentMetadata) => {
        handleShowViewer(true);

        if (documentData.id === documentDisplayData?.id) {
            const textHasChanged =
                documentData.text !== documentMetadata?.markedText;

            const pageHasChanged =
                documentData.initialPage !== documentMetadata?.initialPage;

            if (textHasChanged || pageHasChanged) {
                handleDocumentMetadata({
                    ...documentMetadata,
                    initialPage: documentData.initialPage,
                    markedText: documentData.text,
                });
            }

            return;
        }

        getDocument(documentData);
    };

    return {
        handleViewDocument,
        onDeleteDocument,
        checkDocumentViewerParams,
    };
};

export default useDocumentViewer;
