export const getQueryParams = <T,>(key: string) => {
    try {
        const url = new URLSearchParams(window.location.search);
        const urlParams = url.get(key);

        if (!urlParams) {
            return null;
        }

        const params = JSON.parse(urlParams);

        return params as T;
    } catch (error) {
        return null;
    }
};
