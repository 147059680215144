import React, { useMemo } from 'react';
import './MultiSelectCell.scss';
import { Pill, TagsPicker } from '../UIElements';
import { Popover } from 'react-tiny-popover';
import { useFloatMenu } from '../../hooks';

interface MultiSelectOptionsInterface {
    id: string;
    name: string;
}

export interface MultiSelectCellInterface {
    id: string;
    rowId: string;
    columnId: string;
    options: MultiSelectOptionsInterface[];
}

interface ColumnItemInterface {
    id: string;
    name: string;
    columnId: string;
}

export interface CellItemsPayloadInterface {
    createCell?: boolean;
    itemsToCreate?: string[];
    itemsToAdd?: string[];
    itemsToRemove?: string[];
    cellId: string;
}

interface MultiSelectInterface {
    cell: MultiSelectCellInterface;
    columnItems: ColumnItemInterface[];
    onApply?: (
        itemsPayload: CellItemsPayloadInterface,
        callback: () => void
    ) => void;
    disabled?: boolean;
    loading?: boolean;
    setLoading: (loading: boolean) => void;
    onUpdateItems: () => void;
    onDeleteCellItems: () => void;
    singleItem?: boolean;
    intl: IntlShape;
}

import { compareStringsAlphabetically } from '../../utils/orderStringAlphabetically';
import { IntlShape } from 'react-intl';

const MultiSelectCell: React.FC<MultiSelectInterface> = ({
    intl,
    disabled,
    loading,
    singleItem,
    cell,
    columnItems = [],
    onApply, // when added or removed items
    onUpdateItems, // when updated items
    onDeleteCellItems, // when deleted items
}) => {
    const menu = useFloatMenu();

    const options = useMemo(() => {
        return columnItems.map((t) => ({ value: t.id, label: t.name }));
    }, [columnItems]);

    const selectedOptions = useMemo(
        () =>
            cell.options
                .map((t) => ({ value: t.id, label: t.name }))
                .sort((a, b) =>
                    compareStringsAlphabetically(a?.label, b?.label)
                ),
        [cell.options]
    );

    const onApplyItems = async (newItemsArray) => {
        // setLoading(true);

        const payload: CellItemsPayloadInterface = { cellId: cell.id };

        // first we need to check if the cell is already created for this column, if not we need to create it
        if (!cell.id) payload.createCell = true;

        // unexisting items, need to be created
        const itemsToCreate = newItemsArray
            .filter((t) => t.isNew)
            .map((t) => t.value);

        // existing items but not in the cell, need to be added
        const itemsToAdd = newItemsArray
            .filter(
                (t) => !t.isNew && !cell.options.some((i) => i.id === t.value)
            )
            .map((t) => t.value);

        if (itemsToAdd.length) payload.itemsToAdd = itemsToAdd;

        if (itemsToCreate.length) payload.itemsToCreate = itemsToCreate;

        const newItemIds = newItemsArray.map((t) => t.value);

        const itemsToRemove = cell.options
            .filter((i) => !newItemIds.includes(i.id))
            .map((i) => i.id);

        if (itemsToRemove.length) payload.itemsToRemove = itemsToRemove;

        onApply && onApply(payload, () => menu.handleMenu());
    };

    return (
        <Popover
            isOpen={menu.showMenu}
            positions={['bottom', 'left', 'right']}
            align="start"
            content={
                <TagsPicker
                    intl={intl}
                    tags={options}
                    selectedTags={selectedOptions}
                    onApply={onApplyItems}
                    loading={loading}
                    showItemActions
                    onUpdateItem={onUpdateItems}
                    onDeleteItem={onDeleteCellItems}
                    maxTags={singleItem ? 1 : 0}
                />
            }
            onClickOutside={() => menu.setShowMenu(false)}
        >
            <div
                className="dg-items-cell"
                onClick={() => {
                    if (disabled) return;
                    menu.handleMenu();
                }}
            >
                {selectedOptions.map((t) => (
                    <Pill key={t.value} variant="secondary">
                        <p title={t.label}>{t.label}</p>
                    </Pill>
                ))}
            </div>
        </Popover>
    );
};

export default MultiSelectCell;
