function FolderIcon({ onClick }: { onClick?: (e: React.MouseEvent) => void }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={(e) => onClick && onClick(e)}
        >
            <path
                fill="#161816"
                fillRule="evenodd"
                d="M2.667 2.667A.667.667 0 002 3.334v9.333a.667.667 0 00.667.667h10.666a.667.667 0 00.667-.667V5.334a.667.667 0 00-.667-.667h-6a.667.667 0 01-.555-.297L5.643 2.667H2.667zm-1.415-.748a2 2 0 011.415-.586H6c.223 0 .43.112.555.297L7.69 3.334h5.643a2 2 0 012 2v7.333a2 2 0 01-2 2H2.667a2 2 0 01-2-2V3.333a2 2 0 01.585-1.414z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default FolderIcon;
