import { MouseEvent } from 'react';
import { FolderItemTypes } from '~/constants/folders';
import useItemMenu from './useItemMenu.hook';
import {
    FilledFolderTreeInterface,
    FolderInterface,
} from '~/interfaces/entities';

interface UseItemProps {
    folder: FilledFolderTreeInterface;
    folderItemsType?: FolderItemTypes;
    showActions?: boolean;
    isExpanded: boolean;
    forceExpandFolder?: (folderId: string) => void;
    onCreateFolderItem?: (itemName: string, parentId: string) => void;
    onCreateSubfolder?: (newFolderName: string, parentId: string) => void;
    onFolderClick: (folder: FilledFolderTreeInterface) => void;
    onFolderIconClick?: () => void;
    onUpdateFolder?: (folder: FolderInterface) => void;
    onDeleteFolder?: (folderId: string) => void;
}

const useItem = ({
    folder,
    folderItemsType,
    showActions,
    isExpanded,
    onFolderClick,
    onUpdateFolder,
    onDeleteFolder,
    onFolderIconClick,
    onCreateFolderItem,
    onCreateSubfolder,
    forceExpandFolder,
}: UseItemProps) => {
    const {
        ACTION_MENUS,
        someMenuIsOpen: folderMenuVisible,
        actionsMenu,
        renameMenu,
        deleteMenu,
        createSubfolderMenu,
        createTopicMenu,
        createTagMenu,
    } = useItemMenu({
        folderItemsType,
        showActions,
    });

    const handleFolderCreation = (folderName: string) => {
        if (!isExpanded && forceExpandFolder) forceExpandFolder(folder.id);

        const isRenaming = renameMenu.showMenu;

        if (isRenaming) {
            onUpdateFolder &&
                onUpdateFolder({
                    id: folder.id,
                    name: folderName,
                    project_id: folder.project_id,
                });
            renameMenu.handleMenu();
        } else {
            onCreateSubfolder && onCreateSubfolder(folderName, folder.id);
        }
    };

    const handleDeleteFolder = (e: React.MouseEvent) => {
        e.stopPropagation();
        deleteMenu.handleMenu();
        onDeleteFolder && onDeleteFolder(folder.id);
    };

    const expandFolder = () => {
        if (!isExpanded && forceExpandFolder) forceExpandFolder(folder.id);
    };

    // todo not have specific login in folder tree, let this on the item
    const onCreateTag = (tagName: string) => {
        onCreateFolderItem && onCreateFolderItem(tagName, folder.id);

        expandFolder();
    };

    const handleFolderClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onFolderClick(folder);
    };

    const onClickIcon = (e: MouseEvent) => {
        e.stopPropagation();
        onFolderIconClick && onFolderIconClick();
    };

    return {
        ACTION_MENUS,
        actionsMenu,
        renameMenu,
        deleteMenu,
        createSubfolderMenu,
        createTopicMenu,
        createTagMenu,
        folderMenuVisible,
        onClickIcon,
        handleFolderClick,
        handleFolderCreation,
        handleDeleteFolder,
        expandFolder,
        onCreateTag,
    };
};

export default useItem;
