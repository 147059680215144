import queryString from 'query-string';
import { environment } from '~/config/env';
import {
    TopicInterface,
    CreateTopicDTO,
    TopicInterfaceDetailed,
    UpdateTopicDTO,
} from '~/modules/Topics/types/Topic.interface';

import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '../helpers/requestAdapter';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';

const API_URL = environment.apiUrl + '/topics';

const contentServices = {
    createTopic,
    updateTopic,
    deleteTopic,
    getTopics,
};
export default contentServices;

async function getTopics(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<PagedResponse<TopicInterfaceDetailed>>(
        API_URL + '/detailed?' + queryString.stringify(options)
    );
}

async function createTopic(topic: CreateTopicDTO) {
    analytics.trackGenericEvent(EVENT_NAMES_ENUM.CREATE_TOPIC);

    return requestAdapterInstance.post<
        UnpagedResponse<TopicInterface>,
        CreateTopicDTO
    >(environment.apiUrl + '/topics', topic);
}

async function updateTopic(topicId: string, topic: UpdateTopicDTO) {
    return requestAdapterInstance.put<
        UnpagedResponse<TopicInterface>,
        UpdateTopicDTO
    >(environment.apiUrl + `/topics/${topicId}`, topic);
}

async function deleteTopic(topicId: string) {
    return requestAdapterInstance.delete<null, null>(
        environment.apiUrl + `/topics/${topicId}`
    );
}
