import React, { useEffect, useState } from 'react';
import './index.scss';
import ReactQuill from 'react-quill';
import sanitizeHtml from 'sanitize-html';
import { useMemo } from 'react';
import { RichTextInputPropsInterface } from '~/components/UIElements/RichTextField';

const toolbarTabs = [
    ['bold', 'italic', { list: 'ordered' }, { list: 'bullet' }, 'link'],
];

const sanitizeConf = {
    allowedTags: [
        'b',
        'i',
        'a',
        'p',
        'br',
        'ul',
        'li',
        'ol',
        'h1',
        'h2',
        'h3',
        'span',
        'strong',
        'em',
    ],
    allowedAttributes: { a: ['href', 'rel', 'target'] },
};

const RichTextInput: React.FC<RichTextInputPropsInterface> = ({
    loading,
    placeholder,
    value,
    onChange,
    disabled,
}) => {
    const [editorHtml, setEditorHtml] = useState(value);

    const handleEditorChange = (html: string) => {
        const sanitizedHtml = sanitizeHtml(html, sanitizeConf).replace(
            /<\/\s*br>/g,
            '<br>'
        ); // avoid xss attack

        if (sanitizedHtml === value) return; //

        onChange(sanitizedHtml);
        setEditorHtml(sanitizedHtml);
    };

    useEffect(() => {
        setEditorHtml(value);
    }, []);

    const memoInput = useMemo(
        () => (
            <ReactQuill
                value={editorHtml}
                onChange={handleEditorChange}
                placeholder={placeholder}
                className={`cg-rich-text-input `}
                modules={{
                    toolbar: toolbarTabs,
                }}
                readOnly={loading || disabled}
            />
        ),
        []
    );

    return memoInput;
};

export default RichTextInput;
