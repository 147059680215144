import { FilledFolderTreeInterface } from '~/interfaces/entities';
import { FolderTreeInterfaceBase } from '~/components/UIElements/FolderTree';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import FolderItem from '../FolderItem';
import EmptyFolder from './EmptyFolder';
import FolderItems from './FolderItems';
import classNames from 'classnames';

import styles from './RecursiveFolderTree.module.scss';

interface RecursiveFolderTreeProps<T> extends FolderTreeInterfaceBase<T> {
    globalStructure?: FilledFolderTreeInterface[];
    structure: FilledFolderTreeInterface;
}

const RecursiveFolderTree = <T,>({
    structure,
    customItem: CustomItem,
    selectedFolders = [],
    showActions = true,
    showFiles = true,
    expandAllFolders,
    expandedFolders = [],
    globalStructure,
    folderItemsType,
    showItemsCount,
    onUpdateFolder,
    onSelectFolder,
    onDeleteFolder,
    onExpandFolder,
    onCreateFolderItem,
    onCreateSubfolder,
    onMoveItem,
}: RecursiveFolderTreeProps<T>) => {
    const folderCanBeOpened = showFiles || Boolean(structure?.children?.length);

    const handleExpand = () => {
        if (!folderCanBeOpened) return;
        onExpandFolder?.(structure?.id);
    };

    const folderExpanded =
        expandedFolders.includes(structure?.id) || Boolean(expandAllFolders);

    const isEmptyFolder =
        structure.items.length === 0 && structure.children?.length === 0;

    const showActionsParentFolder =
        showActions && structure.name !== UNCATEGORIZED_FOLDER_NAME;

    const onFolderItemsClick = (item: FilledFolderTreeInterface) => {
        if (folderCanBeOpened) {
            onExpandFolder && onExpandFolder(structure?.id);
        }
        onSelectFolder && onSelectFolder(item);
    };

    return (
        <div className={styles.cgRFolderTree}>
            <div
                className={styles.cgRFolderTree__folderItem}
                title={structure.name}
            >
                <FolderItem
                    folder={structure}
                    isChecked={selectedFolders.includes(structure?.id)}
                    onDeleteFolder={onDeleteFolder}
                    isExpanded={folderExpanded}
                    showActions={showActionsParentFolder}
                    folderItemsType={folderItemsType}
                    showItemsCount={showItemsCount}
                    onFolderClick={() => onFolderItemsClick(structure)}
                    onFolderIconClick={handleExpand}
                    onUpdateFolder={onUpdateFolder}
                    onCreateFolderItem={onCreateFolderItem}
                    onCreateSubfolder={onCreateSubfolder}
                    forceExpandFolder={onExpandFolder}
                    onMoveItem={onMoveItem}
                />
            </div>
            {folderExpanded ? (
                <div
                    className={classNames(styles.cgRFolderTree__expanded, {
                        [styles['cgRFolderTree__expanded--open']]:
                            folderExpanded,
                    })}
                >
                    {isEmptyFolder && <EmptyFolder />}

                    <FolderItems
                        structure={structure}
                        customItem={CustomItem}
                        selectedFolders={selectedFolders}
                        showActions={showActions}
                        showFiles={showFiles}
                        globalStructure={globalStructure}
                        expandedFolders={expandedFolders}
                        expandAllFolders={expandAllFolders}
                        folderItemsType={folderItemsType}
                        showItemsCount={showItemsCount}
                        onFolderClick={() => onFolderItemsClick(structure)}
                        onExpandFolder={onExpandFolder}
                        onDeleteFolder={onDeleteFolder}
                        onCreateFolderItem={onCreateFolderItem}
                        onCreateSubfolder={onCreateSubfolder}
                        onUpdateFolder={onUpdateFolder}
                        onSelectFolder={onSelectFolder}
                        onMoveItem={onMoveItem}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default RecursiveFolderTree;
