import { PillVariants } from '~/components/UIElements/Pill/constants';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { TagInterfaceDetailed } from '~/modules/Tags/types/Tag.interface';

export function getTagColor(
    colors: { [key: string]: PillVariants },
    tags: TagInterfaceDetailed[],
    tagId: string
) {
    const tagFolderId = tags.find((t) => t.id === tagId)?.tag_group_id;
    if (!tagFolderId) return colors[UNCATEGORIZED_FOLDER_NAME];
    return colors[tagFolderId];
}
