import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateReportDTO,
    CreateReportSectionDTO,
    ReportInterface,
    ReportSectionInterface,
    ReportSectionInterfaceDetailed,
    UpdateReportDTO,
    UpdateReportSectionDTO,
    UpdateReportSectionSourcesDTO,
    UpdateReportWithAIDTO,
} from '~/interfaces/entities';

const API_URL = environment.apiUrl + '/reports';

const getReports = async (options: Record<string, unknown> = {}) => {
    const url = API_URL + '?' + queryString.stringify(options);
    return requestAdapterInstance.get<PagedResponse<ReportInterface>>(url);
};

const getReportSections = async (options: Record<string, unknown> = {}) => {
    const url =
        environment.apiUrl +
        '/report_sections/detailed?' +
        queryString.stringify(options);
    return requestAdapterInstance.get<
        PagedResponse<ReportSectionInterfaceDetailed>
    >(url);
};

const createReport = async (reportName: string, projectId: string) => {
    return requestAdapterInstance.post<
        UnpagedResponse<ReportInterface>,
        CreateReportDTO
    >(API_URL, {
        project_id: projectId,
        name: reportName,
    });
};

const deleteReport = async (reportId: string) => {
    return requestAdapterInstance.delete<null, null>(
        API_URL + '/' + reportId + '?force=true'
    );
};

const createReportSection = async (section: CreateReportSectionDTO) => {
    const url = environment.apiUrl + '/report_sections';
    return requestAdapterInstance.post<
        UnpagedResponse<ReportSectionInterface>,
        CreateReportSectionDTO
    >(url, section);
};

const deleteSection = async (sectionId: string) => {
    const url = environment.apiUrl + '/report_sections/' + sectionId;
    return requestAdapterInstance.delete<null, null>(url);
};

const updateSection = async (
    sectionId: string,
    payload: UpdateReportSectionDTO
) => {
    const url = environment.apiUrl + '/report_sections/' + sectionId;
    return requestAdapterInstance.put<
        UnpagedResponse<ReportSectionInterface>,
        UpdateReportSectionDTO
    >(url, payload);
};

const updateSectionSources = async (
    sectionId: string,
    sources: UpdateReportSectionSourcesDTO
) => {
    const url =
        environment.apiUrl + '/report_sections/' + sectionId + '/sources';
    return requestAdapterInstance.put<
        UnpagedResponse<ReportSectionInterfaceDetailed>,
        UpdateReportSectionSourcesDTO
    >(url, sources);
};

const generateConclusionWithAI = async (
    reportId: string,
    options: UpdateReportWithAIDTO
) => {
    const url = `${environment.apiUrl}/reports/${reportId}/conclusion/generate`;
    return requestAdapterInstance.put<
        UnpagedResponse<ReportInterface>,
        UpdateReportWithAIDTO
    >(url, options);
};

const updateReport = async (reportId: string, payload: UpdateReportDTO) => {
    const url = environment.apiUrl + '/reports/' + reportId;
    return requestAdapterInstance.put<
        UnpagedResponse<ReportInterface>,
        UpdateReportDTO
    >(url, payload);
};

const reportServices = {
    createReport,
    getReports,
    deleteReport,
    getReportSections,
    createReportSection,
    deleteSection,
    updateSection,
    updateSectionSources,
    generateConclusionWithAI,
    updateReport,
};

export default reportServices;
