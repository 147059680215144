import { useEffect, useRef } from 'react';
import './index.scss';

export interface TextInputInterface
    extends React.InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}

const TextInput: React.FC<TextInputInterface> = ({
    value,
    onChange,
    placeholder = '',
    className = '',
    error,
    autoFocus,
    disabled,
    ...rest
}) => {
    const inputElement = useRef(null);

    useEffect(() => {
        if (!inputElement.current) return;

        if (autoFocus && !disabled) {
            inputElement?.current?.focus();
        }
    }, [autoFocus, disabled]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
    };

    return (
        <input
            ref={inputElement}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            className={`dg-text-input ${
                error ? 'dg-text-input-error' : ''
            } ${className}`}
            disabled={disabled}
            {...rest}
        />
    );
};

export default TextInput;
