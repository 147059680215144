import './index.scss';
import PropTypes from 'prop-types';
import { InputNumber } from 'primereact/inputnumber';

const NumberField = ({
    hint,
    label,
    error,
    className = '',
    iconAfter: IconAfter,
    iconBefore: IconBefore,
    inputProps = {},
    maxFractionDigits = 10,
    allowEmpty = true,
    ...rest
}) => {
    const inputContainerClasses = () => {
        let classes = 'dg-field-input ';

        if (IconAfter) classes += 'dg-field-input--icon-after ';
        if (IconBefore) classes += 'dg-field-input--icon-before ';
        if (error) classes += 'dg-field-input--error ';

        return classes;
    };

    return (
        <div className={`dg-number-field ${className} `} {...rest}>
            {label && <label>{label}</label>}
            <div className={inputContainerClasses()}>
                {IconBefore && <IconBefore />}

                <InputNumber
                    onValueChange={(e) => {
                        inputProps.onChange && inputProps.onChange(e);
                    }}
                    allowEmpty={allowEmpty}
                    maxFractionDigits={maxFractionDigits}
                    {...inputProps}
                />

                {IconAfter && <IconAfter />}
            </div>
            {hint && <span className="dg-number-field-hint">{hint}</span>}
            {error && <span className="dg-number-field-error">{error}</span>}
        </div>
    );
};

NumberField.propTypes = {
    hint: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    maxFractionDigits: PropTypes.number,
    allowEmpty: PropTypes.bool,
    iconAfter: PropTypes.object,
    iconBefore: PropTypes.object,
    inputProps: PropTypes.object.isRequired,
};

export default NumberField;
