import { forwardRef, MouseEventHandler } from 'react';

interface SlidersIconProps {
    onClick?: MouseEventHandler<HTMLDivElement>;
    className?: string;
}
const SlidersIcon = forwardRef<HTMLDivElement, SlidersIconProps>(
    ({ onClick, className = '' }, ref) => {
        return (
            <div className={`pt-3 ${className} `} onClick={onClick} ref={ref}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="24"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0.1}
                    viewBox="0 0 16 16"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 2.667c0-.369.299-.667.667-.667h4.666a.667.667 0 110 1.333h-4.666A.667.667 0 0110 2.667zM0 2.667C0 2.298.298 2 .667 2h2a.667.667 0 010 1.333h-2A.667.667 0 010 2.667zM0 9.333c0-.368.298-.666.667-.666h4.666a.667.667 0 110 1.333H.667A.667.667 0 010 9.333zM12.667 9.333c0-.368.298-.666.666-.666h2a.667.667 0 110 1.333h-2a.667.667 0 01-.666-.667z"
                        clipRule="evenodd"
                    ></path>
                    <path
                        fillRule="evenodd"
                        d="M5.333 1.333a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM2.667 2.667a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0zM10.667 8a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM8 9.333a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0z"
                        clipRule="evenodd"
                    ></path>
                </svg>
            </div>
        );
    }
);

SlidersIcon.displayName = 'SlidersIcon';

export default SlidersIcon;
