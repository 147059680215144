import React, { forwardRef } from 'react';
import './index.scss';
import styleClass from '~/helpers/componentClassGenerator';
import Spinner from '../Spinner';

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'distructive'
        | 'accent'
        | 'darken';
    type?: 'button' | 'submit' | 'reset';
    size?: 'regular' | 'large';
    onClick?: (event: React.MouseEvent) => void;
    className?: string;
    children: React.ReactNode;
    iconAfter?: React.FC;
    iconBefore?: React.FC;
    loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = 'primary',
            type = 'button',
            size = 'regular',
            onClick,
            className = '',
            children,
            iconAfter: IconAfter,
            iconBefore: IconBefore,
            loading,
            ...rest
        },
        ref
    ) => {
        const styles = styleClass([variant, size], 'dg-button');
        return (
            <button
                ref={ref}
                className={`dg-button ${className} ${styles}`}
                type={type}
                onClick={onClick}
                {...rest}
            >
                {loading && <Spinner width="14" height="14" weight="2" />}
                {IconBefore && !loading ? <IconBefore /> : null}

                {children}

                {IconAfter && <IconAfter />}
            </button>
        );
    }
);

export default Button;
