import styles from './CustomItem.module.scss';

interface CustomItemProps {
    getItem: (props: object) => React.ReactNode;
    item: any;
}

const CustomItem = ({ getItem, item }: CustomItemProps) => {
    const renderItem = () => {
        return getItem(item);
    };

    return (
        <div className={styles.cgFolderItems__customItem}>{renderItem()}</div>
    );
};

export default CustomItem;
