import SelectInput, { SelectInputPropsInterface } from '../SelectInput';
import './index.scss';

interface SelectFieldPropsInterface {
    inputProps: SelectInputPropsInterface;
    hint?: string;
    error?: string;
    label?: string;
    className?: string;
}

const SelectField = ({
    hint,
    label,
    error,
    className = '',
    inputProps,
}: SelectFieldPropsInterface) => {
    return (
        <div className={`dg-select-field ${className} `}>
            {label && <label>{label}</label>}

            <SelectInput error={Boolean(error)} {...inputProps} />

            {hint && <span className="dg-select-field-hint">{hint}</span>}

            {error && <span className="dg-select-field-error">{error}</span>}
        </div>
    );
};

export default SelectField;
