import React, { useMemo, useRef } from 'react';
import './index.scss';
import { useColumnsFilter, useEntityContext } from '~/hooks';
import { ChevronDown, Filter } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import TagsPicker from '../TagsPicker';
import { TagsContext } from '../../../context/tags';
import { IntlShape } from 'react-intl';
import {
    ColumnValueOption,
    SortingParameters,
    onApplyFilter,
    ColumnFilter as ColumnFilterInterface,
    resetFilterColumn,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { GroupedTagsWithLabelledItems } from '~/helpers/transformTagGroupWithItems';
import { PillVariants } from '~/components/UIElements/Pill/constants';

interface TagsColumnFilterParams {
    intl: IntlShape;
    resetFilterColumn: resetFilterColumn;
    columnFilters: ColumnFilterInterface[];
    onApplyFilter: onApplyFilter;
    columnValues: ColumnValueOption[];
    sortParams: SortingParameters;
    sortFieldName?: string;
    headerName: string;
    columnName: string;
    renderTree?: boolean;
    folderStructure?: GroupedTagsWithLabelledItems | null | undefined;
    getTagColorCustomFn?: (tagId: string) => PillVariants;
}

const TagsColumnFilter: React.FC<TagsColumnFilterParams> = ({
    headerName,
    intl,
    columnName,
    resetFilterColumn,
    columnFilters,
    onApplyFilter,
    columnValues = [],
    sortParams = {},
    sortFieldName,
    renderTree = true,
    folderStructure,
    getTagColorCustomFn,
}) => {
    const menuRef = useRef(null);
    const { transformedFolderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const {
        isFiltered,
        sortFilter,
        selectedValues,
        filteredValues,
        onResetFilter,
        filterMenu,
    } = useColumnsFilter({
        columnName,
        resetFilterColumn,
        columnFilters,
        onApplyFilter,
        columnValues,
        sortParams,
        sortFieldName,
        isDynamicColumnFilter: false,
    });

    // if the structure is not given by props, use the transformed folder structure from the context (public library uses a different structure)
    const tagsStructure = folderStructure || transformedFolderStructure;

    const selectedTags = isFiltered ? selectedValues : [];

    const onApply = (tags: ColumnValueOption[]) => {
        if (!tags.length) {
            onResetFilter();
            filterMenu.setShowMenu(false);
            return;
        }

        onApplyFilter({
            sortType: sortFilter,
            column: columnName,
            values: tags,
            sortBy: sortFieldName,
            isDynamicColumnFilter: false,
        });
        filterMenu.handleMenu();
    };

    // filter folder structure in order to show only the tags that are present in the column values
    const filteredFolderStructure = useMemo(() => {
        if (!tagsStructure || !columnValues.length) return [];

        const availableTagIds = columnValues
            .map((tag) => tag.value.split('&'))
            .flat();

        const filteredTags = tagsStructure.map((folder) => {
            const tags = folder.items.filter((tag) =>
                availableTagIds.includes(tag.value)
            );

            return {
                ...folder,
                items: tags,
            };
        });

        return filteredTags;
    }, [tagsStructure, columnValues]);

    return (
        <div ref={menuRef}>
            <Popover
                isOpen={filterMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="end"
                content={
                    <div className="tags-column-filter">
                        <TagsPicker
                            intl={intl}
                            tags={filteredValues}
                            selectedTags={selectedTags}
                            onApply={onApply}
                            onCancel={() => {
                                filterMenu.setShowMenu(false);
                                onResetFilter();
                            }}
                            showCancelButton={isFiltered}
                            allowCreation={false}
                            folderStructure={filteredFolderStructure}
                            renderTree={renderTree}
                            getTagColorCustomFn={getTagColorCustomFn}
                        />
                    </div>
                }
                onClickOutside={() => filterMenu.setShowMenu(false)}
            >
                <div className="column-filter-header-body">
                    <span> {intl.formatMessage({ id: headerName })} </span>
                    {isFiltered ? (
                        <Filter onClick={filterMenu.handleMenu} />
                    ) : (
                        <ChevronDown onClick={filterMenu.handleMenu} />
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default TagsColumnFilter;
