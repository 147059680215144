import { useIntl } from 'react-intl';

import styles from './EmptyFolder.module.scss';

const EmptyFolder = () => {
    const intl = useIntl();

    return (
        <div className={styles.cgEmptyFolder}>
            {intl.formatMessage({ id: 'nothing_here' })}
        </div>
    );
};

export default EmptyFolder;
