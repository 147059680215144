import { useEffect, useRef, useState } from 'react';
import './index.scss';
import { ChevronDown, X } from 'react-feather';
import { useFloatMenu } from '../../../hooks';
import { Popover } from 'react-tiny-popover';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';
import { IconButton } from '~/components/UIElements';

interface PillSelectInterface {
    value?: LabelValueOptionInterface | null;
    onChange: (option: LabelValueOptionInterface) => void;
    placeholder?: string;
    className?: string;
    error?: string;
    options: LabelValueOptionInterface[];
    onOpen?: () => void;
    variant?: 'default' | 'pill' | 'secondary';
    prefix?: string;
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
    onRemove?: () => void;
}

const mainClass = 'cg-pill-select';

const PillSelect: React.FC<PillSelectInterface> = ({
    value = null,
    onChange,
    placeholder = '',
    className = '',
    error,
    options = [],
    onOpen,
    variant = 'default',
    prefix = '',
    size = 'medium',
    disabled,
    onRemove,
}) => {
    const inputMenu = useFloatMenu();

    const [selectedValue, setSelectedValue] =
        useState<LabelValueOptionInterface | null>(value);

    useEffect(() => {
        if (value) setSelectedValue(value);
    }, [value]);

    const getDisplay = () => {
        if (!selectedValue) {
            return placeholder;
        }

        return selectedValue.label;
    };

    const isSelected = (option: LabelValueOptionInterface) => {
        if (!selectedValue) {
            return false;
        }

        return selectedValue.value === option.value;
    };

    const onItemClick = (option: LabelValueOptionInterface) => {
        setSelectedValue(option);
        onChange(option);
        handleInputClick();
    };

    const handleInputClick = (e?) => {
        e?.stopPropagation();
        if (disabled) return;

        if (onOpen && !inputMenu.showMenu) onOpen();
        inputMenu.handleMenu();
    };

    const container = useRef();

    return (
        <div
            className={`${mainClass} ${options.length} ${className} ${
                error ? `${mainClass}__error` : ''
            } ${mainClass}__${variant} ${mainClass}__${size} ${
                disabled ? `${mainClass}__disabled` : ''
            }}`}
            ref={container}
        >
            <Popover
                isOpen={inputMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="start"
                onClickOutside={() => inputMenu.setShowMenu(false)}
                clickOutsideCapture={true}
                content={
                    <div className="dg-dropdown-menu">
                        {options.map((option: LabelValueOptionInterface) => (
                            <div
                                onClick={(e) => {
                                    onItemClick(option);
                                    e.stopPropagation();
                                }}
                                key={option.value}
                                className={`dg-dropdown-item ${
                                    isSelected(option) && 'selected'
                                }`}
                            >
                                <p title={option.label}>{option.label}</p>
                            </div>
                        ))}
                    </div>
                }
                parentElement={container.current}
            >
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                    }}
                >
                    <div
                        onClick={handleInputClick}
                        className="dg-dropdown-input"
                        title={getDisplay()}
                    >
                        <div
                            className={` ${
                                !selectedValue ? 'dg-dropdown-placeholder' : ''
                            }`}
                        >
                            {prefix && (
                                <span className={mainClass + '__prefix'}>
                                    {prefix}:{' '}
                                </span>
                            )}
                            <span
                                className={
                                    prefix && selectedValue
                                        ? mainClass + '__prefix-value'
                                        : ''
                                }
                            >
                                {getDisplay()}
                            </span>
                        </div>
                        <ChevronDown />
                    </div>
                    {onRemove && (
                        <IconButton
                            icon={X}
                            variant="secondary"
                            onClick={(e) => {
                                onRemove();
                                e.preventDefault();
                            }}
                            className={mainClass + '__remove'}
                        />
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default PillSelect;
