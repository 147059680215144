import { createContext } from 'react';
import { PermissionsProviderInterface } from '~/interfaces/contexts/PermissionsContext.interface';

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: { isAllowedTo: () => false } = {
    isAllowedTo: () => false,
};

// Create the context
const PermissionContext =
    createContext<PermissionsProviderInterface>(defaultBehaviour);

export default PermissionContext;
