import { ENTITIES, FirstLevelEntities, SecondLevelEntities } from './entities';
import { PROJECT_MEMBER_ROLES, WORKSPACE_MEMBER_ROLES } from './memberRoles';

export enum PERMISSIONS {
    VIEW = 'view',
    EDIT = 'edit',
    CREATE = 'create',
    DELETE = 'delete',
}

type PermissionsMap<T extends string, V extends string, W> = {
    [key in T]: { [secondKey in V]: W[] };
};

export const WORKSPACE_MEMBER_PERMISSIONS: PermissionsMap<
    WORKSPACE_MEMBER_ROLES,
    FirstLevelEntities,
    PERMISSIONS
> = {
    [WORKSPACE_MEMBER_ROLES.OWNER]: {
        [ENTITIES.WORKSPACES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
    },
    [WORKSPACE_MEMBER_ROLES.ADMIN]: {
        [ENTITIES.WORKSPACES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
    },
    [WORKSPACE_MEMBER_ROLES.USER]: {
        [ENTITIES.WORKSPACES]: [PERMISSIONS.VIEW],
    },
};

export const PROJECT_MEMBER_PERMISSIONS: PermissionsMap<
    PROJECT_MEMBER_ROLES,
    SecondLevelEntities,
    PERMISSIONS
> = {
    [PROJECT_MEMBER_ROLES.ADMIN]: {
        [ENTITIES.INSIGHTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.PROJECTS]: [
            PERMISSIONS.VIEW,
            PERMISSIONS.EDIT,
            PERMISSIONS.CREATE,
            PERMISSIONS.DELETE,
        ],
        [ENTITIES.DOCUMENTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.PROJECT_MEMBERS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.TOPICS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.TAGS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.SOURCES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.REPORTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.IMPORT]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.SAVED_QUERIES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
    },

    [PROJECT_MEMBER_ROLES.USER]: {
        [ENTITIES.INSIGHTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.PROJECTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.DOCUMENTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.PROJECT_MEMBERS]: [PERMISSIONS.VIEW],
        [ENTITIES.TOPICS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.TAGS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.SOURCES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.REPORTS]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.IMPORT]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
        [ENTITIES.SAVED_QUERIES]: [PERMISSIONS.VIEW, PERMISSIONS.EDIT],
    },

    [PROJECT_MEMBER_ROLES.READ_ONLY]: {
        [ENTITIES.INSIGHTS]: [PERMISSIONS.VIEW],
        [ENTITIES.PROJECTS]: [PERMISSIONS.VIEW],
        [ENTITIES.DOCUMENTS]: [PERMISSIONS.VIEW],
        [ENTITIES.PROJECT_MEMBERS]: [PERMISSIONS.VIEW],
        [ENTITIES.TOPICS]: [PERMISSIONS.VIEW],
        [ENTITIES.TAGS]: [PERMISSIONS.VIEW],
        [ENTITIES.SOURCES]: [PERMISSIONS.VIEW],
        [ENTITIES.REPORTS]: [PERMISSIONS.VIEW],
        [ENTITIES.IMPORT]: [],
        [ENTITIES.SAVED_QUERIES]: [PERMISSIONS.VIEW],
    },
};
