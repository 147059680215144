import { useEffect, useState } from 'react';
import './PasswordValidation.scss';
import { validatePassword } from '../../../utils/validateUserInfo';
import { Check } from 'react-feather';

const STRENGTH_LEVEL = {
    1: 'weak',
    2: 'fair',
    3: 'good',
    4: 'strong',
};

const PasswordValidation = ({ password = '', intl }) => {
    const [validated, setValidated] = useState([]);

    useEffect(() => {
        const { validated } = validatePassword(password);

        setValidated(validated);
    }, [password]);

    return (
        <div className="password-validation animate__animated animate__fadeIn">
            <div
                className={`password-validation__bars password-validation__bars__${
                    STRENGTH_LEVEL[validated.length]
                }`}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>

            {password && validated.length ? (
                <span
                    className={`password-validation__status password-validation__status__${
                        STRENGTH_LEVEL[validated.length]
                    }`}
                >
                    {intl.formatMessage({
                        id: STRENGTH_LEVEL[validated.length],
                    })}
                </span>
            ) : (
                ''
            )}

            <div className="password-validation__policies">
                <div
                    className={`${validated.includes('length') ? 'valid' : ''}`}
                >
                    <Check />
                    {intl.formatMessage({ id: 'password_length_policy' })}
                </div>
                <div
                    className={`${
                        validated.includes('uppercase') ? 'valid' : ''
                    }`}
                >
                    <Check />
                    {intl.formatMessage({ id: 'password_uppercase_policy' })}
                </div>
                <div
                    className={`${validated.includes('number') ? 'valid' : ''}`}
                >
                    <Check />
                    {intl.formatMessage({ id: 'password_number_policy' })}
                </div>
                <div
                    className={`${
                        validated.includes('special') ? 'valid' : ''
                    }`}
                >
                    <Check />
                    {intl.formatMessage({ id: 'password_special_policy' })}
                </div>
            </div>
        </div>
    );
};

export default PasswordValidation;
