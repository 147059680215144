import { useEffect, useState } from 'react';

interface InitialPagingValuesProps {
    defaultPageSize?: number;
    defaultPageNumber?: number;
    defaultTotalRow?: number;
    multipleSelect?: boolean;
    callback?: () => void;
}

export interface PagingValues {
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    selectedItems: string[];
    selectedAll: boolean;
    totalItems: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (size: number) => void;
    setTotalPages: (pages: number) => void;
    onSelectItem: (value: string[] | string) => void;
    resetPagination: () => void;
    setTotalItems: (total: number) => void;
    setSelectedAll: (value: boolean) => void;
    setSelectedItems: (items: string[]) => void;
}

const useTable = ({
    defaultPageSize = 50,
    defaultPageNumber = 0,
    defaultTotalRow = 1,
    multipleSelect,
    callback,
}: InitialPagingValuesProps): PagingValues => {
    const [pageSize, setPageSize] = useState<number>(defaultPageSize);
    const [pageNumber, setPageNumber] = useState<number>(defaultPageNumber);
    const [totalPages, setTotalPages] = useState<number>(defaultTotalRow);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);

    const [selectedAll, setSelectedAll] = useState(false);

    useEffect(() => {
        const pages = Math.ceil(totalItems / pageSize);
        setTotalPages(pages);

        const allItemsAreSelected =
            totalItems > 0 && totalItems === selectedItems.length;

        setSelectedAll(allItemsAreSelected);
    }, [totalItems, selectedItems]);

    useEffect(() => {
        if (!callback) return;
        callback();
    }, [pageSize, pageNumber]);

    /**
     * If '' is passed -> all items are deselected
     * If an ID is passed -> ID is added or removed from selected list
     * If an ID[] is passed -> previous selected items are overriden.
     * @param value '', an ID or an array of IDS
     * @returns
     */
    const onSelectMultipleRows = (value: string[] | string) => {
        if (Array.isArray(value)) {
            setSelectedItems(value);
            return;
        }

        if (!value) {
            setSelectedItems([]);
            return;
        }

        const idIsAlreadyIncluded = selectedItems.includes(value);

        let filteredArray = [];

        if (idIsAlreadyIncluded) {
            filteredArray = selectedItems.filter((rowId) => rowId !== value);
        } else {
            filteredArray = [...selectedItems, value];
        }

        setSelectedItems(filteredArray);
        if (selectedAll) setSelectedAll(false);
    };

    /**
     * If '' is passed -> all items are deselected
     * If an ID is passed -> ID is added or removed from selected list
     * If an ID[] is passed -> all items are deselected. Only a single select can be done.
     * @param value '', an ID or an array of IDS
     * @returns
     */
    const onSelectSingleRow = (value: string[] | string) => {
        if (!value || Array.isArray(value)) {
            setSelectedItems([]);
            return;
        }
        const items = selectedItems.includes(value) ? [] : [value];
        setSelectedItems(items);
    };

    const onPageChange = (page: number) => {
        setPageNumber(page < 0 ? 1 : page);
    };

    const onPageSizeChange = (size: number) => {
        setPageSize(size);
        setPageNumber(0);
    };

    const resetPagination = () => {
        setPageNumber(defaultPageNumber);
        setPageSize(defaultPageSize);
        setSelectedItems([]);
        setSelectedAll(false);
    };

    return {
        pageSize,
        pageNumber,
        totalPages,
        selectedItems,
        selectedAll,
        totalItems,
        onPageChange,
        onPageSizeChange,
        setTotalPages,
        onSelectItem: multipleSelect ? onSelectMultipleRows : onSelectSingleRow,
        resetPagination,
        setTotalItems,
        setSelectedAll,
        setSelectedItems,
    };
};

export default useTable;
