import { useState } from 'react';

type ErrorType = { [key: string]: string } | string | null;

const useTemporaryErrorDisplay = <T = ErrorType,>() => {
    const [error, setError] = useState<T | null>(null);

    const handleError = (msg: Partial<T> | null) => {
        setError(msg as T);
        setTimeout(() => {
            setError(null);
        }, 3000);
    };

    return { handleError, error };
};

export default useTemporaryErrorDisplay;
