export const validatePassword = (password = '') => {
    const valid = [];

    if (/\d/.test(password)) {
        valid.push('number');
    }
    if (password.length > 7 && password.length < 51) {
        valid.push('length');
    }

    if (/[A-Z]/.test(password)) {
        valid.push('uppercase');
    }
    /* In a regular expression like this [...] we don't need to escape [ or /, but we need to escape  others like ] -  */
    if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
        valid.push('special');
    }

    return {
        validated: valid,
        valid: valid.length === 4,
    };
};
