import Checkbox from '../Checkbox';
import { HTMLAttributes, InputHTMLAttributes } from 'react';
import './index.scss';

interface CheckboxFieldProps extends HTMLAttributes<HTMLDivElement> {
    error?: string;
    label: string;
    className?: string;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    hint?: string;
    id: string;
}

const CheckboxField = ({
    error,
    label,
    className = '',
    inputProps = {},
    hint,
    id,
    ...rest
}: CheckboxFieldProps) => {
    return (
        <div
            className={`cg-checkbox-field ${
                inputProps.disabled ? 'cg-checkbox-field__disabled' : ''
            } ${className}`}
            {...rest}
        >
            <Checkbox id={id} {...inputProps} />
            {label && <label htmlFor={id}>{label}</label>}
            {hint && <span className="cg-checkbox-field__hint">{hint}</span>}
            {error && <span className="cg-checkbox-field__error">{error}</span>}
        </div>
    );
};

export default CheckboxField;
