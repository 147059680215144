import { IMPORT_STATUSES } from '~/constants/ImportStatuses.enum';

export enum ImportFileTypes {
    URL = 'url',
    FILE = 'file',
}

export enum UploadFileStatuses {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export const ImportStatusTextColor = {
    [IMPORT_STATUSES.STARTED]: '#a22cff',
    [IMPORT_STATUSES.PENDING]: '#a22cff',
};

export enum StageTabsEnum {
    ALL = 'all',
    PENDING = 'pending',
    REJECTED = 'rejected',
    APPROVED = 'approved',
}

export enum ImportHistoryViews {
    IMPORT_HISTORY = 'import_history',
    IMPORT_STATUS = 'import_status',
}
