import { classNames } from '@react-pdf-viewer/core';
import './index.scss';

interface BaseFloatMenuProps {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    children: React.ReactNode;
    className?: string;
    animated?: boolean;
}

const BaseFloatMenu = ({
    top,
    left,
    right,
    bottom,
    children,
    className = '',
    animated = true,
}: BaseFloatMenuProps) => {
    return (
        <div
            className={classNames({
                'dg-base-float-menu': true,
                [className]: Boolean(className),
                'animate__animated animate__fadeIn animate__faster': animated,
            })}
            style={{
                top: top !== undefined ? top + 'px' : 'unset',
                left: left !== undefined ? left + 'px' : 'unset',
                bottom: bottom !== undefined ? bottom + 'px' : 'unset',
                right: right !== undefined ? right + 'px' : 'unset',
            }}
        >
            {children}
        </div>
    );
};

export default BaseFloatMenu;
