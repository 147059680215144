function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
        >
            <g
                fill="#000"
                fillRule="evenodd"
                clipPath="url(#clip0_5706_15865)"
                clipRule="evenodd"
            >
                <path d="M8 3.696c.368 0 .667.298.667.666v2.972h2.971a.667.667 0 110 1.333H8A.667.667 0 017.333 8V4.362c0-.368.299-.666.667-.666zM1.644 10.572a.667.667 0 01.615-.138l3.536.335a.667.667 0 11-.358 1.285l-2.57-.066-.03 2.6a.667.667 0 11-1.323.167L1.42 11.16a.667.667 0 01.225-.588z"></path>
                <path d="M7.126.719a7.337 7.337 0 11-5.638 10.665.667.667 0 111.184-.615 6.004 6.004 0 10-.675-2.768.667.667 0 11-1.334 0A7.337 7.337 0 017.126.718z"></path>
            </g>
            <defs>
                <clipPath id="clip0_5706_15865">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Icon;
