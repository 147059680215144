import React, { useRef } from 'react';
import './ColumnFilter.scss';
import { useColumnsFilter } from '~/hooks';
import { ChevronDown, Filter } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import FilterMenu from '~/components/Tables/FilterMenu';
import { IntlShape } from 'react-intl';
import {
    ColumnFilter as ColumnFilterInterface,
    ColumnValueOption,
    SortingParameters,
    onApplyFilter,
    resetFilterColumn,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';

interface ColumnFilterParams {
    intl: IntlShape;
    resetFilterColumn: resetFilterColumn;
    columnFilters: ColumnFilterInterface[];
    onApplyFilter: onApplyFilter;
    columnValues: ColumnValueOption[];
    sortParams: SortingParameters;
    sortFieldName?: string;
    headerName: string;
    columnName: string;
}

const ColumnFilter: React.FC<ColumnFilterParams> = ({
    headerName,
    intl,
    columnName,
    resetFilterColumn,
    columnFilters,
    onApplyFilter,
    columnValues = [],
    sortParams = {},
    sortFieldName,
}) => {
    const menuRef = useRef(null);

    const columnDataFiltering = useColumnsFilter({
        columnName,
        resetFilterColumn,
        columnFilters,
        onApplyFilter,
        columnValues,
        sortParams,
        sortFieldName,
    });

    const { filterMenu, isFiltered } = columnDataFiltering;

    return (
        <div ref={menuRef}>
            <Popover
                isOpen={filterMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="start"
                content={<FilterMenu intl={intl} {...columnDataFiltering} />}
                onClickOutside={() => filterMenu.setShowMenu(false)}
            >
                <div className="column-filter-header-body">
                    <span> {intl.formatMessage({ id: headerName })} </span>
                    {isFiltered ? (
                        <Filter onClick={filterMenu.handleMenu} />
                    ) : (
                        <ChevronDown onClick={filterMenu.handleMenu} />
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default ColumnFilter;
