export enum ENTITIES {
    DOCUMENTS = 'documents',
    IMPORT = 'import',
    INSIGHTS = 'insights',
    PROJECTS = 'projects',
    PROJECT_MEMBERS = 'project_members',
    REPORTS = 'reports',
    SAVED_QUERIES = 'saved_queries',
    SOURCES = 'sources',
    TOPICS = 'topics',
    TAGS = 'tags',
    WORKSPACES = 'workspaces',
}

export type FirstLevelEntities = ENTITIES.WORKSPACES;

export type SecondLevelEntities =
    | ENTITIES.DOCUMENTS
    | ENTITIES.IMPORT
    | ENTITIES.INSIGHTS
    | ENTITIES.PROJECTS
    | ENTITIES.PROJECT_MEMBERS
    | ENTITIES.REPORTS
    | ENTITIES.SAVED_QUERIES
    | ENTITIES.SOURCES
    | ENTITIES.TAGS
    | ENTITIES.TOPICS;
