import React from 'react';

import { Toaster } from 'react-hot-toast';
const ToastAlert = (props) => {
    return (
        <Toaster
            {...props}
            toastOptions={{
                duration: 5000,
                success: {
                    style: {
                        fontWeight: 300,
                        background: 'rgb(97 211 69)',
                        color: '#fff',
                    },
                },
                error: {
                    style: {
                        fontWeight: 300,
                        background: '#ff4b4b',
                        color: '#fff',
                    },
                },
            }}
        />
    );
};

export default ToastAlert;
