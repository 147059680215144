import {
    FilledFolderTreeInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';

export const mergeTopicsWithFolders = (
    topics: TopicInterfaceDetailed[],
    folderStructure: FolderTreeInterface[]
): FilledFolderTreeInterface[] => {
    const modifiedStructure = [...folderStructure];
    return modifiedStructure.map((folder) => {
        folder.items = topics.filter((t) => t.folder_id === folder.id);

        if (folder.children) {
            folder.children = mergeTopicsWithFolders(topics, folder.children);
        }

        return folder;
    });
};
