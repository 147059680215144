import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateFolderDTO,
    FolderInterface,
    UpdateFolderDTO,
} from '~/interfaces/entities';

const API_URL = environment.apiUrl + '/folders';

async function getFolders(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<PagedResponse<FolderInterface>>(
        API_URL + `/?${queryString.stringify(options)}`
    );
}

async function createFolder(data: CreateFolderDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<FolderInterface>,
        CreateFolderDTO
    >(API_URL, data);
}

async function updateFolder(id: string, data: UpdateFolderDTO) {
    return requestAdapterInstance.put<
        UnpagedResponse<FolderInterface>,
        UpdateFolderDTO
    >(API_URL + `/${id}`, data);
}

async function deleteFolder(id: string) {
    return requestAdapterInstance.delete<null, null>(
        API_URL + `/${id}?force=true`
    );
}

const foldersServices = {
    getFolders,
    createFolder,
    updateFolder,
    deleteFolder,
};

export default foldersServices;
