import {
    FilledFolderTreeInterface,
    FolderInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';

export const simpleFolderSearch = (
    folders: FolderInterface[],
    searchTerm: string
) => {
    const result: FolderInterface[] = [];

    const term = searchTerm.toLowerCase();

    for (const folder of folders) {
        if (
            folder.name.toLowerCase().includes(term) ||
            folder.items?.some((item) => item.name.toLowerCase().includes(term))
        ) {
            const filteredItems = folder.items.filter((item) =>
                item.name.toLowerCase().includes(term)
            );

            result.push({
                ...folder,
                items: filteredItems,
            });
        }
    }
    return result;
};

export const searchFolders = (folderStructure, searchTerm, includeItems) => {
    if (!searchTerm) return folderStructure;
    const results = [];

    function traverse(node) {
        let match = node.name.toLowerCase().includes(searchTerm.toLowerCase());
        const nodeToFIlter = { ...node };

        if (node.items?.length && includeItems) {
            nodeToFIlter.items = nodeToFIlter.items.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            match = match || nodeToFIlter.items.length > 0;
        }

        if (match) {
            nodeToFIlter.children = node.children
                ? node.children.map(traverse).filter(Boolean)
                : [];
            return nodeToFIlter;
        }

        if (nodeToFIlter.children) {
            const matchingChildren = nodeToFIlter.children
                .map(traverse)
                .filter(Boolean);
            if (matchingChildren.length > 0) {
                const newNode = { ...nodeToFIlter };
                newNode.children = matchingChildren;
                return newNode;
            }
        }

        return null;
    }

    // if the objective is to show files, remove all the folders that don't have items
    let foldersToFilter = [...folderStructure];

    if (includeItems) {
        foldersToFilter = foldersToFilter.filter((folder) =>
            folderHasItems(folder)
        );
    }

    if (!searchTerm) return foldersToFilter;

    foldersToFilter.forEach((node) => {
        const result = traverse(node);
        if (result) {
            results.push(result);
        }
    });

    return results;
};

export const findFolderPath = (
    folderStructure: FilledFolderTreeInterface[],
    idToSearch: string,
    searchByItem = false
) => {
    let path = [];

    function traverse(node, currentPath) {
        const newPath = [
            ...currentPath,
            {
                label: node.name,
                id: node.id,
            },
        ];

        if (
            (searchByItem && node.items?.some((i) => i.id === idToSearch)) ||
            node.id === idToSearch
        ) {
            path = newPath;
            return;
        }

        if (node.children) {
            node.children.forEach((child) => traverse(child, newPath));
        }
    }

    folderStructure.forEach((node) => traverse(node, []));

    return {
        pathLabel: path.map((f) => f.label).join(' > '),
        ids: path.map((f) => f.id),
    };
};

export const copyTreeUpdatingSingleNode = (
    structure = {},
    nodeId,
    newData = {}
) => {
    let newStructure = { ...structure };

    if (nodeId === structure?.id) {
        newStructure = { ...structure, ...newData };
    }

    if (newStructure.children) {
        newStructure.children.forEach((ch, idx) => {
            newStructure.children[idx] = copyTreeUpdatingSingleNode(
                ch,
                nodeId,
                newData
            );
        });
    }

    return newStructure;
};

export const rootCopyTreeUpdatingSingleNode = (
    nodeId: string,
    newData = {},
    filledFolderTree: FolderTreeInterface[]
): FolderTreeInterface[] => {
    const newStructure: FolderTreeInterface[] = [];
    const currentStructure = filledFolderTree;

    for (const root of currentStructure) {
        const newRoot = copyTreeUpdatingSingleNode(root, nodeId, newData);
        newStructure.push(newRoot);
    }

    return newStructure;
};

export const removeFolder = (idToRemove, folderStructure) => {
    function traverse(node) {
        if (node.id === idToRemove) {
            return null;
        }

        if (node.children) {
            node.children = node.children.map(traverse).filter(Boolean);
        }

        return node;
    }

    return folderStructure.map(traverse).filter(Boolean);
};

export const getFolderItems = (folderStructure, folderIds) => {
    const items = [];

    function traverse(node, includeItems) {
        if (includeItems && node.items) {
            items.push(...node.items);
        }

        if (node.children) {
            node.children.forEach((child) =>
                traverse(child, includeItems || folderIds.includes(child.id))
            );
        }
    }

    folderStructure.forEach((node) =>
        traverse(node, folderIds.includes(node.id))
    );

    return items;
};

export const getFolderName = (folderStructure, folderId) => {
    let folderName = '';

    function traverse(node) {
        if (node.id === folderId) {
            folderName = node.name;
            return;
        }

        if (node.children) {
            node.children.forEach((child) => traverse(child));
        }
    }

    folderStructure.forEach((node) => traverse(node));

    return folderName;
};

export const filterExistingFolderIds = (folderIds, folderStructure) => {
    const idSet = new Set();

    function traverse(node) {
        idSet.add(node.id);

        if (node.children) {
            node.children.forEach(traverse);
        }
    }

    folderStructure.forEach(traverse);

    return folderIds.filter((id) => idSet.has(id));
};

export const folderHasItems = (folder) => {
    if (folder.items?.length) {
        return true;
    }

    if (folder.children?.length) {
        for (const child of folder.children) {
            if (folderHasItems(child)) {
                return true;
            }
        }
    }

    return false;
};

function mapToUpperMostLevelRecursive(
    folder: FolderTreeInterface,
    ids: string[]
) {
    if (folder.children) {
        for (const childFolder of folder.children) {
            mapToUpperMostLevelRecursive(childFolder, ids);
        }
    }
    ids.push(folder.id);
}

/**
 * Obtain a map that links every upper-most folder to all its chidlren (via IDs)
 * @param folders
 * @returns
 */
export function mapUpperLevelFolderToChildren(
    folders: FolderTreeInterface[]
): Record<string, string[]> {
    const map: Record<string, string[]> = {};

    for (const folder of folders) {
        map[folder.id] = [];
        if (folder.children) {
            for (const childFolder of folder.children) {
                mapToUpperMostLevelRecursive(childFolder, map[folder.id]);
            }
        }
    }

    return map;
}

/**
 * Filter the three by the given items ids
 * @param folderStructure
 * @param itemsToInclude
 * @returns
 */
export function filterFoldersByItems(
    folderStructure: FolderTreeInterface[],
    itemsToInclude: string[]
): FolderTreeInterface[] {
    const newFolders = [];

    function traverse(node) {
        if (node.items) {
            node.items = node.items.filter((item) =>
                itemsToInclude.includes(item.id)
            );
        }

        if (node.children) {
            node.children = node.children.map(traverse).filter(Boolean);
        }

        if (!node.items.length && !node.children.length) return null;
        return node;
    }

    for (const folder of folderStructure) {
        const newFolder = traverse(folder);

        if (newFolder) newFolders.push(newFolder);
    }

    return newFolders;
}
