import { CheckCircle, Icon } from 'react-feather';
import { DottedCircleIcon, MidDottedCircleIcon } from '~/assets/icons/shared';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';

const getTopicStatusIcon = (status: TOPIC_STATUSES): Icon => {
    switch (status) {
        case TOPIC_STATUSES.DONE:
            return CheckCircle;

        case TOPIC_STATUSES.IN_PROGRESS:
            return MidDottedCircleIcon as Icon;

        default:
            return DottedCircleIcon as Icon;
    }
};

export default getTopicStatusIcon;
