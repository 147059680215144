import { DragEvent, useState } from 'react';

const useDrop = (on1sHover: () => void) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout | null>(null);

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
        count1sDragHover();
    };

    const count1sDragHover = () => {
        const timer = setTimeout(() => {
            on1sHover?.();
        }, 1000);
        setHoverTimer(timer);
    };

    const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
        if (hoverTimer) {
            clearTimeout(hoverTimer);
            setHoverTimer(null);
        }
    };

    const handleDrop = (
        e: DragEvent<HTMLDivElement>,
        onHandleDrop: (id: string) => void
    ) => {
        e.preventDefault();
        setIsDraggingOver(false);
        const itemId = e.dataTransfer.getData('text/plain');
        onHandleDrop && onHandleDrop(itemId);
    };

    return {
        handleDragOver,
        handleDragLeave,
        handleDrop,
        isDraggingOver,
    };
};

export default useDrop;
