import { environment } from '~/config/env';
import requestAdapterInstance from '~/helpers/requestAdapter';
import { UpdateUserPasswordDTO } from '~/interfaces/entities';

const API_URL = environment.apiUrl + '/users';

const userServices = {
    updateUserPassword,
};
export default userServices;

async function updateUserPassword(data: UpdateUserPasswordDTO) {
    const url = `${API_URL}/recover_password`;
    const [error, response] = await requestAdapterInstance.publicPost<
        null,
        UpdateUserPasswordDTO
    >(url, data);

    if (error) {
        return { error, code: response.code };
    }
    return { error: false, code: null };
}
