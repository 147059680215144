import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { OptionInterface } from '~/components/UIElements/SelectInput';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';
import getTopicStatusIcon from '~/modules/Topics/helpers/getTopicStatusIcon';

const useTopicStatusOptions = (): {
    options: OptionInterface[];
} => {
    const intl = useIntl();

    const TOPIC_STATUS_OPTIONS = useMemo(
        () => [
            {
                value: TOPIC_STATUSES.PENDING,
                label: intl.formatMessage({ id: 'pending' }),
                icon: getTopicStatusIcon(TOPIC_STATUSES.PENDING),
            },
            {
                value: TOPIC_STATUSES.IN_PROGRESS,
                label: intl.formatMessage({ id: 'in_progress' }),
                icon: getTopicStatusIcon(TOPIC_STATUSES.IN_PROGRESS),
            },
            {
                value: TOPIC_STATUSES.DONE,
                label: intl.formatMessage({ id: 'done' }),
                icon: getTopicStatusIcon(TOPIC_STATUSES.DONE),
            },
        ],
        []
    );

    return { options: TOPIC_STATUS_OPTIONS };
};

export default useTopicStatusOptions;
