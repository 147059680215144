import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Avatar = ({
    value = '',
    size = 'regular',
    disabled = false,
    ...rest
}) => {
    return (
        <div
            title={value}
            className={`dg-avatar animate__animated animate__fadeIn  dg-avatar-${size} ${
                disabled ? 'dg-avatar__disabled' : ''
            }`}
            {...rest}
        >
            {value}
        </div>
    );
};

Avatar.propTypes = {
    value: PropTypes.string.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'regular', 'large']),
};

export default Avatar;
