function OpenedFolderIcon({
    onClick,
}: {
    onClick?: (e: React.MouseEvent) => void;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={(e) => onClick && onClick(e)}
        >
            <path
                fill="#1A1A1A"
                fillRule="evenodd"
                d="M4.459 6.999A1.333 1.333 0 015.749 6h8.723c.872 0 1.51.823 1.29 1.668l-1.554 6a1.333 1.333 0 01-1.29.999H4.194c-.872 0-1.51-.824-1.29-1.668l1.554-6zm10.013.334H5.75l-1.554 6h8.723l1.554-6z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#1A1A1A"
                fillRule="evenodd"
                d="M.667 3.333a2 2 0 012-2h3.12c.207 0 .402.096.528.26l1.346 1.74h5.005a.667.667 0 110 1.334H7.333a.667.667 0 01-.527-.26L5.46 2.668H2.667A.667.667 0 002 3.334v8a.667.667 0 11-1.333 0v-8z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default OpenedFolderIcon;
