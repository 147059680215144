import { ReactElement, Ref, forwardRef } from 'react';
import {
    ContentRenderer,
    Popover,
    PopoverAlign,
    PopoverPosition,
} from 'react-tiny-popover';

import './index.scss';

interface BasePopoverInterface {
    isOpen: boolean;
    positions?: PopoverPosition[];
    align?: PopoverAlign;
    content: ContentRenderer | JSX.Element;
    onClickOutside?: () => void;
    children: ReactElement;
    parentContainer?: HTMLElement;
    boundaryElement?: HTMLElement;
}

const BasePopover = forwardRef(
    (
        {
            isOpen,
            positions = ['bottom', 'left', 'right'] as PopoverPosition[],
            align = 'start' as PopoverAlign,
            content,
            onClickOutside,
            parentContainer,
            boundaryElement,
            children,
        }: BasePopoverInterface,
        ref: Ref<HTMLElement>
    ) => {
        return (
            <Popover
                isOpen={isOpen}
                positions={positions}
                align={align}
                onClickOutside={onClickOutside}
                content={content || null}
                ref={ref}
                parentElement={parentContainer}
                boundaryElement={boundaryElement}
            >
                {children}
            </Popover>
        );
    }
);

export default BasePopover;
