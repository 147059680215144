import React, { useState } from 'react';
import './NewColumnModal.scss';
import { BaseModal, Button, TextField } from '../UIElements';
import { X } from 'react-feather';

const NewColumnModal = ({ handleClose, column, callback, intl, isEdit }) => {
    const [newColumn, setNewColumn] = useState(column);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleError = (err) => {
        setError(err);
        setTimeout(() => {
            setError(null);
        }, [3000]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!newColumn.name.trim())
            return handleError({ name: 'required_field' });

        setLoading(true);

        setTimeout(() => {
            callback(newColumn);
        }, [500]);
    };
    return (
        <BaseModal size="small" handleClose={handleClose}>
            <div className="create-column-dialog">
                <div>
                    <h4>
                        {intl.formatMessage({
                            id: isEdit ? 'update_column' : 'create_column',
                        })}
                    </h4>
                    <X onClick={handleClose} />
                </div>
                <form onSubmit={handleSubmit}>
                    <TextField
                        inputProps={{
                            onChange: (e) =>
                                setNewColumn({
                                    ...newColumn,
                                    name: e.target.value,
                                }),
                            value: newColumn.name,
                            autoFocus: true,
                            placeholder: 'e.g. Statistics',
                        }}
                        label={intl.formatMessage({ id: 'name' })}
                        error={
                            error?.name &&
                            intl.formatMessage({ id: error.name })
                        }
                    />

                    <Button variant="accent" type="submit" disabled={loading}>
                        {intl.formatMessage({
                            id: isEdit ? 'update' : 'create',
                        })}
                    </Button>
                </form>
            </div>
        </BaseModal>
    );
};

export default NewColumnModal;
