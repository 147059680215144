import { WorkspaceInterfaceDetailed } from '~/interfaces/entities';
import { WORKSPACE_STORAGE_KEY } from '~/modules/Workspaces/constants/workspaceStorage';
import { QueryParamsInterface } from '~/types/queryParams.interface';
import { getItemFromLocalStorage } from '~/utils/handleItemInLocalStorage';

const getDefaultWsToSelect = (
    queryParams: QueryParamsInterface | null,
    workspaces: WorkspaceInterfaceDetailed[]
): string => {
    let defaultWs = '';

    // check which is the latest workspace that the user has selected (stored in Lstorage)
    const latestWorkspace = getItemFromLocalStorage<string>(
        WORKSPACE_STORAGE_KEY
    );

    if (latestWorkspace) {
        defaultWs = latestWorkspace;
    }

    // check if the user comes from a query link
    const queryWorkspaceId = queryParams?.workspace;

    if (queryWorkspaceId) {
        defaultWs = queryWorkspaceId;
    }

    // check if the queried/latest workspace still exists
    const defaultWsExist = workspaces.some((ws) => ws.id === defaultWs);

    return defaultWsExist ? defaultWs : workspaces[0].id;
};

export default getDefaultWsToSelect;
