import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import {
    InputNumber,
    InputNumberValueChangeEvent,
} from 'primereact/inputnumber';
import formatNumber from '../../helpers/formatNumber';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';

interface EditableCellProps {
    value: string | number | undefined;
    type: string;
    allowEdit?: boolean;
    onCompleteEdit?: (newValue: string | number) => Promise<void>;
}

const EditableCell: React.FC<EditableCellProps> = ({
    value,
    type,
    allowEdit = true,
    onCompleteEdit,
}) => {
    const [inputValue, setInputValue] = useState<string | number | null>(
        value === undefined ? null : value
    );

    useEffect(() => {
        setInputValue(value === undefined ? null : value);
    }, [value]);

    function handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            onCompleteEdit && onCompleteEdit(inputValue);
        }

        if (e.key === 'Escape') {
            setInputValue && setInputValue(value);
        }
    }
    const onChange = (newValue: string | number) => {
        onCompleteEdit && onCompleteEdit(newValue);
        setInputValue(newValue);
    };

    switch (type) {
        case DYNAMIC_PROPERTY_TYPES.NUMBER:
            return allowEdit ? (
                <InputNumber
                    value={inputValue as number}
                    onValueChange={(e: InputNumberValueChangeEvent) =>
                        onChange(e.value)
                    }
                    allowEmpty
                    maxFractionDigits={10}
                    onKeyDown={handleKeyDown}
                />
            ) : (
                <div className="dg-custom-cell">
                    {formatNumber(inputValue as number, {
                        maximumFractionDigits: 10,
                    })}
                </div>
            );
        case DYNAMIC_PROPERTY_TYPES.TEXT:
            return allowEdit ? (
                <InputText
                    value={(inputValue as string) || ''}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={() => onCompleteEdit(inputValue)}
                />
            ) : (
                <div className="dg-custom-cell">{inputValue}</div>
            );
        default:
            return null;
    }
};

export default EditableCell;
