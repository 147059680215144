import queryString from 'query-string';
import { environment } from '~/config/env';
import {
    CGDocumentInsightInterface,
    CGPublicDocumentInterfaceDetailed,
    UpdateCGDocumentsTagsDTO,
} from '../interfaces/entities/CGDocument.interface';
import { commentsServices } from '~/services';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CGCreateDocumentDynamicValueDTO,
    CGCreateDocumentSelectOptionDTO,
    CGDocumentDynamicPropertyInterface,
    CGDocumentDynamicPropertyInterfaceDetailed,
    CGDocumentDynamicValueInterface,
    CGDocumentInterface,
    CGDocumentInterfaceDetailed,
    CGDocumentTablePositionInterface,
    CGUpdateDocumentDynamicValueDTO,
    CGUpdateDocumentSelectOptionDTO,
    CreateCGDocumentDynamicPropertyDTO,
    UpdateCGDocumentDTO,
    UpdateCGDocumentDynamicPropertyDTO,
    UpdateDynamicValueSelectOptionsDTO,
} from '~/interfaces/entities';
import triggerSaveFilePrompt from '~/utils/triggerSaveFilePrompt';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';

const API_URL = environment.apiUrl + '/documents';

const documentServices = {
    deleteFiles,
    downloadFiles,
    getDocuments,
    updateDocument,
    getColumnValues,
    getDocument,
    updateDocumentsTags,
    getDocumentInsights,
    getDocumentTablePositions,
    getDocumentDynamicValues,
    getDocumentDynamicProperties,
    appendImagesToDocumentInsights,
    createDocumentDynamicProperty,
    deleteDocumentDynamicProperty,
    updateDocumentDynamicValue,
    createDocumentDynamicValue,
    updateDocumentDynamicProperty,
    createDocumentDynamicOptions,
    updateDocumentDynamicOptions,
    deleteDocumentDynamicOptions,
    updateDynamicValueOptions,
    getDocumentInsightsColumnValues,
    updateTablePositions,
    getPublicDocuments,
};
export default documentServices;

async function deleteFiles(ids: string[] = []) {
    const url =
        environment.apiUrl + '/documents?' + queryString.stringify({ ids });
    return requestAdapterInstance.delete<null, null>(url);
}

async function updateDocument(
    documentId: string,
    payload: UpdateCGDocumentDTO
) {
    const url = `${environment.apiUrl}/documents/${documentId}`;
    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentInterface>,
        UpdateCGDocumentDTO
    >(url, payload);
}

async function downloadFiles(documentIds: string[]) {
    const url =
        environment.apiUrl +
        '/files/zipped?' +
        queryString.stringify({ ids: documentIds });

    const [error, response] = await requestAdapterInstance.getZipBlob(url);

    if (error) {
        return { ...response };
    }

    const blob = response;
    const fileTypes = [
        {
            description: 'Files',
            accept: {
                'application/zip': ['.zip'],
                'application/x-rar-compressed': ['.rar'],
            },
        },
    ];
    const { triggerSuccessToast } = await triggerSaveFilePrompt(
        blob,
        'Cogny-files.zip',
        fileTypes
    );

    return { triggerSuccessToast };
}

async function getDocuments(
    options: Record<string, unknown>,
    concise?: boolean
) {
    return requestAdapterInstance.get<
        PagedResponse<CGDocumentInterfaceDetailed>
    >(
        `${API_URL}/${concise ? '' : 'detailed'}?${queryString.stringify(options)}`
    );
}

async function getDocument(documentId: string) {
    const [error, response] = await requestAdapterInstance.get<
        PagedResponse<CGDocumentInterfaceDetailed>
    >(`${API_URL}/detailed?ids=${documentId}`);

    if (error) {
        return { ...response };
    }

    return { document: response.data[0], error };
}

// pending to type response
async function getColumnValues(options: Record<string, unknown>) {
    return requestAdapterInstance.get(
        `${API_URL}/detailed/metadata?${queryString.stringify(options)}`
    );
}

async function updateDocumentsTags(payload: UpdateCGDocumentsTagsDTO) {
    analytics.trackGenericEvent(EVENT_NAMES_ENUM.TAG_DOCUMENT);

    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentInterfaceDetailed[]>,
        UpdateCGDocumentsTagsDTO
    >(`${API_URL}/tags`, payload);
}

// Documents view for insights

async function getDocumentInsights(options: Record<string, unknown> = {}) {
    const url = `${API_URL}/table_view?${queryString.stringify(options)}`;

    return requestAdapterInstance.get<
        PagedResponse<CGDocumentInsightInterface>
    >(url);
}

async function getDocumentTablePositions(
    options: Record<string, unknown> = {}
) {
    const url = `${environment.apiUrl}/document_table_positions?${queryString.stringify(
        options
    )}`;

    return requestAdapterInstance.get<
        UnpagedResponse<CGDocumentTablePositionInterface[]>
    >(url);
}

// get cell values
async function getDocumentDynamicValues(options: Record<string, unknown> = {}) {
    const url = `${environment.apiUrl}/document_dynamic_values?${queryString.stringify(options)}`;

    return requestAdapterInstance.get<
        PagedResponse<CGDocumentDynamicValueInterface>
    >(url);
}

// get dynamic columns
async function getDocumentDynamicProperties(
    options: Record<string, unknown> = {}
) {
    const url = `${environment.apiUrl}/document_dynamic_properties/detailed?${queryString.stringify(options)}`;

    return requestAdapterInstance.get<
        UnpagedResponse<CGDocumentDynamicPropertyInterfaceDetailed[]>
    >(url);
}

// create dynamic column
async function createDocumentDynamicProperty(
    payload: CreateCGDocumentDynamicPropertyDTO
) {
    const url = `${environment.apiUrl}/document_dynamic_properties`;

    return requestAdapterInstance.post<
        UnpagedResponse<CGDocumentDynamicPropertyInterface>,
        CreateCGDocumentDynamicPropertyDTO
    >(url, payload);
}

// update dynamic column
async function updateDocumentDynamicProperty(
    id: string,
    payload: UpdateCGDocumentDynamicPropertyDTO
) {
    const url = `${environment.apiUrl}/document_dynamic_properties/${id}`;

    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentDynamicPropertyInterface>,
        UpdateCGDocumentDynamicPropertyDTO
    >(url, payload);
}

// delete dynamic column
async function deleteDocumentDynamicProperty(id: string) {
    const url = `${environment.apiUrl}/document_dynamic_properties/${id}`;

    return requestAdapterInstance.delete<null, null>(url);
}

async function updateDocumentDynamicValue(
    id: string,
    payload: CGUpdateDocumentDynamicValueDTO
) {
    const url = `${environment.apiUrl}/document_dynamic_values/${id}`;

    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentDynamicValueInterface>,
        CGUpdateDocumentDynamicValueDTO
    >(url, payload);
}

async function createDocumentDynamicValue(
    payload: CGCreateDocumentDynamicValueDTO
) {
    const url = `${environment.apiUrl}/document_dynamic_values`;

    return requestAdapterInstance.post<
        UnpagedResponse<CGDocumentDynamicValueInterface>,
        CGCreateDocumentDynamicValueDTO
    >(url, payload);
}

// create items for multi/single select
async function createDocumentDynamicOptions(
    payload: CGCreateDocumentSelectOptionDTO
) {
    const url = `${environment.apiUrl}/document_select_options`;

    return requestAdapterInstance.post<
        UnpagedResponse<CGCreateDocumentSelectOptionDTO>,
        CGCreateDocumentSelectOptionDTO
    >(url, payload);
}

// update item for multi/single select
async function updateDocumentDynamicOptions(
    id: string,
    payload: CGUpdateDocumentSelectOptionDTO
) {
    const url = `${environment.apiUrl}/document_select_options/${id}`;

    return requestAdapterInstance.put<
        UnpagedResponse<CGUpdateDocumentSelectOptionDTO>,
        CGUpdateDocumentSelectOptionDTO
    >(url, payload);
}

// delete item for multi/single select
async function deleteDocumentDynamicOptions(id: string) {
    const url = `${environment.apiUrl}/document_select_options/${id}`;

    return requestAdapterInstance.delete<null, null>(url);
}

// update the select options of an specific cell
async function updateDynamicValueOptions(
    cellId: string,
    payload: UpdateDynamicValueSelectOptionsDTO
) {
    const url = `${environment.apiUrl}/document_dynamic_values/${cellId}/select_options`;

    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentDynamicValueInterface>,
        UpdateDynamicValueSelectOptionsDTO
    >(url, payload);
}

// update table positions
async function updateTablePositions(columnId: string, position: number) {
    const url = `${environment.apiUrl}/document_table_positions/${columnId}`;

    return requestAdapterInstance.put<
        UnpagedResponse<CGDocumentTablePositionInterface>,
        CGDocumentTablePositionInterface
    >(url, { position });
}

async function getDocumentInsightsColumnValues(
    options: Record<string, unknown> = {}
) {
    const url = `${API_URL}/table_view/metadata?${queryString.stringify(options)}`;

    return requestAdapterInstance.get(url);
}

async function appendImagesToDocumentInsights(
    documents: CGDocumentInsightInterface[]
) {
    const imagePromises: Promise<void>[] = [];
    documents.forEach(async (doc) => {
        doc.latest_comments.forEach((insight, idx) => {
            if (insight.screenshot) {
                imagePromises.push(
                    commentsServices
                        .getCommentImage(insight.id)
                        .then(([error, response]) => {
                            if (!error) {
                                doc.latest_comments[idx].image = response;
                            }
                        })
                );
            }
        });

        // return doc;
    });
    await Promise.all(imagePromises);

    return documents;
}

async function getPublicDocuments(options: Record<string, unknown> = {}) {
    const url =
        environment.apiUrl +
        '/public_documents/detailed?' +
        queryString.stringify(options);

    return requestAdapterInstance.get<
        PagedResponse<CGPublicDocumentInterfaceDetailed>
    >(url);
}
