import { importServices } from '~/services';
import asyncPool from 'tiny-async-pool';
import { ImportFileTypes } from '~/constants/importFiles';
import { getErrorCode } from '~/utils/getErrorCode';
import { ImportDataInterface } from '~/context/upload';
import { FileToImportInterface } from '~/modules/Import/types/importFiles';

/**
 * @param importId
 * @param totalFileCount Amount of files that were originally intended to be uploaded
 * @param failedFileCount Files that could not be uploaded
 * @param addedToQueue if files are being appended to a running import
 */
export interface onFinishImportInterface {
    importId: string;
    failedFileCount: number;
    totalFileCount: number;
    addedToQueue: boolean;
}

interface useQueueUploadInterface {
    maxFiles: number;
    onFinish: (data: onFinishImportInterface) => Promise<void>;
    onFileUpload: (data: { name: string }) => void;
}

const useQueueUpload = ({
    maxFiles = 2,
    onFinish,
    onFileUpload,
}: useQueueUploadInterface) => {
    const startUpload = async (importData: ImportDataInterface) => {
        const { filesToUpload, importId, addedToQueue } = importData;

        const uploaded = [];
        for await (const file of asyncPool(maxFiles, filesToUpload, (f) =>
            uploadFile(f, importId)
        )) {
            const payload = { ...file, importId };
            uploaded.push(payload);
        }

        onFinish({
            importId,
            totalFileCount: filesToUpload.length,
            failedFileCount: uploaded.filter((f) => f.error_msg).length,
            addedToQueue,
        });
    };

    const uploadFile = async (
        fileItem: FileToImportInterface,
        importId: string
    ) => {
        const fileIsWeblink = fileItem.importType === ImportFileTypes.URL;
        const name =
            fileItem.data instanceof File ? fileItem.data.name : fileItem.data;

        const [error, response] = fileIsWeblink
            ? await importServices.uploadWebURL({
                  // when file is a link, data is the link/string
                  url: fileItem.data,
                  import_id: importId,
              })
            : await importServices.uploadFile(fileItem.data, importId);

        if (error) {
            return {
                name,
                status: 'rejected',
                error_msg: getErrorCode(response.error_code),
                id: fileItem.id,
            };
        }

        onFileUpload({ name });

        return {
            name,
            status: 'uploaded',
            id: fileItem.id,
            error_msg: undefined,
        };
    };

    return { startUpload };
};

export default useQueueUpload;
