import { useIntl } from 'react-intl';
import { FilledFolderTreeInterface } from '~/interfaces/entities';
import { DeleteConfirmationDialog } from '~/components/UIElements';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import CreateEditSubFolderDialog from './Dialogs/CreateEditSubFolderDialog';
import CreateTagDialog from './Dialogs/CreateTagDialog';
import CreateTopicDialog from './Dialogs/CreateTopicDialog';

interface FolderDialogsProps {
    cardRef: React.RefObject<HTMLDivElement>;
    folder: FilledFolderTreeInterface;
    deleteMenu: FloatMenuInterface;
    createSubfolderMenu: FloatMenuInterface;
    renameMenu: FloatMenuInterface;
    createTagMenu: FloatMenuInterface;
    createTopicMenu: FloatMenuInterface;
    onDeleteFolder: (e: React.MouseEvent) => void;
    handleFolderCreation: (folderName: string) => void;
    onCreateTag: (tagName: string) => void;
    onCreateTopic: () => void;
}

// todo this as a prop not inside of folder tree
const FolderDialogs = ({
    cardRef,
    folder = {} as FilledFolderTreeInterface,
    deleteMenu,
    createSubfolderMenu,
    renameMenu,
    createTagMenu,
    createTopicMenu,
    onDeleteFolder,
    handleFolderCreation,
    onCreateTag,
    onCreateTopic,
}: FolderDialogsProps) => {
    const intl = useIntl();

    return (
        <>
            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    callback={onDeleteFolder}
                    buttonText={intl.formatMessage({
                        id: 'delete_folder_and_items',
                    })}
                    text={intl.formatMessage({
                        id: 'delete_folder_warning',
                    })}
                    title={
                        intl.formatMessage({
                            id: 'delete_folder',
                        }) + '?'
                    }
                />
            )}
            <CreateEditSubFolderDialog
                folder={folder}
                createSubfolderMenu={createSubfolderMenu}
                renameMenu={renameMenu}
                cardRef={cardRef}
                onFolderCreation={handleFolderCreation}
            />
            <CreateTagDialog
                createTagMenu={createTagMenu}
                cardRef={cardRef}
                onCreateTag={onCreateTag}
            />
            <CreateTopicDialog
                folder={folder}
                createTopicMenu={createTopicMenu}
                cardRef={cardRef}
                onCreateTopic={onCreateTopic}
            />
        </>
    );
};

export default FolderDialogs;
