import { useCallback } from 'react';
import { WorkspaceInterfaceDetailed } from '~/interfaces/entities';
import { UseWorkspacesInterface } from '~/modules/Workspaces/types/useWorkspaces.interface';
import { useShallow } from 'zustand/react/shallow';
import useWorkspacesStore from '~/modules/Workspaces/stores';

const useWorkspaces = (): UseWorkspacesInterface => {
    const { workspaces, selectedWorkspaceId } = useWorkspacesStore(
        useShallow((state) => ({
            workspaces: state.workspaces,
            handleSelectedWorkspace: state.handleSelectedWorkspace,
            selectedWorkspaceId: state.selectedWorkspaceId,
        }))
    );

    const getWorkspaceById = useCallback(
        (wsId: string) => workspaces.find((w) => w.id === wsId) || null,
        [workspaces]
    );

    const getUserRoleInWorkspace = useCallback(
        (ws: WorkspaceInterfaceDetailed) => {
            const userMembership = ws.details?.user_membership;

            if (!userMembership) return null;

            return userMembership.role;
        },
        []
    );

    const selectedWorkspace =
        workspaces.find((ws) => ws.id === selectedWorkspaceId) || null;

    return {
        selectedWorkspace,
        getWorkspaceById,
        getUserRoleInWs: getUserRoleInWorkspace,
    };
};

export default useWorkspaces;
