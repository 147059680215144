export enum PillVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    HEADER = 'header',
    TERTIARY = 'tertiary',
    BORDERED = 'bordered',

    // by color
    TURQUOISE = 'turquoise',
    SUNFLOWER = 'sunflower',
    PETER = 'peter',
    ELECTRIC = 'electric',
    AMETHYST = 'amethyst',
    EMERALD = 'emerald',
    CARROT = 'carrot',
    ALIZARIN = 'alizarin',
    PINK = 'pink',
}
