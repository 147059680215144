import './index.scss';
import RichTextInput from '../RichTextInput';
import Skeleton from 'react-loading-skeleton';

export interface RichTextInputPropsInterface {
    loading?: boolean;
    placeholder?: string;
    value: string;
    disabled?: boolean;
    onChange: (html: string) => void;
}
interface RichTextFieldProps {
    hint?: string;
    label?: string;
    error?: string;
    className?: string;
    inputProps: RichTextInputPropsInterface;
    loading?: boolean;
}

const RichTextField: React.FC<RichTextFieldProps> = ({
    hint,
    label,
    error,
    className = '',
    loading,
    inputProps,
}) => {
    return (
        <div className={`dg-rich-text-field ${className} `}>
            {label && <label>{label}</label>}

            {loading ? (
                <Skeleton className="dg-rich-text-field__loading" count={1} />
            ) : (
                <RichTextInput loading={loading} {...inputProps} />
            )}

            {hint && <span className="dg-rich-text-field-hint">{hint}</span>}
            {error && <span className="dg-rich-text-field-error">{error}</span>}
        </div>
    );
};

export default RichTextField;
