import { useEffect } from 'react';
import './TopicCell.scss';
import { useFloatMenu, useOpenFolders } from '../../../../hooks';
import { commentsServices } from '../../../../services';
import { BaseModal, FolderSelector } from '../../../../components/UIElements';
import { findFolderPath } from '../../../../utils/traverseFolderTreeFunctions';
import toast from 'react-hot-toast';
import { UNCATEGORIZED_FOLDER_NAME } from '../../../../constants/folders';
import TopicItem from '../TopicItem';
import { IntlShape } from 'react-intl';
import {
    FilledFolderTreeInterface,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';
import { getErrorCode } from '~/utils/getErrorCode';

interface TopicCellProps {
    intl: IntlShape;
    insight: InsightInterfaceDetailed;
    onUpdate: () => void;
    folderStructure?: FilledFolderTreeInterface[];
    disabled?: boolean;
}

const TopicCell: React.FC<TopicCellProps> = ({
    intl,
    insight,
    onUpdate,
    folderStructure = [],
    disabled,
}) => {
    const menu = useFloatMenu();
    const { openedFolders, openFolder, setOpenedFolders } = useOpenFolders();

    const onSelectTopic = async (topicId: string) => {
        const [error, response] = await commentsServices.assignTopicToComments({
            ids: [insight.id],
            topic_id: topicId,
        });

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        menu.handleMenu();

        toast.success(intl.formatMessage({ id: 'topic_updated_successfully' }));

        onUpdate();
    };

    useEffect(() => {
        let parentIds = [];

        if (!insight.topic.folder_id) {
            parentIds = [UNCATEGORIZED_FOLDER_NAME];
        } else {
            parentIds = findFolderPath(
                folderStructure,
                insight.topic.id,
                true
            )?.ids;
        }

        setOpenedFolders(parentIds);
    }, []);

    const handleClick = () => {
        if (disabled) return;
        menu.setShowMenu(true);
    };
    return (
        <>
            {menu.showMenu && (
                <BaseModal
                    showCloseIcon
                    handleClose={menu.handleMenu}
                    noPadding
                >
                    <FolderSelector
                        structure={folderStructure}
                        value={insight.topic.id}
                        onConfirm={(topicId) => {
                            onSelectTopic(topicId);
                            menu.handleMenu();
                        }}
                        onCancel={menu.handleMenu}
                        expandedFolders={openedFolders}
                        onExpandFolder={openFolder}
                        placeholder={intl.formatMessage({
                            id: 'search_folders_and_topics',
                        })}
                        confirmButtonText={intl.formatMessage({ id: 'save' })}
                        showFiles
                        showUncategorizedFolder
                        loading={false}
                    />
                </BaseModal>
            )}
            <div className="cg-topic-cell" onClick={handleClick}>
                <TopicItem
                    topic={insight.topic}
                    lite
                    onClick={handleClick}
                    showInsightsCount={false}
                    tooltipId={insight.id}
                    colored
                    isSelected={false}
                />
            </div>
        </>
    );
};

export default TopicCell;
