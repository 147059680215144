import React, { useCallback, useRef, useState } from 'react';

interface ResizableBoxOptions {
    initialWidth?: number;
    originPlace?: 'left' | 'right';
    onChange?: (width: number) => void;
}

const useResizableBox = ({
    initialWidth = 300,
    originPlace = 'left',
    onChange,
}: ResizableBoxOptions) => {
    const boxRef = useRef<HTMLDivElement>(null);

    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(initialWidth);

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = useCallback(
        (mouseMoveEvent: MouseEvent) => {
            if (!boxRef.current) return;

            if (isResizing) {
                let newWidth;

                if (originPlace === 'right') {
                    newWidth =
                        boxRef.current.getBoundingClientRect().right -
                        mouseMoveEvent.clientX;
                } else {
                    newWidth =
                        mouseMoveEvent.clientX -
                        boxRef.current.getBoundingClientRect().left;
                }

                onChange && onChange(newWidth);
                setSidebarWidth(newWidth);
            }
        },
        [isResizing]
    );

    React.useEffect(() => {
        window.addEventListener('mousemove', resize);
        window.addEventListener('mouseup', stopResizing);
        return () => {
            window.removeEventListener('mousemove', resize);
            window.removeEventListener('mouseup', stopResizing);
        };
    }, [resize, stopResizing]);

    return { sidebarWidth, startResizing, boxRef };
};

export default useResizableBox;
