import { useState, useMemo, useContext } from 'react';
import { useIntl } from 'react-intl';
import showdown from 'showdown';
import { useEntityContext } from '~/hooks';
import useTopic from '../useTopic.hook';
import { ProjectContext } from '~/context/project';
import PermissionContext from '~/context/permissions/PermissionContext';
import { PERMISSIONS } from '~/constants/memberPermissions';
import TOPIC_STATUSES from '../../constants/TopicStatuses.enum';
import { ENTITIES } from '~/constants/entities';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import useTopicStatusOptions from './useTopicStatusOptions.hook';
import {
    TopicInterface,
    TopicInterfaceDetailed,
} from '../../types/Topic.interface';
import FolderSelectorInput from '~/components/UIElements/FolderSelectorInput';
import {
    Button,
    RichTextField,
    SelectField,
    TextAreaField,
} from '~/components/UIElements';
import RestrictedUI from '~/components/RestrictedUI';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import classNames from 'classnames';

import styles from './CreateUpdateTopicForm.module.scss';

interface CreateUpdateTopicFormProps {
    callback: (newTopic?: TopicInterface) => void;
    topicToEdit?: TopicInterfaceDetailed | null;
    destinationFolderId?: string;
    initialValues?: { name: string; description: string };
    customConfirmationText?: string;
    duplicate?: boolean;
    size?: 'normal' | 'small';
}

const converter = new showdown.Converter();
converter.setOption('simpleLineBreaks', true);

const convert = (term: string) =>
    converter.makeHtml(term).replace(/\s+(?=<)/g, '');

const CreateUpdateTopicForm = ({
    topicToEdit,
    callback,
    destinationFolderId,
    initialValues: defaultValues,
    customConfirmationText,
    duplicate,
    size = 'normal',
}: CreateUpdateTopicFormProps) => {
    const intl = useIntl();

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);
    const { isAllowedTo } = useContext(PermissionContext);

    const userWantsToEdit = Boolean(topicToEdit && !duplicate);

    const [isEditing, setIsEditing] = useState(userWantsToEdit);
    const { folderStructure, loading, error, handleError, updateTopic } =
        useTopic({
            isEditing,
        });

    const initialValues: TopicInterface = {
        project_id: selectedProject?.id || '',
        folder_id: '',
        id: '',
        name: '',
        description: '',
        status: TOPIC_STATUSES.PENDING,
        ...defaultValues,
        ...(topicToEdit || {}),
    };

    const initialState = topicToEdit ? { ...topicToEdit } : initialValues;

    const [topic, setTopic] =
        useState<Partial<TopicInterfaceDetailed>>(initialState);

    // since rich text component is not re rendered in each state change, we need to have a separate state for it
    const [richText, setRichText] = useState(
        convert(topicToEdit?.description || '')
    );

    const onSubmit = async () => {
        if (!selectedProject) return;
        if (!topic.name?.trim()) {
            handleError({ name: 'required_field' });
            return;
        }

        let markdownValue = converter.makeMarkdown(richText);
        // sometimes the markdown add two extra lines at the end --> /n/n, remove if exist
        if (markdownValue.endsWith('\n\n'))
            markdownValue = markdownValue.slice(0, -2);

        const folderId =
            topic.folder_id === UNCATEGORIZED_FOLDER_NAME || !topic.folder_id
                ? null
                : topic.folder_id;

        const payload: TopicInterface = {
            id: topicToEdit?.id || '',
            name: topic.name,
            description: markdownValue,
            project_id: selectedProject.id,
            folder_id: destinationFolderId ?? folderId,
            status: topic.status || TOPIC_STATUSES.PENDING,
        };

        const response = await updateTopic(payload);

        resetForm();

        if ('data' in response && response.data) {
            callback && callback(response.data);
        }
    };

    const resetForm = () => {
        setTopic({
            ...topic,
            name: '',
        });
        setRichText('');
        setIsEditing(false);
    };

    // checks if the data has changed in order to enable/disable the save button
    const dataHasChanged = useMemo(() => {
        if (!isEditing) return true;

        const originalConverted = converter
            .makeHtml(topicToEdit?.description || '')
            .replace(/\s+(?=<)/g, '');

        const hasChanged =
            topic.name !== topicToEdit?.name ||
            richText !== originalConverted ||
            topic.folder_id !== topicToEdit?.folder_id ||
            topic.status !== topicToEdit?.status;

        return hasChanged;
    }, [topic, isEditing, richText]);

    const canEdit = useMemo(
        () => isAllowedTo(ENTITIES.TOPICS, PERMISSIONS.EDIT),
        [isAllowedTo]
    );

    const { options: statusOptions } = useTopicStatusOptions();

    const selectedOption = useMemo(
        () => statusOptions.find((option) => option.value === topic.status),
        [topic.status]
    );
console.log('size', size)
    return (
        <div className={styles.cgUpdateTopicForm}>
            <div
                className={classNames(styles.cgUpdateTopicForm__fields, {
                    [styles.cgUpdateTopicForm__small]: size === 'small',
                })}
            >
                <TextAreaField
                    label={intl.formatMessage({ id: 'topic_name' })}
                    inputProps={{
                        rows: 2,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
                            setTopic({ ...topic, name: e.target.value }),
                        value: topic.name,
                        placeholder: intl.formatMessage({
                            id: 'add_topic_name',
                        }),
                        disabled: !canEdit,
                        autoFocus: true,
                    }}
                    error={
                        error?.name && intl.formatMessage({ id: error.name })
                    }
                />

                <SelectField
                    label={intl.formatMessage({ id: 'status' })}
                    inputProps={{
                        icon: selectedOption?.icon,
                        onChange: ({ value }) =>
                            setTopic({
                                ...topic,
                                status: value as TOPIC_STATUSES,
                            }),
                        value: selectedOption,
                        options: statusOptions,
                    }}
                />

                {!destinationFolderId && (
                    <FolderSelectorInput
                        disabled={!canEdit || loading}
                        selectedFolder={topic.folder_id || null}
                        onSelectFolder={(folderId) =>
                            setTopic({
                                ...topic,
                                folder_id: folderId,
                            })
                        }
                        structure={folderStructure}
                        label={intl.formatMessage({ id: 'topic_location' })}
                        placeholder={intl.formatMessage({ id: 'root' })}
                        isOptional
                    />
                )}

                <RichTextField
                    label={intl.formatMessage({ id: 'description' })}
                    inputProps={{
                        onChange: setRichText,
                        value: richText,
                        placeholder: intl.formatMessage({
                            id: 'add_description',
                        }),
                        disabled: !canEdit || loading,
                    }}
                    loading={loading}
                />

                {error?.server && (
                    <div className="error-centered-div">
                        {intl.formatMessage({ id: error.server })}
                    </div>
                )}
            </div>

            <RestrictedUI entity={ENTITIES.TOPICS} to={PERMISSIONS.EDIT}>
                <Button
                    disabled={loading || !dataHasChanged}
                    onClick={onSubmit}
                    variant="accent"
                >
                    {customConfirmationText ||
                        intl.formatMessage({
                            id: isEditing ? 'save' : 'create',
                        })}
                </Button>
            </RestrictedUI>
        </div>
    );
};

export default CreateUpdateTopicForm;
