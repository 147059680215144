import { useMemo } from 'react';
import {
    FilledFolderTreeInterface,
    FolderInterface,
} from '~/interfaces/entities';
import {
    FolderItemTypes,
    UNCATEGORIZED_FOLDER_NAME,
} from '~/constants/folders';
import RecursiveFolderTree from '../../RecursiveFolderTree';
import CustomItemComponent from './CustomItem';
import FolderItem from '../../FolderItem';

interface FolderItemsProps<T> {
    structure: FilledFolderTreeInterface;
    customItem?: (props: T) => React.ReactNode;
    selectedFolders?: string[];
    showActions?: boolean;
    showFiles?: boolean;
    globalStructure?: FilledFolderTreeInterface[];
    expandedFolders?: string[];
    expandAllFolders?: boolean;
    folderItemsType?: FolderItemTypes;
    showItemsCount?: boolean;
    onFolderClick: (folder: FilledFolderTreeInterface) => void;
    onExpandFolder?: (folderId: string) => void;
    onCreateFolderItem?: (itemName: string, parentId: string) => void;
    onCreateSubfolder?: (folderName: string, parentFolderId: string) => void;
    onDeleteFolder?: (folderId: string) => void;
    onUpdateFolder?: (folder: FolderInterface) => void;
    onSelectFolder?: (folder: FilledFolderTreeInterface) => void;
    onMoveItem?: ({ id, folderId }: { id: string; folderId: string }) => void;
}

const FolderItems = <T,>({
    structure,
    customItem: CustomItem,
    selectedFolders = [],
    showActions = true,
    showFiles = true,
    globalStructure,
    expandedFolders,
    expandAllFolders,
    folderItemsType,
    showItemsCount,
    onExpandFolder,
    onCreateFolderItem,
    onCreateSubfolder,
    onDeleteFolder,
    onFolderClick,
    onUpdateFolder,
    onSelectFolder,
    onMoveItem,
}: FolderItemsProps<T>) => {
    const ItemsList = useMemo(
        () => (
            <>
                {structure.items.map((item) =>
                    CustomItem ? (
                        <CustomItemComponent
                            key={item.id || item.value}
                            getItem={CustomItem}
                            item={item}
                        />
                    ) : (
                        <div key={item.id}>
                            <FolderItem
                                folder={item}
                                isChecked={selectedFolders.includes(item?.id)}
                                onDeleteFolder={onDeleteFolder}
                                isExpanded={false}
                                showActions={
                                    showActions &&
                                    item.name !== UNCATEGORIZED_FOLDER_NAME
                                }
                                onFolderClick={onFolderClick}
                                onMoveItem={onMoveItem}
                            />
                        </div>
                    )
                )}
            </>
        ),
        [structure.items, CustomItem, globalStructure]
    );
    return (
        <>
            {structure.children?.map((child) => (
                <RecursiveFolderTree
                    key={child.id || child.name}
                    structure={child}
                    customItem={CustomItem}
                    showActions={showActions}
                    showFiles={showFiles}
                    selectedFolders={selectedFolders}
                    expandedFolders={expandedFolders}
                    expandAllFolders={expandAllFolders}
                    globalStructure={globalStructure}
                    folderItemsType={folderItemsType}
                    showItemsCount={showItemsCount}
                    onFolderClick={onFolderClick}
                    onCreateFolderItem={onCreateFolderItem}
                    onCreateSubfolder={onCreateSubfolder}
                    onUpdateFolder={onUpdateFolder}
                    onSelectFolder={onSelectFolder}
                    onExpandFolder={onExpandFolder}
                    onDeleteFolder={onDeleteFolder}
                    onMoveItem={onMoveItem}
                />
            ))}
            {showFiles && ItemsList}
        </>
    );
};

export default FolderItems;
