const randomColors = [
    '#f59542',
    '#f56042',
    '#425af5',
    '#a742f5',
    '#a81313',
    '#a85d13',
    '#a3a813',
];

export default randomColors;
