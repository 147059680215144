import { useContext, useMemo } from 'react';
import {
    CheckCircle,
    ChevronRight,
    Copy,
    Icon,
    Maximize2,
    Trash2,
} from 'react-feather';
import { useIntl } from 'react-intl';
import {
    DottedCircleIcon,
    MidDottedCircleIcon,
    StarMenuIcon,
} from '~/assets/icons/shared';
import PinIcon from '~/assets/icons/shared/PinIcon';
import { BasicMenuItemInterface } from '~/components/UIElements/BasicMenuList/MenuItem';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import PermissionContext from '~/context/permissions/PermissionContext';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';

interface TopicActionsMenuParametersInterface {
    topic: TopicInterfaceDetailed;
    isSelected: boolean;
    onPin: (topic: TopicInterfaceDetailed) => void;
    onOpenSearchesList?: (topic: TopicInterfaceDetailed) => void;
    handleDuplication: (value: boolean) => void;
    onChangeStatus: (status: TOPIC_STATUSES) => void;
    closeMainMenu: () => void;
    openEditMenu: () => void;
    openDeleteMenu: () => void;
}

interface TopicActionsMenuInterface {
    menuTabs: BasicMenuItemInterface[];
}

const useTopicActionsMenu = ({
    topic,
    isSelected,
    onPin,
    onOpenSearchesList,
    onChangeStatus,
    handleDuplication,
    closeMainMenu,
    openEditMenu,
    openDeleteMenu,
}: TopicActionsMenuParametersInterface): TopicActionsMenuInterface => {
    const intl = useIntl();

    const { isAllowedTo } = useContext(PermissionContext);

    const MENU_TABS = useMemo(() => {
        const userCanEdit = isAllowedTo(ENTITIES.TOPICS, PERMISSIONS.EDIT);

        let basicMenuItems: BasicMenuItemInterface[] = [
            {
                icon: Maximize2,
                label: intl.formatMessage({ id: 'open' }),
                onClick: () => {
                    openEditMenu();
                    closeMainMenu();
                },
            },
            {
                icon: PinIcon as Icon,
                label: intl.formatMessage({ id: isSelected ? 'unpin' : 'pin' }),
                onClick: () => {
                    onPin(topic);
                    closeMainMenu();
                },
            },
        ];

        if (userCanEdit) {
            basicMenuItems.push({
                icon: CheckCircle,
                label: intl.formatMessage({ id: 'status' }),
                iconAfter: ChevronRight,
                onClick: () => {},
                children: [
                    {
                        icon: DottedCircleIcon as Icon,
                        label: intl.formatMessage({ id: 'pending' }),
                        onClick: () => {
                            onChangeStatus(TOPIC_STATUSES.PENDING);
                            closeMainMenu();
                        },
                    },
                    {
                        icon: MidDottedCircleIcon as Icon,
                        label: intl.formatMessage({ id: 'in_progress' }),
                        onClick: () => {
                            onChangeStatus(TOPIC_STATUSES.IN_PROGRESS);
                            closeMainMenu();
                        },
                    },
                    {
                        icon: CheckCircle,
                        label: intl.formatMessage({ id: 'done' }),
                        onClick: () => {
                            onChangeStatus(TOPIC_STATUSES.DONE);
                            closeMainMenu();
                        },
                    },
                ],
            });
        }

        const canOpenSavedSearchesMenu =
            onOpenSearchesList && topic.details.saved_search_count > 0;

        if (canOpenSavedSearchesMenu) {
            basicMenuItems.push({
                icon: StarMenuIcon as Icon,
                label: intl.formatMessage({ id: 'saved_searches' }),
                onClick: () => {
                    closeMainMenu();
                    onOpenSearchesList(topic);
                },
            });
        }

        if (userCanEdit) {
            basicMenuItems = [
                ...basicMenuItems,
                {
                    label: intl.formatMessage({ id: 'duplicate' }),
                    onClick: () => {
                        handleDuplication(true);
                        closeMainMenu();
                        openEditMenu();
                    },
                    icon: Copy,
                },
                {
                    label: intl.formatMessage({ id: 'delete' }),
                    onClick: () => {
                        openDeleteMenu();
                        closeMainMenu();
                    },
                    icon: Trash2,
                    variant: 'distructive',
                },
            ];
        }

        return basicMenuItems;
    }, [isAllowedTo, isSelected]);

    return {
        menuTabs: MENU_TABS,
    };
};

export default useTopicActionsMenu;
