import React, { useContext, useMemo, useState } from 'react';
import './TagCell.scss';
import { Pill, TagsPicker } from '../UIElements';
import { Popover } from 'react-tiny-popover';
import { useEntityContext, useFloatMenu } from '../../hooks';
import { TagsContext } from '../../context/tags';
import { documentServices, tagsServices } from '../../services';
import toast from 'react-hot-toast';
import { compareStringsAlphabetically } from '../../utils/orderStringAlphabetically';
import PermissionContext from '../../context/permissions/PermissionContext';
import { ENTITIES } from '../../constants/entities';
import { PERMISSIONS } from '../../constants/memberPermissions';
import { useIntl } from 'react-intl';
import { TagInterfaceDetailed } from '~/modules/Tags/types/Tag.interface';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { ProjectContext } from '~/context/project';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { PillVariants } from '~/components/UIElements/Pill/constants';

interface TagCellInterface {
    selectedTags: TagInterfaceDetailed[];
    documentId: string;
    onApply?: () => void;
    onOpenFile?: () => void;
    disabled?: boolean;
    renderTree?: boolean;
    folderTagsColors: { [key: string]: PillVariants };
    sortAlphabetically?: boolean;
}

const TagCell: React.FC<TagCellInterface> = ({
    selectedTags = [],
    documentId,
    onApply,
    disabled,
    onOpenFile,
    folderTagsColors,
    renderTree,
    sortAlphabetically = true,
}) => {
    const intl = useIntl();
    const [loadingTags, setLoadingTags] = useState(false);

    const menu = useFloatMenu();
    const { tags, getTags, transformedFolderStructure } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const { isAllowedTo } = useContext(PermissionContext);

    const options = useMemo(
        () => tags.map((t) => ({ value: t.id, label: t.name })),
        [tags]
    );

    const selectedOptions = useMemo(() => {
        const parsed = selectedTags.map((t) => ({
            value: t.id,
            label: t.name,
            folderId: t.tag_group_id,
        }));

        return sortAlphabetically
            ? parsed.sort((a, b) =>
                  compareStringsAlphabetically(a?.label, b?.label)
              )
            : parsed;
    }, [selectedTags]);

    const onApplyTags = async (newTagsArray) => {
        setLoadingTags(true);

        const documentTags = selectedTags.map((t) => t.id);
        const tagsToCreate = newTagsArray
            .filter((t) => t.isNew)
            .map((t) => t.value);

        // existing tags but not in document, need to be added
        const tagsToAdd = newTagsArray
            .filter((t) => !t.isNew && !documentTags.includes(t.value))
            .map((t) => t.value);

        if (tagsToCreate.length) {
            const tagPromises = tagsToCreate.map((t) =>
                tagsServices.createTag({
                    project_id: selectedProject.id,
                    name: t,
                })
            );

            const response = await Promise.all(tagPromises);
            if (response.length) {
                response.forEach(([error, response]) => {
                    if (!error) {
                        tagsToAdd.push(response.data.id);
                    }
                });
            }
        }

        const newTagsId = newTagsArray.map((t) => t.value);
        const tagsToRemove = documentTags.filter((t) => !newTagsId.includes(t));

        const payload = {
            document_ids: [documentId],
            add_tag_ids: tagsToAdd,
            remove_tag_ids: tagsToRemove,
        };

        const [error] = await documentServices.updateDocumentsTags(payload);

        setLoadingTags(false);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(intl.formatMessage({ id: 'tags_updated_successfully' }));
        menu.handleMenu();

        getTags();

        onApply?.();
    };

    const canEdit = useMemo(() => {
        const isAllowed = isAllowedTo(ENTITIES.TAGS, PERMISSIONS.EDIT);
        return isAllowed;
    }, [isAllowedTo]);

    return (
        <Popover
            isOpen={menu.showMenu}
            positions={['bottom', 'left', 'right']}
            align="start"
            content={
                <TagsPicker
                    intl={intl}
                    tags={options}
                    selectedTags={selectedOptions}
                    onApply={onApplyTags}
                    loading={loadingTags}
                    folderStructure={
                        renderTree ? transformedFolderStructure : null
                    }
                />
            }
            onClickOutside={() => menu.setShowMenu(false)}
        >
            <div
                className={`cg-tag-cell ${!disabled ? 'cg-tag-cell-editable' : ''}`}
                onClick={() => {
                    if (disabled) return;
                    if (!canEdit && onOpenFile) return onOpenFile();
                    menu.handleMenu();
                }}
            >
                {selectedOptions.map((t) => (
                    <Pill
                        key={t.value}
                        variant={
                            t.folderId
                                ? folderTagsColors[t.folderId]
                                : folderTagsColors[UNCATEGORIZED_FOLDER_NAME]
                        }
                    >
                        <p title={t.label}>{t.label}</p>
                    </Pill>
                ))}
            </div>
        </Popover>
    );
};

export default TagCell;
