import { useEffect, useState } from 'react';
import { Icon } from 'react-feather';
import classNames from 'classnames';

import './index.scss';

interface ValueInterface {
    label: string;
    value: string;
    icon?: Icon;
}

interface SwitchInputTextProps {
    value: string;
    leftValue: ValueInterface;
    rightValue: ValueInterface;
    disabled?: boolean;
    handleToggle: (val: string) => void;
    className?: string;
}

const SwitchInputText = ({
    value = '',
    leftValue,
    rightValue,
    handleToggle,
    disabled,
    className,
}: SwitchInputTextProps) => {
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        setSelected(value);
    }, [value]);

    const onToggle = (val: string) => {
        if (val === selected || disabled) return;

        setSelected(val);
        handleToggle(val);
    };

    return (
        <div
            className={classNames('cg-switch-input-text', className, {
                'cg-switch-input-text__disabled': disabled,
            })}
        >
            <div
                className={`${
                    selected === leftValue.value
                        ? 'cg-switch-input-text__selected'
                        : ''
                }`}
                onClick={() => onToggle(leftValue.value)}
            >
                {leftValue.icon && <leftValue.icon />}
                <span>{leftValue.label}</span>
            </div>
            <div
                className={`${
                    selected === rightValue.value
                        ? 'cg-switch-input-text__selected'
                        : ''
                }`}
                onClick={() => onToggle(rightValue.value)}
            >
                {rightValue.icon && <rightValue.icon />}

                <span>{rightValue.label}</span>
            </div>
        </div>
    );
};

export default SwitchInputText;
