// uncategorized is a virtual folder that includes items without folder
export const UNCATEGORIZED_FOLDER_NAME = 'Uncategorized';

export const FOLDER_ACTION = {
    rename: {
        key: 'rename',
        i8n: 'rename',
    },
    move: {
        key: 'move',
        i8n: 'move_to',
    },
    delete: {
        key: 'delete',
        i8n: 'delete',
    },
    createSubfolder: {
        key: 'create_subfolder',
        i8n: 'create_subfolders',
    },
    createTag: {
        key: 'create_tag',
        i8n: 'create_tags',
    },
    createTopic: {
        key: 'create_topic',
        i8n: 'create_topics',
    },
};

export enum FolderItemTypes {
    TOPICS = 'topics',
    TAGS = 'tags',
}
