import {
    PublicTagGroupInterfaceDetailed,
    TagGroupInterfaceDetailed,
    TagGroupInterfaceWithItems,
    UncategorizedTagsGroup,
} from '~/interfaces/entities';
import { TagInterfaceDetailed } from '~/modules/Tags/types/Tag.interface';
import { UNCATEGORIZED_FOLDER_NAME } from '../constants/folders';

/**
 * TagGroups with their corresponding tags accumulated, including the Uncategorized group.
 */
export type GroupedTags = (
    | TagGroupInterfaceWithItems
    | UncategorizedTagsGroup
)[];

const groupTagsByFolder = (
    tags: TagInterfaceDetailed[] = [],
    folders:
        | TagGroupInterfaceDetailed[]
        | PublicTagGroupInterfaceDetailed[] = []
) => {
    const groupedTags: GroupedTags = folders.map((folder) => {
        const folderTags = tags.filter((tag) => tag.tag_group_id === folder.id);

        return {
            ...folder,
            items: folderTags,
        };
    });

    const ungroupedTags = tags.filter((tag) => !tag.tag_group_id);

    if (ungroupedTags.length)
        groupedTags.push({
            id: UNCATEGORIZED_FOLDER_NAME,
            name: UNCATEGORIZED_FOLDER_NAME,
            items: ungroupedTags,
            project_id: null,
        });

    return groupedTags;
};

export default groupTagsByFolder;
