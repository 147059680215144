import { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';
import normalizeBooleanColumnValues from '~/helpers/normalizeBooleanColumnValues';

import {
    AccumulatedColumnValues,
    ColumnFilter,
    FilterAccumulation,
    SortingParameters,
    handleColumnValues,
    onApplyFilter,
    resetFilterColumn,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { DynamicPropertyInterfaceDetailed } from '~/interfaces/entities';

export interface useColumnsFilterContextInterface {
    columnValues: AccumulatedColumnValues;
    columnFilters: ColumnFilter[];
    sortParams: SortingParameters;
    filterParams: FilterAccumulation;
    filtersAreApplied: boolean;
    resetFilterColumn: resetFilterColumn;
    onApplyFilter: onApplyFilter;
    handleColumnValues: handleColumnValues;
    setColumnFilters: (filters: ColumnFilter[]) => void;
    setColumnValues: (values: AccumulatedColumnValues) => void;
    resetAllFilters: () => void;
}

const useColumnsFilterContext = (
    columns?: DynamicPropertyInterfaceDetailed[]
): useColumnsFilterContextInterface => {
    // column values without filtering
    const [originalColumnValues, setOriginalColumnValues] =
        useState<AccumulatedColumnValues>({});

    const [columnValues, setColumnValues] = useState<AccumulatedColumnValues>(
        {}
    );

    const [sortParams, setSortParams] = useState<SortingParameters>({});

    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);

    const resetFilterColumn: resetFilterColumn = (columnName, all) => {
        const filtered = columnFilters.filter((f) => f.column !== columnName);
        setColumnFilters(all ? [] : filtered);
        setSortParams({});
    };

    const resetAllFilters = () => {
        setColumnFilters([]);
        setSortParams({});
    };

    const handleColumnValues: handleColumnValues = (values) => {
        if (!columnFilters.length) {
            setOriginalColumnValues(values);
        } else {
            // show all the options for the first filter applied (excel behavior)
            const firstColumn = columnFilters[0]?.column;

            // if filter is applied by a query link, originalColumnValues is empty
            if (originalColumnValues[firstColumn])
                values[firstColumn] = originalColumnValues[firstColumn];
        }

        setColumnValues(values);
    };

    /**
     * When applying filters
     */
    const onApplyFilter: onApplyFilter = (
        { sortType, sortBy, column, values, isDynamicColumnFilter },
        getAll
    ) => {
        if (sortType) {
            setSortParams({
                sortType,
                sortBy: sortBy || column,
                sortDynamicPropertyId: isDynamicColumnFilter
                    ? sortBy
                    : undefined,
            });
        }

        if (getAll) {
            setColumnFilters(columnFilters.filter((f) => f.column !== column));
            return;
        }

        const appliedFilterIndex = columnFilters.findIndex(
            (cf) => cf.column === column
        );
        const newFilters = [...columnFilters];

        // check if the table is filtered by a query link, so overwrite it
        if (
            columnFilters.find((c) => c.column === 'ids') &&
            isDynamicColumnFilter
        ) {
            setColumnFilters([
                {
                    column: column,
                    value: values,
                },
            ]);
            return;
        }
        if (appliedFilterIndex >= 0) {
            // updating an already filtered column
            newFilters[appliedFilterIndex] = {
                column: column,
                value: values,
            };
            setColumnFilters(newFilters);
            return;
        }

        setColumnFilters([
            ...columnFilters,
            {
                column: column,
                value: values,
            },
        ]);
    };

    const filterParams = useMemo(() => {
        const filters: FilterAccumulation = {};

        columnFilters.forEach((col) => {
            filters[col.column] = col.value;
        });

        return filters;
    }, [columnFilters]);

    const filtersAreApplied = useMemo(
        () =>
            Object.keys({
                ...columnFilters,
                ...sortParams,
            }).length > 0,
        [sortParams, columnFilters]
    );

    // Just because we need to normalize boolean column values
    // note: blanks values are considered  falsy values by the backend, so we need to join falsy + blanks in only one option to be displayed
    const normalizedColumnValues: AccumulatedColumnValues = useMemo(() => {
        if (!Object.keys(columnValues).length || !columns?.length)
            return columnValues;

        const booleanColumns = columns.filter(
            (c) => c.type === DYNAMIC_PROPERTY_TYPES.BOOLEAN
        );
        if (!booleanColumns.length) return columnValues;

        const newValues = { ...columnValues };

        booleanColumns.forEach((c) => {
            const normalized = normalizeBooleanColumnValues(columnValues[c.id]);
            newValues[c.id] = normalized;
        });

        return newValues;
    }, [columnValues, columns]);

    return {
        columnValues: normalizedColumnValues,
        columnFilters,
        sortParams,
        filterParams,
        filtersAreApplied,
        setColumnFilters,
        setColumnValues,
        resetFilterColumn,
        onApplyFilter,
        handleColumnValues,
        resetAllFilters,
    };
};

export default useColumnsFilterContext;
