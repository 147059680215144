import { MutableRefObject, useState } from 'react';
import useClickOutside from './useClickOutside';

export interface FloatMenuInterface {
    showMenu: boolean;
    setShowMenu: (value: boolean) => void;
    handleMenu: () => void;
    menuRef: MutableRefObject<undefined>;
}

const useFloatMenu = (props = {}): FloatMenuInterface => {
    const { onClickOutside } = props;

    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = () => setShowMenu(!showMenu);

    const menuRef = useClickOutside(() => {
        setShowMenu((prev) => {
            // only execute the callback function when menu is open
            if (onClickOutside && prev) onClickOutside();

            return false;
        });
    });

    return { showMenu, handleMenu, menuRef, setShowMenu };
};

export default useFloatMenu;
