import React from 'react';
import './index.scss';
import { X } from 'react-feather';
import Button from '../Button';
import BaseModal from '../BaseModal';

interface DeleteConfirmationDialogInterface {
    handleClose: () => void;
    callback: (e: React.MouseEvent) => void;
    title: string;
    text: string;
    buttonText: string;
    loading?: boolean;
}
const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogInterface> = ({
    handleClose,
    callback,
    title,
    text,
    buttonText,
    loading,
}) => {
    return (
        <BaseModal size="small" handleClose={handleClose}>
            <div className="delete-confirmation-dialog">
                <div>
                    <h4> {title} </h4>
                    <X onClick={handleClose} />
                </div>
                <p>{text}</p>

                <Button
                    disabled={loading}
                    variant="distructive"
                    onClick={callback}
                >
                    {buttonText}
                </Button>
            </div>
        </BaseModal>
    );
};

export default DeleteConfirmationDialog;
