import { useState } from 'react';

const useRecentProjects = () => {
    const initialRecents = localStorage.getItem('recentProjects');
    const initialRecentsArray = initialRecents
        ? JSON.parse(initialRecents)
        : [];

    const [recentProjects, setRecentProjects] =
        useState<string[]>(initialRecentsArray);

    const addProject = (projectId: string) => {
        if (recentProjects[0] === projectId) return;
        const newRecents = [projectId, ...recentProjects].slice(0, 2);

        setRecentProjects(newRecents);

        localStorage.setItem('recentProjects', JSON.stringify(newRecents));
    };

    return {
        recentProjects,
        addProject,
    };
};

export default useRecentProjects;
