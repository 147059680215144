import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import useFloatMenu from './useFloatMenu';
import { isStrictlyNumeric } from '../utils/isStrictlyNumeric';
import { compareStringsAlphabetically } from '../utils/orderStringAlphabetically';
import {
    ColumnFilter,
    resetFilterColumn,
    onApplyFilter,
    SortingParameters,
    ColumnValueOption,
} from '../interfaces/columnValues/ColumnFilterContext.interface';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';

interface ColumnFilterHookParams {
    columnName: string;
    resetFilterColumn: resetFilterColumn;
    columnFilters: ColumnFilter[];
    onApplyFilter: onApplyFilter;
    columnValues: ColumnValueOption[];
    sortParams: SortingParameters;
    sortFieldName?: string;
    isDynamicColumnFilter?: boolean;
}

const useColumnsFilter = ({
    columnName,
    resetFilterColumn,
    columnFilters,
    onApplyFilter,
    columnValues = [],
    sortParams,
    sortFieldName = '',
    isDynamicColumnFilter = false,
}: ColumnFilterHookParams) => {
    const [search, setSearch] = useState('');
    // checked checkboxes
    const [selectedValues, setSelectedValues] = useState<ColumnValueOption[]>(
        []
    );

    // sort filter only manages sorting by the length of the column
    const [sortFilter, setSortFilter] = useState<
        SORT_TYPE_OPTIONS | null | undefined
    >(null);

    const filterMenu = useFloatMenu();

    const orderedColumnValues = useMemo(() => {
        const isNumericArray = columnValues.every((value) =>
            isStrictlyNumeric(value?.label)
        );

        const ordered = isNumericArray
            ? columnValues.sort((a, b) => Number(a?.label) - Number(b?.label))
            : columnValues.sort((a, b) =>
                  compareStringsAlphabetically(a?.label, b?.label)
              );

        return ordered;
    }, [columnValues]);

    const filteredValues = useMemo(
        () =>
            orderedColumnValues.filter((val) =>
                val.label.toLowerCase().includes(search)
            ),
        [search, orderedColumnValues]
    );

    // check if this column is filtered or not.
    const currentFilteredColumn = useMemo(
        () => columnFilters.find((col) => col.column === columnName),
        [columnFilters, columnName]
    );

    useEffect(() => {
        if (filterMenu.showMenu) {
            // when the menu is open, we need to reset the search input (and by consequence the filtered values)
            onChangeSearch('');

            const selectedItems = currentFilteredColumn
                ? currentFilteredColumn.value
                : orderedColumnValues;

            setSelectedValues(selectedItems);

            const sortType =
                sortParams.sortBy === columnName ? sortParams.sortType : null;

            setSortFilter(sortType);
        }
    }, [filterMenu.showMenu]);

    const onChangeSearch = (val: string) => {
        const value = String(val).toLowerCase();
        setSearch(value);
    };

    const onChangeCheckbox = (option: ColumnValueOption, checked: boolean) => {
        if (checked) {
            setSelectedValues([...selectedValues, option]);
        } else {
            const filtered = selectedValues.filter(
                (val) => val.__id !== option.__id
            );
            setSelectedValues(filtered);
        }
    };

    const applyFilters = () => {
        const getAll = selectedValues.length === orderedColumnValues.length;

        onApplyFilter(
            {
                sortType: sortFilter,
                column: columnName,
                values: selectedValues,
                sortBy: sortFieldName,
                isDynamicColumnFilter,
            },
            getAll
        );
        filterMenu.handleMenu();
    };

    const onResetFilter = () => {
        resetFilterColumn(columnName);
        filterMenu.handleMenu();
    };

    // check if the botton APPLY FILTERS should be shown
    const showApplyButton = useMemo(() => {
        if (!filteredValues.length || !selectedValues.length) return false;

        // check if the column has a filter applied
        const filteredColumn = columnFilters.find(
            (f) => f.column === columnName
        );

        const toCompare = filteredColumn
            ? filteredColumn.value
            : orderedColumnValues;

        const checkboxChanged = !toCompare.every((opt) =>
            selectedValues.some((val) => val.__id === opt.__id)
        );

        // // this only works for the first time
        // if (filteredColumn) {
        //     checkboxChanged = !filteredColumn.value.every((opt) =>
        //         selectedValues.some((val) => val.__id === opt.__id)
        //     );
        // } else {
        //     checkboxChanged = !orderedColumnValues.every((opt) =>
        //         selectedValues.some((val) => val.__id === opt.__id)
        //     );
        // }

        const lengthDif = toCompare.length !== selectedValues.length;

        const columnIsSorted = sortParams.sortBy === columnName;

        //check if sort filter has changed
        const sortChanged = columnIsSorted
            ? sortParams.sortType !== sortFilter
            : sortFilter !== null;

        return checkboxChanged || lengthDif || sortChanged;
    }, [columnFilters, selectedValues, sortFilter, sortParams]);

    const onSelectAll = (selectOperation: boolean) => {
        if (selectOperation) {
            setSelectedValues([
                ...selectedValues,
                ...filteredValues.filter(
                    (value) =>
                        !selectedValues.find(
                            (selected) => selected.__id === value.__id
                        )
                ),
            ]);
        } else {
            setSelectedValues(
                selectedValues.filter(
                    (value) =>
                        !filteredValues.find(
                            (filtered) => value.__id === filtered.__id
                        )
                )
            );
        }
    };
    return {
        isFiltered: Boolean(currentFilteredColumn),
        sortFilter,
        search,
        selectedValues,
        filteredValues,
        filterMenu,
        onSelectAll,
        onChangeSearch,
        onChangeCheckbox,
        showApplyButton,
        applyFilters,
        onResetFilter,
        onSort: setSortFilter,
        setSelectedValues,
    };
};

export default useColumnsFilter;
