import { CognyErrors } from '~/languages/errors/errors.interface';

const enErrorCodes: CognyErrors = {
    an_error_occurred: 'Something went wrong. Please, try again later',
    invalid_payload: 'Something went wrong. Please, try again later',
    at_least_one_property_attribute_must_be_provided:
        'One property must be provided at minimum',
    missing_permissions: 'Missing permissions',
    comment_has_no_screenshot: 'The comment has no screenshot',
    comment_already_has_screenshot:
        'The comment has already a screenshot associated',
    comment_screenshot_invalid_extension:
        'The comment screenshot extension is invalid',
    comment_screenshot_invalid_mime_type:
        'The comment screenshot mime is invalid',
    comment_screenshot_invalid_size: 'The comment screenshot size is invalid',
    comment_not_found: 'The comment was not found',
    comment_create_not_allowed: 'Comment creation is not allowed',
    comment_read_not_allowed: 'Comment read is not allowed',
    comment_update_not_allowed: 'Comment update is not allowed',
    comment_delete_not_allowed: 'Comment deletion is not allowed',
    comment_dynamic_property_deletion_impossible_has_comment_dynamic_values:
        'Comment dynamic property cannot be deleted as it contains dynamic values',
    comment_dynamic_property_deletion_impossible_has_comment_select_options:
        'Comment dynamic property cannot be deleted as it contains select options',
    comment_dynamic_property_update_impossible_at_invalid_position:
        'Comment dynamic property cannot be updated as the position is invalid',
    comment_dynamic_property_not_found:
        'The comment dynamic property was not found',
    comment_dynamic_property_create_not_allowed:
        'Comment dynamic property creation is not allowed',
    comment_dynamic_property_read_not_allowed:
        'Comment dynamic property read is not allowed',
    comment_dynamic_property_update_not_allowed:
        'Comment dynamic property update is not allowed',
    comment_dynamic_property_delete_not_allowed:
        'Comment dynamic property deletion is not allowed',
    comment_dynamic_value_not_found: 'The comment dynamic value was not found',
    comment_dynamic_value_create_not_allowed:
        'Comment dynamic value creation is not allowed',
    comment_dynamic_value_read_not_allowed:
        'Comment dynamic value read is not allowed',
    comment_dynamic_value_update_not_allowed:
        'Comment dynamic value update is not allowed',
    comment_dynamic_value_delete_not_allowed:
        'Comment dynamic value deletion is not allowed',
    comment_dynamic_value_comment_dynamic_property_comment_not_same_project:
        'Comment dynamic value & comment are not in same project',
    comment_dynamic_value_create_impossible_because_default_type:
        'Comment dynamic value cannot be created because is default type',
    comment_dynamic_value_create_impossible_because_already_exists:
        'Comment dynamic value cannot be created as it already exists',
    comment_dynamic_value_create_impossible_because_invalid_value_for_dynamic_property_type:
        'Comment dynamic value cannot be created as value is invalid for the type',
    comment_dynamic_value_deletion_impossible_has_comment_dynamic_value_select_options:
        'Comment dynamic value cannot be deleted because has select options associated',
    select_option_value_not_found: 'The select option was not found',
    select_option_value_create_not_allowed:
        'Select option creation is not allowed',
    select_option_value_read_not_allowed: 'Select option read is not allowed',
    select_option_value_update_not_allowed:
        'Select option update is not allowed',
    select_option_value_delete_not_allowed:
        'Select option deletion is not allowed',
    comment_select_option_create_impossible_property_is_not_selectable:
        'Select option creation is impossible as property is not selectable',
    comment_select_option_deletion_impossible_has_comment_dynamic_value_select_options:
        'Select option deletion is impossible as it contains associated dynamic values',
    document_deletion_impossible_because_has_comments:
        'The document has associated comment, so it cannot be deleted',
    document_not_found: 'The document was not found',
    document_create_not_allowed: 'Document creation is not allowed',
    document_read_not_allowed: 'Document read is not allowed',
    document_update_not_allowed: 'Document update is not allowed',
    document_delete_not_allowed: 'Document deletion is not allowed',
    document_dynamic_property_deletion_impossible_has_document_dynamic_values:
        'Document dynamic property cannot be deleted as it contains dynamic values',
    document_dynamic_property_deletion_impossible_has_document_select_options:
        'Document dynamic property cannot be deleted as it contains select options',
    document_dynamic_property_not_found:
        'The document dynamic property was not found',
    document_dynamic_property_create_not_allowed:
        'Document dynamic property creation is not allowed',
    document_dynamic_property_read_not_allowed:
        'Document dynamic property read is not allowed',
    document_dynamic_property_update_not_allowed:
        'Document dynamic property update is not allowed',
    document_dynamic_property_delete_not_allowed:
        'Document dynamic property deletion is not allowed',
    document_dynamic_value_not_found:
        'The document dynamic value was not found',
    document_dynamic_value_create_not_allowed:
        'Document dynamic value creation is not allowed',
    document_dynamic_value_read_not_allowed:
        'Document dynamic value read is not allowed',
    document_dynamic_value_update_not_allowed:
        'Document dynamic value update is not allowed',
    document_dynamic_value_delete_not_allowed:
        'Document dynamic value deletion is not allowed',
    document_dynamic_value_document_dynamic_property_document_not_same_project:
        'Document dynamic value & document are not in same project',
    document_dynamic_value_create_impossible_because_already_exists:
        'Document dynamic value cannot be created as it already exists',
    document_dynamic_value_create_impossible_because_invalid_value_for_dynamic_property_type:
        'Document dynamic value cannot be created as value is invalid for the type',
    document_dynamic_value_deletion_impossible_has_document_dynamic_value_select_options:
        'Document dynamic value cannot be deleted because has select options associated',
    document_select_option_value_not_found: 'The select option was not found',
    document_select_option_value_create_not_allowed:
        'Select option creation is not allowed',
    document_select_option_value_read_not_allowed:
        'Select option read is not allowed',
    document_select_option_value_update_not_allowed:
        'Select option update is not allowed',
    document_select_option_value_delete_not_allowed:
        'Select option deletion is not allowed',
    document_select_option_create_impossible_property_is_not_selectable:
        'Select option creation is impossible as property is not selectable',
    document_select_option_deletion_impossible_has_document_dynamic_value_select_options:
        'Select option deletion is impossible as it contains associated dynamic values',
    document_table_position_not_found:
        'The document table position was not found',
    document_table_position_create_not_allowed:
        'Document table position creation is not allowed',
    document_table_position_read_not_allowed:
        'Document table position read is not allowed',
    document_table_position_update_not_allowed:
        'Document table position update is not allowed',
    document_table_position_delete_not_allowed:
        'Document table position deletion is not allowed',
    document_table_position_update_impossible_at_invalid_position:
        'Document table position cannot be updated as the position is invalid',
    file_invalid_extension: 'File type not supported',
    file_invalid_mime_type: 'File type not supported',
    file_invalid_url: 'Url format is invalid',
    file_invalid_size: 'File is too large',
    file_not_ready_to_be_fetched: 'The file is not ready yet',
    file_not_found: 'The file was not found',
    file_create_not_allowed: 'File creation is not allowed',
    file_read_not_allowed: 'File read is not allowed',
    folder_deletion_impossible_because_children_has_topics:
        "Can't delete folder topics in sub folders",
    folder_deletion_impossible_because_has_topics:
        "Can't delete folder with topics",
    folder_not_found: 'Folder was not found',
    folder_create_not_allowed: 'Folder creation is not allowed',
    folder_read_not_allowed: 'Folder read is not allowed',
    folder_update_not_allowed: 'Folder update is not allowed',
    folder_delete_not_allowed: 'Folder deletion is not allowed',
    gpt_cant_process: "AI can't process content",
    gpt_create_now_allowed: "AI can't process content",
    gpt_busy_server: 'AI servers under heavily loads',
    gpt_too_many_tokens: 'AI content is too long',
    gpt_truncated_response: 'AI response is truncated',
    gpt_report_conclusion_generation_impossible_no_report_sections:
        "AI can't process report with no conclusion",
    import_invalid_status: 'Import state does not allow this operation',
    import_missing_files: 'Import has no file',
    import_not_found: 'The import was not found',
    import_create_not_allowed: 'Import creation is not allowed',
    import_read_not_allowed: 'Import read is not allowed',
    import_update_not_allowed: 'Import update is not allowed',
    import_delete_not_allowed: 'Import deletion is not allowed',
    error_reporting_not_allowed: 'Import stage error reporting is not allowed',
    import_stage_invalid_status: 'Import stage does not allow this operation',
    import_stage_not_found: 'The import stage was not found',
    import_stage_read_not_allowed: 'Import stage read is not allowed',
    import_stage_max_processing_attempts_reached:
        'Import stage max processing attempts reached',
    project_must_have_at_least_one_admin:
        'Project must have at least one administrator',
    project_deletion_impossible_because_has_documents:
        'Project cannot be deleted as it contains documents',
    project_deletion_impossible_because_has_folders:
        'Project cannot be deleted as it contains folders',
    project_deletion_impossible_because_has_imports:
        'Project cannot be deleted as it contains imports',
    project_deletion_impossible_because_has_reports:
        'Project cannot be deleted as it contains reports',
    project_deletion_impossible_because_has_tags:
        'Project cannot be deleted as it contains tags',
    project_deletion_impossible_because_has_tag_groups:
        'Project cannot be deleted as it contains tag groups',
    project_deletion_impossible_because_has_topics:
        'Project cannot be deleted as it contains topics',
    project_deletion_impossible_because_has_users:
        'Project cannot be deleted as it contains users',
    project_deletion_impossible_because_has_saved_searches:
        'Project cannot be deleted as it contains favorite queries',
    project_not_found: 'Project was not found',
    project_create_not_allowed: 'Project creation is not allowed',
    project_read_not_allowed: 'Project read is not allowed',
    project_update_not_allowed: 'Project update is not allowed',
    project_delete_not_allowed: 'Project deletion is not allowed',
    project_cannot_copy_saved_searched_without_tags:
        "Can't duplicate project with favorite queries without copying tags",
    project_member_already_exists: 'Project member already exists',
    project_member_not_found: 'Project member was not found',
    project_member_create_not_allowed: 'Project member creation is not allowed',
    project_member_read_not_allowed: 'Project member read is not allowed',
    project_member_update_not_allowed: 'Project member update is not allowed',
    project_member_delete_not_allowed: 'Project member deletion is not allowed',
    project_member_invitation_already_exists:
        'Project member invitation already exists',
    project_member_invitation_not_found:
        'Project member invitation was not found',
    project_member_invitation_create_not_allowed:
        'Project member invitation creation is not allowed',
    project_member_invitation_read_not_allowed:
        'Project member invitation read is not allowed',
    project_member_invitation_update_not_allowed:
        'Project member invitation update is not allowed',
    project_member_invitation_delete_not_allowed:
        'Project member invitation deletion is not allowed',
    report_deletion_impossible_because_has_sections:
        "Can't delete report with sections",
    report_not_found: 'Report was not found',
    report_create_not_allowed: 'Report creation is not allowed',
    report_read_not_allowed: 'Report read is not allowed',
    report_update_not_allowed: 'Report update is not allowed',
    report_delete_not_allowed: 'Report deletion is not allowed',
    report_section_not_found: 'Report section was not found',
    report_section_create_not_allowed: 'Report section creation is not allowed',
    report_section_read_not_allowed: 'Report section read is not allowed',
    report_section_update_not_allowed: 'Report section update is not allowed',
    report_section_delete_not_allowed: 'Report section deletion is not allowed',
    saved_search_project_topic_exclusive:
        'A favorite query must have a parent topic or project, not both',
    saved_search_cant_change_project: "Can't change favorite query project",
    saved_search_not_found: 'Favorite query was not found',
    saved_search_create_not_allowed: 'Favorite query creation is not allowed',
    saved_search_read_not_allowed: 'Favorite query read is not allowed',
    saved_search_update_not_allowed: 'Favorite query update is not allowed',
    saved_search_delete_not_allowed: 'Favorite query deletion is not allowed',
    search_paragraph_search_must_include_micro_conditions:
        'Micro search must include micro conditions',
    search_tag_term_not_allowed_in_micro_conditions:
        'Tag term is not allowed in micro conditions',
    tag_deletion_impossible_because_has_documents:
        "Can't delete tag with already associated documents",
    tag_not_found: 'Tag was not found',
    tag_create_not_allowed: 'Tag creation is not allowed',
    tag_read_not_allowed: 'Tag read is not allowed',
    tag_update_not_allowed: 'Tag update is not allowed',
    tag_delete_not_allowed: 'Tag deletion is not allowed',
    tag_tag_group_not_same_project: 'Tag group is not from same project',
    tag_group_deletion_impossible_because_has_tags:
        "Can't delete tag group with associated tags",
    tag_group_not_found: 'Tag group was not found',
    tag_group_create_not_allowed: 'Tag group creation is not allowed',
    tag_group_read_not_allowed: 'Tag group read is not allowed',
    tag_group_update_not_allowed: 'Tag group update is not allowed',
    tag_group_delete_not_allowed: 'Tag group deletion is not allowed',
    topic_invalid: 'Topic is invalid',
    topic_deletion_impossible_because_has_comments:
        "Can't delete topic with already existing comments",
    topic_deletion_impossible_because_has_saved_searches:
        "Can't delete topic with already existing favorite queries",
    topic_not_found: 'Topic was not found',
    topic_create_not_allowed: 'Topic creation is not allowed',
    topic_read_not_allowed: 'Topic read is not allowed',
    topic_update_not_allowed: 'Topic update is not allowed',
    topic_delete_not_allowed: 'Topic deletion is not allowed',
    user_already_active: 'User is already active',
    user_already_exists: 'User already exists',
    user_does_not_exist_or_invalid_credentials:
        'User does not exist or credentials are invalid',
    user_account_not_confirmed: 'User account not confirmed',
    invalid_mail_format: 'Invalid mail format',
    invalid_password_format: 'Invalid password format',
    user_not_found: 'User was not found',
    user_create_not_allowed: 'User creation is not allowed',
    user_read_not_allowed: 'User read is not allowed',
    user_update_not_allowed: 'User update is not allowed',
    user_delete_not_allowed: 'User deletion is not allowed',
    workspace_configuration_not_found: 'Workspace configuration was not found',
    workspace_configuration_create_not_allowed:
        'Workspace configuration creation is not allowed',
    workspace_configuration_read_not_allowed:
        'Workspace configuration read is not allowed',
    workspace_configuration_update_not_allowed:
        'Workspace configuration update is not allowed',
    workspace_configuration_delete_not_allowed:
        'Workspace configuration deletion is not allowed',
    workspace_configuration_url_import_not_allowed:
        'Workspace configuration url import not allowed',
    workspace_member_already_exists: 'Workspace member already exists',
    workspace_member_not_found: 'Workspace member was not found',
    workspace_member_create_not_allowed:
        'Workspace member creation is not allowed',
    workspace_member_read_not_allowed: 'Workspace member read is not allowed',
    workspace_member_update_not_allowed:
        'Workspace member update is not allowed',
    workspace_member_delete_not_allowed:
        'Workspace member deletion is not allowed',
    workspace_member_invalid_role: 'Workspace member role is invalid',
    workspace_member_invitation_already_exists:
        'Workspace member invitation already exists',
    workspace_member_invitation_not_found:
        'Workspace member invitation was not found',
    workspace_member_invitation_create_not_allowed:
        'Workspace member invitation creation is not allowed',
    workspace_member_invitation_read_not_allowed:
        'Workspace member invitation read is not allowed',
    workspace_member_invitation_update_not_allowed:
        'Workspace member invitation update is not allowed',
    workspace_member_invitation_delete_not_allowed:
        'Workspace member invitation deletion is not allowed',
    workspace_not_found: 'Workspace was not found',
    workspace_create_not_allowed: 'Workspace creation is not allowed',
    workspace_read_not_allowed: 'Workspace read is not allowed',
    workspace_update_not_allowed: 'Workspace update is not allowed',
    workspace_delete_not_allowed: 'Workspace deletion is not allowed',
    workspace_must_have_owner: 'Workspace must have owner',
    import_stage_an_error_occurred: 'An error occurred',
    import_stage_document_is_broken: 'Document is badly formatted',
    import_stage_an_error_occurred_during_file_transfer:
        'A network error occurred',
    import_stage_an_error_occurred_during_file_processing:
        'An error occurred during file processing',
    import_stage_an_error_occurred_during_ocr_processing:
        'An error occurred during OCR processing',
};

// errors managed frontend-side
const defaultValues = {
    general_error: 'Something went wrong. Please, try again later',
    canceled: 'Canceled',
    ERR_NETWORK:
        "It seems like there's a problem with your internet connection. Please check your connection and try again later",
};

export default {
    ...enErrorCodes,
    ...defaultValues,
};
