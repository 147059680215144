import React, { useEffect, useRef } from 'react';
import './index.scss';
import Pill from '../Pill';
import { useIncrementalInputWidth } from '../../../hooks';

interface PillInputTextInterface {
    value: string;
    name: string;
    variant?: 'default' | 'pill' | 'secondary';
    error?: string;
    prefix?: string;
    onPressEscape?: () => void;
    onPressEnter?: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
}

const PillInputText: React.FC<PillInputTextInterface> = ({
    value,
    variant,
    name,
    prefix,
    error,
    onPressEnter,
    onPressEscape,
    onBlur,
    onChange,
}) => {
    const inputRef = useRef();

    const { setInputWidth, width } = useIncrementalInputWidth();

    useEffect(() => {
        if (inputRef.current) setInputWidth(inputRef.current);
    }, [inputRef]);

    return (
        <Pill
            variant={variant}
            className={`dg-pill-input-text ${
                error ? 'dg-pill-input-text__error' : ''
            }`}
        >
            {prefix && (
                <span className="dg-pill-input-text__prefix">{prefix}</span>
            )}
            <input
                ref={inputRef}
                autoFocus={true}
                onChange={(e) => {
                    onChange(e);
                    setInputWidth(e.target);
                }}
                value={value}
                name={name}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        onPressEnter && onPressEnter();
                    }
                    if (e.key === 'Escape') {
                        onPressEscape && onPressEscape();
                    }
                }}
                onBlur={() => onBlur && onBlur()}
                style={{
                    width,
                }}
            />
        </Pill>
    );
};

export default PillInputText;
