function DottedCircleIcon({
    onClick,
}: {
    onClick?: (e: React.MouseEvent) => void;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={(e) => onClick && onClick(e)}
        >
            <path
                fill="#000"
                fillRule="evenodd"
                d="M6.537.643a7.534 7.534 0 012.926 0 .5.5 0 01-.194.98 6.534 6.534 0 00-2.538 0 .5.5 0 11-.194-.98zM11.474 1.9a.5.5 0 01.694-.137 7.539 7.539 0 012.068 2.069.5.5 0 11-.83.556 6.538 6.538 0 00-1.795-1.794.5.5 0 01-.137-.694zm-6.948 0a.5.5 0 01-.137.694 6.538 6.538 0 00-1.794 1.794.5.5 0 11-.831-.556 7.538 7.538 0 012.069-2.07.5.5 0 01.693.138zM14.77 6.143a.5.5 0 01.587.394 7.534 7.534 0 010 2.926.5.5 0 01-.98-.194 6.536 6.536 0 000-2.538.5.5 0 01.393-.588zm-13.54 0a.5.5 0 01.394.588 6.534 6.534 0 000 2.538.5.5 0 11-.981.194 7.534 7.534 0 010-2.926.5.5 0 01.587-.394zm.67 5.33a.5.5 0 01.695.138 6.538 6.538 0 001.794 1.794.5.5 0 11-.556.831 7.539 7.539 0 01-2.07-2.069.5.5 0 01.138-.693zm12.2 0a.5.5 0 01.136.694 7.54 7.54 0 01-2.069 2.07.5.5 0 11-.556-.832 6.538 6.538 0 001.794-1.794.5.5 0 01.694-.137zM9.856 14.77a.5.5 0 01-.394.587 7.534 7.534 0 01-2.926 0 .5.5 0 01.194-.98 6.536 6.536 0 002.538 0 .5.5 0 01.588.393z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default DottedCircleIcon;
