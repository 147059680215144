const CognyIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="20"
            fill="none"
            viewBox="0 0 51 20"
        >
            <g fill="#000" clipPath="url(#clip0_900_7563)">
                <path d="M0 9.655C0 6.11 2.279 3.5 5.547 3.5c1.906 0 3.353.865 3.895 2.543l.1-2.339h1.5V8.89H9.374c-.206-2.102-1.21-3.848-3.163-3.848-1.722 0-3.369 1.358-3.369 4.425 0 3.067 1.684 4.608 3.879 4.608 1.242 0 2.621-.425 3.932-1.321v1.83c-1.09.744-2.606 1.184-4.253 1.184C2.537 15.773 0 13.45 0 9.655zM11.91 11.296c0-2.422 1.432-4.478 4.474-4.478 3.042 0 4.458 2.05 4.458 4.478 0 2.427-1.41 4.477-4.458 4.477-3.047 0-4.473-2.05-4.473-4.477zm4.458 3.24c1.242 0 1.822-1.101 1.822-3.24 0-2.14-.58-3.235-1.79-3.235s-1.821 1.1-1.821 3.235c0 2.133.563 3.24 1.79 3.24zM21.421 17.534c0-.713.41-1.305 1.853-1.442v-.083c-1.037-.19-1.532-.897-1.532-1.647 0-.828.679-1.51 1.837-1.73-1.142-.477-1.821-1.389-1.821-2.626 0-1.898 1.6-3.172 3.947-3.172.595 0 1.174.084 1.7.252l.032.016c.031 0 .068.015.084.036 1.295.425 2.058.1 1.853-.765-1.342.477-2.11-.493-1.874-1.321h2.521c.479 1.51.069 2.694-1.105 2.93.494.493.8 1.17.8 2.003 0 2.034-1.79 3.014-4.053 3.014-.41 0-.8-.031-1.158-.1-.479.137-.816.425-.816.766 0 .34.322.593 1.006.593h2.5c2.095 0 3.131.917 3.131 2.338 0 1.897-1.89 2.899-4.984 2.899-2.858 0-3.931-.829-3.931-1.966l.01.005zm4.169.865c1.326 0 2.11-.44 2.11-1.221 0-.593-.41-.881-1.174-.881h-1.342c-1.005 0-1.41.44-1.41 1.017 0 .729.563 1.085 1.82 1.085h-.005zm.136-6.354c.832 0 1.364-.645 1.364-2.133 0-1.27-.458-2.103-1.311-2.103s-1.363.766-1.363 2.17c0 1.406.479 2.066 1.31 2.066zM31.153 14.278l.92-.136.17-.34V8.783l-.17-.34-.884-.137v-1.29h3.437v2.187h.053c.479-1.625 1.6-2.39 2.942-2.39 1.768 0 2.621 1.2 2.621 2.862v4.12l.169.341.92.136v1.29h-4.578v-1.29l.768-.136.153-.34V10.22c0-1.117-.321-1.693-1.19-1.693-1.02 0-1.668.812-1.668 2.306v2.967l.137.341.784.136v1.29h-4.58v-1.29h-.004zM43.337 17.041c.542 0 1.142.236 1.5.713l.747-1.73-3.252-7.24-.19-.356-.768-.12v-1.29h4.679v1.29l-.8.12-.153.273 1.874 4.477 1.72-4.477-.136-.273-.816-.12v-1.29h3.453v1.29l-.953.12-.153.356-4.273 10.511H42.12c-.579-1-.016-2.254 1.21-2.254h.006z"></path>
            </g>
            <defs>
                <clipPath id="clip0_900_7563">
                    <path fill="#fff" d="M0 0H51V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
};

export default CognyIcon;
