import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateDynamicPropertyDTO,
    CreateDynamicInsightValueDTO,
    CreateInsightDTO,
    CreateInsightSelectOptionDTO,
    DynamicPropertyInterface,
    DynamicPropertyInterfaceDetailed,
    DynamicInsightValueInterface,
    DynamicInsightValueDetailedInterface,
    InsightInterface,
    InsightInterfaceDetailed,
    InsightSelectOptionInterface,
    UpdateDynamicPropertyDTO,
    UpdateDynamicInsightValueDTO,
    UpdateDynamicValueSelectOptionsDTO,
    UpdateInsightDTO,
    UpdateInsightSelectOptionDTO,
} from '~/interfaces/entities';

import dataURItoBlob from '../utils/dataURItoBlob';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';

const API_URL = environment.apiUrl + '/comments';

const commentsServices = {
    getComments,
    createComment,
    deleteComment,
    assignTopicToComments,
    getCommentById,
    setCommentImage,
    getCommentImage,
    getColumnValues,
    appendImagesToInsights,
    getCommentColumns,
    addColumn,
    deleteColumn,
    addDynamicValue,
    deleteAdditionalProperty,
    updateColumn,
    updateDynamicValue,
    updateCellItems,
    createSelectOption,
    updateSelectOption,
    deleteSelectOption,
    upsertCommentAndCreateDeps,
};
export default commentsServices;

async function getComments(options: Record<string, unknown>) {
    return requestAdapterInstance.get<PagedResponse<InsightInterfaceDetailed>>(
        environment.apiUrl +
            '/comments/detailed?' +
            queryString.stringify(options)
    );
}

// pending to type response
async function getColumnValues(options: Record<string, unknown>) {
    return requestAdapterInstance.get(
        `${API_URL}/detailed/metadata?${queryString.stringify(options)}`
    );
}

async function getCommentById(commentId: string) {
    return requestAdapterInstance.get<UnpagedResponse<InsightInterface>>(
        `${environment.apiUrl}/comments/${commentId}`
    );
}

async function createComment(comment: CreateInsightDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<InsightInterface>,
        CreateInsightDTO
    >(API_URL, comment);
}

async function deleteComment(commentId: string) {
    return requestAdapterInstance.delete<null, null>(API_URL + `/${commentId}`);
}

async function assignTopicToComments(payload: UpdateInsightDTO) {
    return requestAdapterInstance.put<
        UnpagedResponse<InsightInterface>,
        UpdateInsightDTO
    >(API_URL, payload);
}

async function setCommentImage(commentId: string, imageURI: string) {
    const imageBlob = dataURItoBlob(imageURI);
    const img = new File([imageBlob], 'screenshot.jpeg', {
        type: 'image/jpeg',
    });
    const form = new FormData();
    form.set('file', img);

    const url = `${API_URL}/${commentId}/screenshot`;
    return requestAdapterInstance.putFiles(url, form);
}

async function getCommentImage(commentId: string) {
    return requestAdapterInstance.getFiles(
        `${environment.apiUrl}/comments/${commentId}/screenshot`
    );
}

/**
 * Retrieves insight screenshots linearly when applicable
 */
async function appendImagesToInsights(
    insights: InsightInterfaceDetailed[]
): Promise<InsightInterfaceDetailed[]>;
async function appendImagesToInsights(
    insights: InsightInterface[] = []
): Promise<InsightInterface[]> {
    const modifiedInsights = [...insights];
    const imagePromises: Promise<void>[] = [];

    insights.forEach((insight, idx) => {
        if (insight.screenshot) {
            imagePromises.push(
                getCommentImage(insight.id).then(([error, response]) => {
                    if (!error) {
                        modifiedInsights[idx].image = response;
                    }
                })
            );
        }
    });

    await Promise.all(imagePromises);

    return modifiedInsights;
}

async function getCommentColumns(projectId: string) {
    return requestAdapterInstance.get<
        PagedResponse<DynamicPropertyInterfaceDetailed>
    >(
        `${environment.apiUrl}/comment_dynamic_properties/detailed?project_ids=${projectId}`
    );
}

async function updateColumn(
    columnId: string,
    payload: UpdateDynamicPropertyDTO
) {
    return requestAdapterInstance.put<
        UnpagedResponse<DynamicPropertyInterface>,
        UpdateDynamicPropertyDTO
    >(`${environment.apiUrl}/comment_dynamic_properties/${columnId}`, payload);
}

async function deleteColumn(columnId: string) {
    return requestAdapterInstance.delete<null, null>(
        `${environment.apiUrl}/comment_dynamic_properties/${columnId}?force=true`
    );
}

async function addColumn(column: CreateDynamicPropertyDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<DynamicPropertyInterface>,
        CreateDynamicPropertyDTO
    >(`${environment.apiUrl}/comment_dynamic_properties`, column);
}

async function updateDynamicValue(
    cellId: string,
    payload: UpdateDynamicInsightValueDTO
) {
    return requestAdapterInstance.put<
        UnpagedResponse<DynamicInsightValueInterface>,
        UpdateDynamicInsightValueDTO
    >(`${environment.apiUrl}/comment_dynamic_values/${cellId}`, payload);
}

async function addDynamicValue(payload: CreateDynamicInsightValueDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<DynamicInsightValueInterface>,
        CreateDynamicInsightValueDTO
    >(`${environment.apiUrl}/comment_dynamic_values`, payload);
}

async function deleteAdditionalProperty(cellId: string) {
    return requestAdapterInstance.delete<null, null>(
        `${environment.apiUrl}/comment_dynamic_values/${cellId}`
    );
}

async function updateCellItems(
    cellId: string,
    payload: UpdateDynamicValueSelectOptionsDTO
) {
    return requestAdapterInstance.put<
        UnpagedResponse<DynamicInsightValueDetailedInterface>,
        UpdateDynamicValueSelectOptionsDTO
    >(
        `${environment.apiUrl}/comment_dynamic_values/${cellId}/select_options`,
        payload
    );
}

async function createSelectOption(options: CreateInsightSelectOptionDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<InsightSelectOptionInterface>,
        CreateInsightSelectOptionDTO
    >(`${environment.apiUrl}/comment_select_options`, options);
}

async function updateSelectOption(
    optionId: string,
    payload: UpdateInsightSelectOptionDTO
) {
    return requestAdapterInstance.put<
        UnpagedResponse<InsightSelectOptionInterface>,
        UpdateInsightSelectOptionDTO
    >(`${environment.apiUrl}/comment_select_options/${optionId}`, payload);
}

async function deleteSelectOption(optionId: string) {
    return requestAdapterInstance.delete<null, null>(
        `${environment.apiUrl}/comment_select_options/${optionId}?force=true`
    );
}

type SelectOptionData = Pick<
    InsightSelectOptionInterface,
    'id' | 'name' | 'comment_dynamic_property_id'
>;

export type UpsertInsightSelectOption =
    | (SelectOptionData & { isNew: false })
    | (CreateInsightSelectOptionDTO & { isNew: true });

export type UpsertDynamicValueData = Omit<
    CreateDynamicInsightValueDTO,
    'comment_id'
> &
    (
        | {
              dynamic_property_type:
                  | DYNAMIC_PROPERTY_TYPES.SELECT
                  | DYNAMIC_PROPERTY_TYPES.MULTI_SELECT;
              // we are always appending OR creating & appending insight select options when upserting an insight
              selectOptions: UpsertInsightSelectOption[];
          }
        | {
              dynamic_property_type:
                  | DYNAMIC_PROPERTY_TYPES.BOOLEAN
                  | DYNAMIC_PROPERTY_TYPES.NUMBER
                  | DYNAMIC_PROPERTY_TYPES.TEXT;
          }
    );

type UpsertCommentAndCreateDeps = {
    commentDTO: CreateInsightDTO;
} & {
    image?: string;
    dynamicValueData?: UpsertDynamicValueData[];
};

async function upsertCommentAndCreateDeps({
    commentDTO,
    image,
    dynamicValueData,
}: UpsertCommentAndCreateDeps): Promise<boolean | string> {
    /* 1st step */
    const [insightError, insight] =
        await commentsServices.createComment(commentDTO);

    if (insightError) {
        return false;
    }

    /* 2nd step */
    if (image) {
        const [error] = await commentsServices.setCommentImage(
            insight.data.id,
            image
        );

        if (error) {
            await commentsServices.deleteComment(insight.data.id);
            // deleting the insight delets all associated dynamic values. including multi select option values. but not multi select options per se.
            return false;
        }
    }

    if (dynamicValueData) {
        /* 3rd step */
        const dynamicValueRequests = dynamicValueData.map((dto) => {
            const payload: CreateDynamicInsightValueDTO = {
                comment_id: insight.data.id,
                comment_dynamic_property_id: dto.comment_dynamic_property_id,
                value_boolean: dto.value_boolean,
                value_number: dto.value_number,
                value_text: dto.value_text,
            };

            return commentsServices.addDynamicValue(payload);
        });

        const responses = await Promise.all(dynamicValueRequests);

        const createdDynamicValues = (
            responses.filter(([error]) => error === false) as [
                false,
                UnpagedResponse<DynamicInsightValueInterface>,
            ][]
        ).map(([, response]) => response.data);
        // need to type cast since Array.prototype.filter does not alter its type in spite of filtering

        if (createdDynamicValues.length !== responses.length) {
            await commentsServices.deleteComment(insight.data.id);
            return false;
        }

        /* 4th step */
        // building context to (add) OR (create & add) select options to an insight dynamic value
        const dynamicPropertyToSelectOptionsMap: Record<
            string,
            UpsertInsightSelectOption[]
        > = {};

        const selectOptions = dynamicValueData.map((element) => {
            if (
                element.dynamic_property_type ===
                    DYNAMIC_PROPERTY_TYPES.SELECT ||
                element.dynamic_property_type ===
                    DYNAMIC_PROPERTY_TYPES.MULTI_SELECT
            ) {
                return {
                    propertyId: element.dynamic_property_type,
                    selectOptions: element.selectOptions,
                };
            }

            return null;
        });

        for (const aggregation of selectOptions) {
            if (aggregation === null) {
                continue;
            }

            dynamicPropertyToSelectOptionsMap[aggregation.propertyId] =
                aggregation.selectOptions;
        }

        // filtering select options to be created, and select options that exist
        const newSelectOptionPayloads: CreateInsightSelectOptionDTO[] = [];
        const existingSelectOptionsPayloads: SelectOptionData[] = [];
        for (const propertyId in dynamicPropertyToSelectOptionsMap) {
            const payloads: CreateInsightSelectOptionDTO[] = [];
            const existingSelectOptions: SelectOptionData[] = [];

            for (const selectOption of dynamicPropertyToSelectOptionsMap[
                propertyId
            ]) {
                if (selectOption.isNew) {
                    payloads.push({
                        comment_dynamic_property_id:
                            selectOption.comment_dynamic_property_id,
                        name: selectOption.name,
                    });
                } else {
                    existingSelectOptions.push({
                        id: selectOption.id,
                        name: selectOption.name,
                        comment_dynamic_property_id:
                            selectOption.comment_dynamic_property_id,
                    });
                }
            }
            newSelectOptionPayloads.push(...payloads);
            existingSelectOptionsPayloads.push(...existingSelectOptions);
        }
        const newSelectOptionRequests = newSelectOptionPayloads.map((dto) =>
            commentsServices.createSelectOption(dto)
        );

        const newSelectOptionResponses = await Promise.all(
            newSelectOptionRequests
        );
        const createdSelectOptions = (
            newSelectOptionResponses.filter(([error]) => error === false) as [
                false,
                UnpagedResponse<InsightSelectOptionInterface>,
            ][]
        ).map(([, response]) => response.data);

        if (createdSelectOptions.length !== newSelectOptionResponses.length) {
            await commentsServices.deleteComment(insight.data.id);

            const deleteRequests = createdSelectOptions
                .map((so) => so.id)
                .map((id) => deleteSelectOption(id));

            await Promise.all(deleteRequests);

            return false;
        }

        /* 5th step */
        existingSelectOptionsPayloads.push(
            ...createdSelectOptions.map((so) => ({
                id: so.id,
                name: so.name,
                comment_dynamic_property_id: so.comment_dynamic_property_id,
            }))
        );
        const dynamicPropertyToDynamicValueMap: Record<string, string> = {};
        for (const createdDynamicValue of createdDynamicValues) {
            dynamicPropertyToDynamicValueMap[
                createdDynamicValue.comment_dynamic_property_id
            ] = createdDynamicValue.id;
        }

        const linkDynamicValueToSelectOptionRequests = Object.entries(
            dynamicPropertyToDynamicValueMap
        ).map(([propertyId, dynamicValueId]) => {
            const columnSelectOptions = existingSelectOptionsPayloads
                .filter((so) => so.comment_dynamic_property_id === propertyId)
                .map((so) => so.id);

            return commentsServices.updateCellItems(dynamicValueId, {
                add_select_option_ids: columnSelectOptions,
                remove_select_option_ids: [],
            });
        });

        const linkDynamicValueToSelectOptionResponses = await Promise.all(
            linkDynamicValueToSelectOptionRequests
        );

        const linkedDynamicValuesToSelectOptions = (
            linkDynamicValueToSelectOptionResponses.filter(
                ([error]) => error === false
            ) as [
                false,
                UnpagedResponse<DynamicInsightValueDetailedInterface>,
            ][]
        ).map(([, response]) => response.data);

        if (
            linkedDynamicValuesToSelectOptions.length !==
            linkDynamicValueToSelectOptionResponses.length
        ) {
            // insight deletion removes all related InsightDynamicValueSelectOption entities
            await commentsServices.deleteComment(insight.data.id);

            const deleteRequests = createdSelectOptions
                .map((so) => so.id)
                .map((id) => deleteSelectOption(id));

            await Promise.all(deleteRequests);
            return false;
        }
    }
    return insight.data.id;
}
