import { useState } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { TopicsContext } from '~/context/topics';
import { useEntityContext, useTemporaryErrorDisplay } from '~/hooks';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicInterface } from '~/modules/Topics/types/Topic.interface';
import { contentServices } from '~/services';

const useTopic = ({ isEditing = true }: { isEditing: boolean }) => {
    const intl = useIntl();

    const [loading, setLoading] = useState(false);
    const { handleError, error } = useTemporaryErrorDisplay<{
        name: string;
        server: string;
    }>();

    const { getTopics, filledFolderTree: folderStructure = [] } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const updateTopic = async (payload: Partial<TopicInterface>) => {
        setLoading(true);

        const folderId =
            payload.folder_id === UNCATEGORIZED_FOLDER_NAME ||
            !payload.folder_id
                ? null
                : payload.folder_id;

        const topic = {
            ...payload,
            folder_id: folderId,
        };

        const [error, response] = isEditing
            ? await contentServices.updateTopic(topic.id as string, topic)
            : await contentServices.createTopic(topic);

        setLoading(false);

        if (error) {
            handleError({ server: 'general_error' });
            return response;
        }

        toast.success(
            intl.formatMessage({
                id: isEditing
                    ? 'topic_updated_successfully'
                    : 'topic_created_successfully',
            })
        );

        getTopics();
        return response;
    };

    return {
        folderStructure,
        loading,
        error,
        handleError,
        updateTopic,
    };
};

export default useTopic;
