import { useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import './index.scss';

interface AccordionInterface {
    title: string;
    extraPadding?: number;
    children: React.ReactNode;
    iconPosition?: string;
    expanded?: boolean;
    onExpand?: () => void;
}

const Accordion: React.FC<AccordionInterface> = ({
    title,
    extraPadding = 20,
    children,
    iconPosition = 'left',
    expanded = false,
    onExpand,
}) => {
    const [clicked, setClicked] = useState(expanded);
    const contentEl = useRef();
    const [wrapperHeight, setWrapperHeight] = useState(0);

    useEffect(() => {
        setClicked(expanded);
    }, [expanded]);

    useEffect(() => {
        if (!contentEl.current) return;
        const height = (contentEl.current?.scrollHeight || 0) + extraPadding;

        setWrapperHeight(height);
    });

    const handleToggle = () => {
        setClicked((prev) => !prev);
        if (onExpand) {
            onExpand();
        }
    };
    return (
        <div className="cg-accordion">
            <div className="cg-accordion__header" onClick={handleToggle}>
                {iconPosition === 'left' && (
                    <>{clicked ? <ChevronDown /> : <ChevronRight />}</>
                )}

                <span title={title}>{title}</span>

                {iconPosition === 'right' && (
                    <>{clicked ? <ChevronDown /> : <ChevronRight />}</>
                )}
            </div>

            <div
                className="cg-accordion__content-wrapper"
                style={
                    clicked
                        ? {
                              height: wrapperHeight,
                          }
                        : { height: '0px' }
                }
            >
                <div ref={contentEl} className="cg-accordion__content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
