import { AnalyticsBrowser } from '@june-so/analytics-next';
import { environment } from '~/config/env';

export enum EVENT_NAMES_ENUM {
    SIGN_UP = 'Sign Up',
    LOGIN = 'Login',
    IMPORT_DOC = 'Import Doc',
    CREATE_PROJECT = 'Create Project',
    TRIGGER_SEARCH = 'Trigger Search',
    EXTRACT_INSIGHT_SMARTVIEWER = 'Extract Insight SmartViewer',
    CREATE_TOPIC = 'Create Topic',
    CREATE_TAG = 'Create Tag',
    TAG_DOCUMENT = 'Tag Document',
    EXPORT_EXCEL = 'Export Excel',
    SEND_LINK_TO_REPORT = 'Send Link To Report',
    INVITE_TEAM = 'Invite Team',
    CREATE_REPORT = 'Create Report',
    OPEN_PUBLIC_LIBRARY = 'Open public library',
    ADD_PUBLIC_LIBRARY_TO_PROJECT_WITHOUT_TAGS = 'Add public library to project without tags',
    ADD_PUBLIC_LIBRARY_TO_PROJECT_WITH_TAGS = 'Add public library to project with tags',
    NATURAL_LANGUAGE_SEARCH_RELEVANT = 'natural_language_search_relevant',
    NATURAL_LANGUAGE_SEARCH_IRRELEVANT = 'natural_language_search_irrelevant',
}

interface GenericDataToTrack {
    [key: string]: string | number | boolean;
}

interface IdentifyUserDataInterface {
    email: string;
    first_name: string;
    last_name: string;
}

class Analytics {
    private readonly controller;

    constructor() {
        this.controller = environment.juneKey
            ? AnalyticsBrowser.load({
                  writeKey: environment.juneKey,
              })
            : undefined;
    }

    public trackLogin(userId: string, userData: IdentifyUserDataInterface) {
        if (!this.controller) return;

        this.controller.identify(userId, userData);
        this.controller.track(EVENT_NAMES_ENUM.LOGIN, userData);
    }

    public trackGenericEvent(
        eventName: EVENT_NAMES_ENUM,
        data?: GenericDataToTrack
    ) {
        if (!this.controller) return;

        this.controller.track(eventName, data || {});
    }
}

const instance = new Analytics();

export default instance;
