import { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';
import { ChevronDown, Icon } from 'react-feather';
import { Popover } from 'react-tiny-popover';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';

export interface OptionInterface extends LabelValueOptionInterface {
    variant?: string;
    icon?: Icon;
}

export interface SelectInputPropsInterface {
    value?: LabelValueOptionInterface | null;
    options: OptionInterface[];
    icon?: Icon;
    placeholder?: string;
    className?: string;
    error?: boolean;
    dropdownAlign?: 'start' | 'end' | 'center';
    onChange: (option: OptionInterface) => void;
    onOpen?: () => void;
}

const SelectInput = ({
    value = null,
    placeholder = '',
    className = '',
    error,
    options,
    icon: SelectIcon,
    dropdownAlign = 'start',
    onChange,
    onOpen,
}: SelectInputPropsInterface) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState<OptionInterface | null>(
        value
    );
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) setSelectedValue(value);
    }, [value]);

    const handleClickOutside = useCallback((e: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(e.target as Node)) {
            setShowMenu(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    const isSelected = (option: OptionInterface) =>
        selectedValue ? selectedValue.value === option.value : false;

    const onItemClick = (option: OptionInterface) => {
        setSelectedValue(option);
        onChange(option);
        handleInputClick();
    };

    const handleInputClick = () => {
        if (!showMenu) onOpen?.();
        setShowMenu(!showMenu);
    };

    return (
        <Popover
            isOpen={showMenu}
            align={dropdownAlign}
            positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
            content={
                <div className="dg-dropdown-menu">
                    {options.length === 0 && (
                        <div className="dg-dropdown-menu__empty">
                            {selectedValue?.label ?? placeholder}
                        </div>
                    )}
                    {options.map((option) => (
                        <div
                            onClick={(e) => {
                                onItemClick(option);
                                e.stopPropagation();
                            }}
                            key={option.value}
                            className={`dg-dropdown-item ${
                                isSelected(option) &&
                                'dg-dropdown-item__selected'
                            } dg-dropdown-item__${option.variant || ''}`}
                        >
                            <span>{option.label}</span>
                        </div>
                    ))}
                </div>
            }
        >
            <div
                className={`dg-dropdown-container ${className} ${
                    error ? 'dg-dropdown-container-error' : ''
                }`}
            >
                {SelectIcon && (
                    <div className="dg-dropdown-icon">
                        <SelectIcon />
                    </div>
                )}
                <div
                    ref={inputRef}
                    onClick={handleInputClick}
                    className={`dg-dropdown-input ${
                        SelectIcon ? 'dg-dropdown-input-icon' : ''
                    }`}
                >
                    <span
                        className={` ${
                            !selectedValue ? 'dg-dropdown-placeholder' : ''
                        }`}
                    >
                        {selectedValue ? selectedValue.label : placeholder}
                    </span>
                    <ChevronDown />
                </div>
            </div>
        </Popover>
    );
};

export default SelectInput;
