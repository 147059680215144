import { AccumulatedColumnValues } from '~/interfaces/columnValues/ColumnFilterContext.interface';

const dynamic_value_identifier = 'dynamic_values';

function mapColumnValue(label: string, value: string) {
    return {
        label: String(label),
        value: String(value),
        __id: `${label}-${value}`,
    };
}

export const noValueRecordsMap = {
    ___no_value_records: '(Blanks)',
};

interface ColumnValuesInterface {
    [key: string]: string[] | { [key: string]: string[] | number[] };
}

/**
 * Used to parse metadata from backend into ColumnValues object format in frontend
 */
export default function parseColumnValues(
    values: ColumnValuesInterface
): AccumulatedColumnValues {
    let parsed: AccumulatedColumnValues = {};

    for (const column in values) {
        // remapping dynamic columns metadata into
        // { ...parsed, [dynamicPropertyId]: [..., { label: dynamicValue1, value: "commentId1&commentId2&...&commentIdN"}]}
        if (column === dynamic_value_identifier) {
            for (const dynamicPropertyId in values[column]) {
                parsed[dynamicPropertyId] ??= [];

                for (const dynamicValue in values[column][dynamicPropertyId]) {
                    const valueLabel =
                        noValueRecordsMap[dynamicValue] ?? dynamicValue;

                    parsed[dynamicPropertyId].push(
                        mapColumnValue(
                            valueLabel,
                            values[column][dynamicPropertyId][
                                dynamicValue
                            ]?.join('&')
                        )
                    );
                }
            }
            continue;
        }

        // if value is array it means that it is a column with single values (strings)
        if (Array.isArray(values[column])) {
            parsed[column] = values[column].map((v) => mapColumnValue(v, v));

            continue;
        }

        const columnValues = [];
        for (const value in values[column]) {
            columnValues.push(
                mapColumnValue(value, values[column][value]?.join('&'))
            );
        }
        parsed = { ...parsed, [column]: columnValues };
    }

    return parsed;
}
