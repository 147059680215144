import { MultiSelectCellInterface } from '~/components/Tables/MultiSelectCell';
import { DynamicInsightValueDetailedInterface } from '~/interfaces/entities';

export const normalizeInsightCell = (
    cell: DynamicInsightValueDetailedInterface | undefined
): MultiSelectCellInterface => {
    if (!cell) {
        return {
            id: '',
            rowId: '',
            columnId: '',
            options: [],
        };
    }

    return {
        id: cell.id,
        rowId: cell.comment_id,
        columnId: cell.comment_dynamic_property_id,
        options: cell.comment_select_options,
    };
};
