export enum DYNAMIC_PROPERTY_TYPES {
    SNIPPET = 'snippet',
    SOURCE = 'source',
    PAGE = 'page',
    TOPIC = 'topic',
    TAG = 'tag',

    BOOLEAN = 'boolean',
    MULTI_SELECT = 'multi_select',
    NUMBER = 'number',
    SELECT = 'select',
    TEXT = 'text',
}
