const setItemInLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getItemFromLocalStorage = <T>(key: string): T | null => {
    const item = localStorage.getItem(key);

    if (!item) return null;

    try {
        return JSON.parse(item) as T;
    } catch (error) {
        console.error('Parsing error in getItemFromLocalStorage:', error);
        return null;
    }
};

export { setItemInLocalStorage, getItemFromLocalStorage };
