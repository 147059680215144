import { useEffect, useMemo, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ScreenCapturing.scss';
import CaretBoxIcon from '../../../assets/icons/shared/CaretBoxIcon';

const ScreenCapturing = ({ onCreate, imageSrc, onCancel, intl }) => {
    const cropperRef = useRef(null);
    const [image, setImage] = useState('');
    const [points, setPoints] = useState({ y: 0, x: 0 });

    useEffect(() => {
        const cancelEvent = (evt) => {
            if (evt.key === 'Escape') {
                onCancel();
                evt.stopPropagation();
            }
        };

        document.addEventListener('keydown', cancelEvent);

        return () => {
            document.removeEventListener('keydown', cancelEvent);
        };
    }, []);

    const onCrop = (e) => {
        const cropper = cropperRef.current?.cropper;

        setPoints(e.detail);

        const croppedImage = cropper.getCroppedCanvas().toDataURL();
        setImage(croppedImage);
    };

    const position = useMemo(() => {
        const { y, x } = points;

        return {
            top: y,
            left: x,
        };
    }, [points]);

    return (
        <div className="screen-capturing">
            <Cropper
                src={imageSrc}
                ref={cropperRef}
                background={false}
                highlight={true}
                autoCrop={false}
                viewMode={2}
                crop={onCrop}
                zoomOnWheel={false}
                className="screen-capturing__cropper"
            />
            {points.y > 0 && (
                <div
                    className={`actions`}
                    style={{
                        transform: `translateX(${position.left / 1.3}px) translateY(${position.top / 2 - 30}px)`,
                    }}
                >
                    <div onClick={() => onCreate(image)}>
                        <CaretBoxIcon />{' '}
                        <span>
                            {intl.formatMessage({ id: 'mark_with_topic' })}
                        </span>
                    </div>
                    <div className="cancel-btn" onClick={onCancel}>
                        {intl.formatMessage({ id: 'cancel' })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ScreenCapturing;
