import {
    createContext,
    useState,
    useEffect,
    useMemo,
    useRef,
    PropsWithChildren,
} from 'react';
import { contentServices } from '~/services';
import { ProjectContext } from '../project';
import { projectsServices } from '../../services';
import { mapUpperLevelFolderToChildren } from '../../utils/traverseFolderTreeFunctions';
import { UNCATEGORIZED_FOLDER_NAME } from '../../constants/folders';
import { useEntityContext, useOpenFolders } from '~/hooks';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import {
    FilledFolderTreeInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';
import { TopicsProviderInterface } from '../../modules/Topics/types/TopicsContext.interface';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import {
    EntityColorMap,
    assignColorsToEntities,
} from '~/helpers/assignColorsToEntities';
import { PillVariants } from '~/components/UIElements/Pill/constants';
import addFolderItemsCount from '~/helpers/addFolderInsightsCount';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';
import { EntitySortingParameters } from '~/interfaces/common';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import { mergeTopicsWithFolders } from '~/helpers/groupTopicsByFolder';

export interface MappedObjectTopicsInterface {
    [key: string]: TopicInterfaceDetailed;
}

export const TopicsContext = createContext<TopicsProviderInterface | null>(
    null
);

const TopicsProvider = ({ children }: PropsWithChildren) => {
    const isFirstRender = useRef(true);

    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [topics, setTopics] = useState<TopicInterfaceDetailed[]>([]);

    const [topicsLoaded, setTopicsLoaded] = useState(false);

    const [pinedTopicId, setPinedTopicId] = useState<string | null>(null);
    const pinedTopic = useMemo(
        () => topics.find((topic) => topic.id === pinedTopicId) || null,
        [topics, pinedTopicId]
    );

    const [folderStructure, setFolderStructure] = useState<
        FolderTreeInterface[]
    >([]);

    const [folderColors, setFolderColors] = useState<EntityColorMap>({});

    const [loading, setLoading] = useState(true);

    const [filterByStatus, setFilterByStatus] = useState<TOPIC_STATUSES | null>(
        null
    );

    const [sorting, setSorting] = useState<EntitySortingParameters>({
        field: 'created_at',
        direction: SORT_TYPE_OPTIONS.ASC,
    });

    const { openedFolders, openFolder, resetOpenedFolders } = useOpenFolders();

    useEffect(() => {
        if (!selectedProject) {
            // edge case: when user changes the workspace and the selected one has no projects --> selectedProject is null
            // but states as topics, selectedTopics, etc. are filled up
            if (topics.length || folderStructure) resetMainStates();

            return setLoading(false);
        }

        resetOpenedFolders();
        setFolderColors({});

        getTopics();
    }, [selectedProject?.id]);

    useEffect(() => {
        if (!selectedProject) return;

        getTopics();
    }, [sorting]);

    const handleLoading = async () => {
        await new Promise<void>((resolve) =>
            setTimeout(() => {
                resolve(setLoading(false)); // add a very little delay to avoid flickering
            }, 200)
        );
    };

    // initialTopics param: when user comes from a queryLink we need to opened the folders of the selected topics
    const getFolderStructure = async (uncategorizedColors = {}) => {
        setLoading(true);

        const options = {
            sort_order: sorting.direction,
            sort_attribute: sorting.field,
        };

        const [error, response] =
            await projectsServices.getProjectFolderStructure(
                selectedProject?.id as string,
                options
            );

        await handleLoading();

        if (error) return;

        const { data } = response;

        setFolderStructure(data);
        const folderIds = data.map((fs) => fs.id); // retrieving only top level folders.

        const alreadyAppliedColors = uncategorizedColors || folderColors;

        const colorsToApply = assignColorsToEntities(
            folderIds,
            alreadyAppliedColors
        );

        setFolderColors({
            ...uncategorizedColors,
            ...colorsToApply,
        });
    };

    const filteredTopicsByStatus = useMemo(() => {
        if (!filterByStatus) return topics;

        const filtered = topics.filter(
            (topic) => topic.status === filterByStatus
        );

        return filtered;
    }, [topics, filterByStatus]);

    const filledFolderTree: FilledFolderTreeInterface[] = useMemo(() => {
        const newStructure: FilledFolderTreeInterface[] = [];

        const topicsWithNoFolder = filteredTopicsByStatus.filter(
            (t) => !t.folder_id
        );

        if (topicsWithNoFolder.length) {
            const ungroupedTopicsFolder = {
                name: UNCATEGORIZED_FOLDER_NAME,
                id: UNCATEGORIZED_FOLDER_NAME,
                children: [],
                items: topicsWithNoFolder,
                project_id: '1',
                items_count: 0,
            };
            newStructure.push(ungroupedTopicsFolder);
        }

        if (!folderStructure.length) return newStructure;

        const foldersWithTopics = mergeTopicsWithFolders(
            filteredTopicsByStatus,
            [...folderStructure]
        );

        const joinedStructure = [...foldersWithTopics, ...newStructure];

        const stuctureWithItemsCount = addFolderItemsCount(joinedStructure);

        return stuctureWithItemsCount;
    }, [folderStructure, filteredTopicsByStatus]);

    const folderToSubFoldersMap = useMemo(() => {
        return mapUpperLevelFolderToChildren(folderStructure);
    }, [folderStructure]);

    const getTopicColor = (topic: TopicInterfaceDetailed): PillVariants => {
        if (topic.folder_id) {
            if (!(topic.folder_id in folderColors)) {
                for (const upperLevelId of Object.keys(folderToSubFoldersMap)) {
                    if (
                        folderToSubFoldersMap[upperLevelId].includes(
                            topic.folder_id
                        )
                    ) {
                        return folderColors[upperLevelId];
                    }
                }
            }
            return folderColors[topic.folder_id];
        }

        return folderColors[topic.id];
    };

    const getTopics = async () => {
        setLoading(true);
        setTopicsLoaded(false);

        const [error, response] = await contentServices.getTopics({
            all: true,
            sort_order: sorting.direction,
            sort_attribute: sorting.field,
            project_ids: selectedProject?.id,
        });

        if (error) return await handleLoading();

        const { data } = response;
        setTopicsLoaded(true);

        setTopics(data);

        const folderIds = data.filter((t) => !t.folder_id).map((t) => t.id); // retrieving only top level folders.

        const colorsToApply = assignColorsToEntities(folderIds, folderColors);

        getFolderStructure(colorsToApply);

        if (pinedTopicId) {
            // check if pined topic still exist
            const stillExist = data.some((topic) => topic.id === pinedTopicId);

            if (stillExist) return;
        }

        setPinedTopicId(null);
        isFirstRender.current = false;
    };

    const resetMainStates = () => {
        setTopics([]);
        setPinedTopicId(null);
        setFolderStructure([]);
        resetOpenedFolders();
    };

    const topicOptions = useMemo(() => {
        return topics.map((topic) => ({
            label: topic.name,
            value: topic.id,
        }));
    }, [topics]);

    const handleSorting = (sort: {
        field: string;
        direction: SORT_TYPE_OPTIONS;
    }) => {
        if (
            sort.field === sorting.field &&
            sort.direction === sorting.direction
        )
            return;

        setSorting(sort);
    };

    const objectMappedTopics: MappedObjectTopicsInterface = useMemo(
        () =>
            topics.reduce(
                (
                    acc: MappedObjectTopicsInterface,
                    topic: TopicInterfaceDetailed
                ) => {
                    acc[topic.id] = topic;
                    return acc;
                },
                {}
            ),
        [topics]
    );

    const handlePinedTopic = (topicId: string) => {
        if (pinedTopicId === topicId) {
            return setPinedTopicId(null);
        }
        setPinedTopicId(topicId);
    };

    return (
        <TopicsContext.Provider
            value={{
                loading,
                topics: filteredTopicsByStatus,
                pinedTopic,
                sorting,
                filterByStatus,
                topicOptions,
                folderStructure,
                filledFolderTree,
                openedFolders,
                objectMappedTopics,
                isFirstRender: isFirstRender.current,
                topicsLoaded,
                folderColors,
                handleFilterByStatus: setFilterByStatus,
                getTopicColor,
                handleSorting,
                handlePinedTopic,
                getTopics,
                getFolderStructure,
                openFolder,
                setFolderStructure,
                setLoading,
            }}
        >
            {children}
        </TopicsContext.Provider>
    );
};

export default TopicsProvider;
