import './index.scss';
import Avatar from '../Avatar';
import randomColors from '~/constants/randomColorsArray';
import { forwardRef } from 'react';

interface AvatarStackProps {
    avatars: string[];
    maxAvatars?: number;
    size?: string;
    onClick: (e: React.MouseEvent) => void;
    disabled?: boolean;
}

const AvatarStack: React.FC<AvatarStackProps> = forwardRef(
    (
        {
            avatars = [],
            maxAvatars = 5,
            size = 'regular',
            disabled = false,
            onClick,
        },
        ref
    ) => {
        const remaining = avatars.length - maxAvatars;

        return (
            <div
                ref={ref}
                className="dg-avatar-stack"
                onClick={(e) => onClick && onClick(e)}
            >
                {avatars.slice(0, maxAvatars).map((avatar, idx) => (
                    <Avatar
                        value={avatar}
                        key={idx}
                        disabled={disabled}
                        size={size}
                        style={{
                            backgroundColor: randomColors[idx],
                            zIndex: avatars.length - idx,
                        }}
                    />
                ))}

                {remaining > 0 && (
                    <Avatar
                        value={`+${remaining}`}
                        size={size}
                        style={{
                            backgroundColor: '#ececec',
                            color: '#71757D',
                            fontWeight: '400',
                        }}
                    />
                )}
            </div>
        );
    }
);

export default AvatarStack;
