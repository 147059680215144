import React from 'react';

function PinIcon({ onClick }: { onClick?: (e: React.MouseEvent) => void }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={(e) => onClick && onClick(e)}
            height="24"
            width="14"
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M10.736 1.813a.667.667 0 01-.258.907L2.563 7.138a.667.667 0 11-.65-1.165l7.916-4.417a.667.667 0 01.907.257zM12.52 3.598c.322.18.438.586.258.907L8.36 12.421a.667.667 0 01-1.164-.65l4.418-7.916a.667.667 0 01.907-.257z"
                clipRule="evenodd"
            ></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M8.545.53c.26-.261.682-.261.943 0l4.316 4.316a.667.667 0 11-.942.943L8.545 1.472a.667.667 0 010-.943zM5.48 8.854c.26.26.26.683 0 .943L1.47 13.805a.667.667 0 01-.943-.942l4.009-4.009c.26-.26.682-.26.942 0z"
                clipRule="evenodd"
            ></path>
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M.528 4.846c.26-.26.683-.26.943 0l8.017 8.017a.667.667 0 01-.943.942L.528 5.79a.667.667 0 010-.943z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default PinIcon;
