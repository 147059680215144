import { ReactNode } from 'react';
import {
    FilledFolderTreeInterface,
    FolderTreeInterface,
} from '~/interfaces/entities';
import { FolderItemTypes } from '~/constants/folders';
import RecursiveFolderTree from './RecursiveFolderTree';

import styles from './FolderTree.module.scss';

export interface FolderTreeInterfaceBase<T> {
    selectedFolders?: string[];
    showActions?: boolean;
    showFiles?: boolean;
    expandedFolders?: string[];
    expandAllFolders?: boolean;
    showItemsCount?: boolean;
    folderItemsType?: FolderItemTypes;
    customItem?: (props: T) => ReactNode;
    onUpdateFolder?: (folder: FolderTreeInterface) => Promise<void>;
    onSelectFolder?: (folder: FilledFolderTreeInterface) => void;
    onDeleteFolder?: (folderId: string) => void;
    onExpandFolder?: (folderId: string) => void;
    onCreateFolderItem?: (itemName: string, parentId: string) => void;
    onCreateSubfolder?: (folderName: string, parentFolderId: string) => void;
    onMoveItem?: ({ id, folderId }: { id: string; folderId: string }) => void;
}

export interface FolderTreeInterfaceProps<T>
    extends FolderTreeInterfaceBase<T> {
    structure: FilledFolderTreeInterface[];
}

const FolderTree = <T,>({
    structure,
    ...props
}: FolderTreeInterfaceProps<T>) => {
    return (
        <div className={styles.cgFolderTree}>
            {structure.map((st, idx) => (
                <RecursiveFolderTree
                    key={idx}
                    structure={st}
                    globalStructure={structure}
                    {...props}
                />
            ))}
        </div>
    );
};

export default FolderTree;
