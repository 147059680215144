import { InputHTMLAttributes } from 'react';
import './index.scss';

const Checkbox = ({
    value,
    onChange,
    className = '',
    checked = false,
    ...rest
}: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <input
            type="checkbox"
            value={value}
            onChange={onChange}
            className={`dg-checkbox ${className}`}
            checked={checked}
            {...rest}
        />
    );
};

export default Checkbox;
