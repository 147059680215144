import './index.scss';
import SwitchInput from '../SwitchInput';

const mainClass = 'cg-switch-field';
const SwitchField = ({ className = '', label = '', inputProps = {} }) => {
    return (
        <div className={mainClass + className}>
            <label> {label} </label>
            <SwitchInput
                isOn={inputProps.value}
                handleToggle={(e) => inputProps.onChange(e.target.checked)}
                id={inputProps.id || 'switch-field'}
            />
        </div>
    );
};

export default SwitchField;
