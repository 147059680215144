import { RefObject } from 'react';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import { FilledFolderTreeInterface } from '~/interfaces/entities';
import BaseFloatMenu from '~/components/UIElements/BaseFloatMenu';
import { BasePopover } from '~/components/UIElements';

import styles from './Dialogs.module.scss';
import CreateUpdateTopicForm from '~/modules/Topics/components/CreateUpdateTopicForm';

interface CreateTopicDialogProps {
    folder: FilledFolderTreeInterface;
    createTopicMenu: FloatMenuInterface;
    cardRef: RefObject<HTMLDivElement>;
    onCreateTopic: () => void;
}

const CreateTopicDialog = ({
    folder,
    createTopicMenu,
    cardRef,
    onCreateTopic,
}: CreateTopicDialogProps) => {
    if (!cardRef.current) return;

    return (
        <BasePopover
            isOpen={createTopicMenu.showMenu}
            positions={['right', 'top']}
            align="start"
            onClickOutside={createTopicMenu.handleMenu}
            parentContainer={cardRef.current}
            content={
                <BaseFloatMenu
                    className={styles.cgDialogTopic}
                    animated={false}
                >
                    <CreateUpdateTopicForm
                        callback={onCreateTopic}
                        destinationFolderId={folder.id}
                    />
                </BaseFloatMenu>
            }
        >
            <span></span>
        </BasePopover>
    );
};

export default CreateTopicDialog;
