import './TagsInput.scss';
import Pill from '../Pill';
import { PillVariants } from '~/components/UIElements/Pill/constants';
import { X } from 'react-feather';
import { useEntityContext } from '~/hooks';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TagsContext } from '~/context/tags';

interface TagsInputInterface {
    canAddNewTag: boolean;
    value: { value: string; label: string; isNew?: boolean }[];
    onChange: (
        value: { value: string; label: string; isNew?: boolean }[]
    ) => void;
    placeholder: string;
    inputValue: string;
    onChangeInputValue: (value: string) => void;
    disabled?: boolean;
    disableEnterKey?: boolean;
    autofocus?: boolean;
    getTagColorCustomFn?: (tagId: string) => PillVariants;
}

const TagsInput: React.FC<TagsInputInterface> = ({
    canAddNewTag,
    value,
    placeholder,
    inputValue,
    disabled,
    disableEnterKey,
    autofocus,
    onChange,
    onChangeInputValue,
    getTagColorCustomFn,
}) => {
    const { getTagColor } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    const onPressEnter = () => {
        if (!canAddNewTag || disableEnterKey) return;

        const newTag = {
            value: inputValue,
            label: inputValue,
            isNew: true,
        };

        const newSelectedTags = [...value, newTag];
        onChange(newSelectedTags);
        onChangeInputValue('');
    };

    const onRemove = (tagValue: string) => {
        const newValue = value.filter((tag) => tag.value !== tagValue);
        onChange(newValue);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onPressEnter();
        }

        // if user press backspace and input is empty, remove last tag
        if (e.key === 'Backspace' && !inputValue) {
            const newValue = [...value];
            newValue.pop();
            onChange(newValue);
        }
    };

    const getTagColorFn = getTagColorCustomFn || getTagColor;

    return (
        <div className="cg-tags-input">
            {value.map((tag, idx) => (
                <Pill key={idx} variant={getTagColorFn(tag.value)}>
                    <p title={tag.label}>{tag.label}</p>
                    <X
                        onClick={(e) => {
                            onRemove(tag.value);
                            e.stopPropagation();
                        }}
                    />
                </Pill>
            ))}
            <input
                type="text"
                placeholder={placeholder}
                onChange={(e) => onChangeInputValue(e.target.value)}
                onKeyDown={onKeyDown}
                value={inputValue}
                disabled={disabled}
                autoFocus={autofocus}
            />
        </div>
    );
};

export default TagsInput;
