import { Icon } from 'react-feather';
import { PillVariants } from '~/components/UIElements/Pill/constants';

import './index.scss';

const mainClass = 'cg-icon-pill';

interface IconPillInterface {
    icon: Icon;
    variant: PillVariants;
}

const IconPill: React.FC<IconPillInterface> = ({
    icon: Icon,
    variant = PillVariants.AMETHYST,
}) => {
    return (
        <div className={`${mainClass} ${mainClass}__${variant}`}>
            <Icon />
        </div>
    );
};

export default IconPill;
