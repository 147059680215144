import React from 'react';
import './index.scss';
import { IconButton } from '~/components/UIElements';
import { X } from 'react-feather';

interface PillInterface {
    variant?: 'tertiary' | 'bordered' | 'primary' | 'accent';
    onClick?: () => void;
    children: React.ReactNode;
    className?: string;
    clickable?: boolean;
    prefix?: string;
    removeButtonType?: 'float' | 'fixed';
    onRemove?: () => void;
}

const Pill: React.FC<PillInterface> = ({
    variant,
    children,
    className = '',
    clickable,
    prefix,
    onClick,
    onRemove,
    removeButtonType = 'float',
    ...rest
}) => {
    return (
        <div className="dg-pill-container">
            <div
                onClick={onClick}
                className={`dg-pill dg-pill-${variant} ${
                    clickable ? 'dg-pill__clickable' : ''
                }  ${className} `}
                {...rest}
            >
                {prefix && <span className="dg-pill__prefix">{prefix}</span>}
                {children}

                {onRemove && removeButtonType === 'fixed' && (
                    <X
                        onClick={(e) => {
                            e.stopPropagation();
                            onRemove();
                        }}
                        className="dg-pill__remove-fixed"
                    />
                )}
            </div>
            {onRemove && removeButtonType === 'float' && (
                <IconButton
                    icon={X}
                    variant="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    className={'dg-pill-container__remove'}
                />
            )}
        </div>
    );
};
export default Pill;
