import getDefaultWsToSelect from '~/modules/Workspaces/helpers/getDefaultWsToSelect';
import { WorkspacesStoreInterface } from '~/modules/Workspaces/types/workspacesStore.interface';
import { workspaceServices } from '~/services';
import { QueryParamsInterface } from '~/types/queryParams.interface';
import { getQueryParams } from '~/utils/getQueryParams';

const queryParams = getQueryParams<QueryParamsInterface>('params');

export const onFetchWorkspaces: (
    set: (state: Partial<WorkspacesStoreInterface>) => void,
    get: () => WorkspacesStoreInterface
) => () => Promise<void> = (set, get) => async () => {
    set({ loading: true });

    const { error, data } = await workspaceServices.getWorkspaces({
        all: true,
    });

    set({ loading: false });

    if (error) return;

    set({ workspaces: data });

    const defaultWs = getDefaultWsToSelect(queryParams, data);

    get().handleSelectedWorkspace(defaultWs);

    // SIDE EFFECTS:
    get().fetchMembersAndInvitations();
};

export const onFetchWorkspaceMembersAndInvitations: (
    set: (state: Partial<WorkspacesStoreInterface>) => void,
    get: () => WorkspacesStoreInterface
) => () => Promise<void> = (set, get) => async () => {
    set({ loadingMembers: true });

    const wsId = get().selectedWorkspaceId;

    const { error, members, invitations } =
        await workspaceServices.getMembersAndInvitations(wsId as string);

    set({ loadingMembers: false });

    if (error) return;

    set({ members, invitations: invitations });
};
