function MidDottedCircleIcon({
    onClick,
}: {
    onClick?: (e: React.MouseEvent) => void;
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={(e) => onClick && onClick(e)}
        >
            <path
                fill="#000"
                d="M6.537.643a7.5 7.5 0 014.768 14.092.5.5 0 11-.441-.898 6.491 6.491 0 002.015-1.542 6.483 6.483 0 001.494-5.584c-.17-.84-.5-1.621-.959-2.31l-.008-.012a6.538 6.538 0 00-1.795-1.794l-.006-.005A6.467 6.467 0 008 1.5c-.435 0-.859.043-1.27.124a.5.5 0 11-.193-.981zM4.526 1.9a.5.5 0 01-.137.695 6.538 6.538 0 00-1.794 1.794.5.5 0 11-.831-.556 7.538 7.538 0 012.069-2.07.5.5 0 01.693.138zM1.23 6.143a.5.5 0 01.394.588 6.534 6.534 0 000 2.538.5.5 0 11-.981.194 7.534 7.534 0 010-2.926.5.5 0 01.587-.394zM1.9 11.474a.5.5 0 01.695.137 6.538 6.538 0 001.794 1.794.5.5 0 11-.556.831 7.539 7.539 0 01-2.07-2.069.5.5 0 01.138-.693zM6.143 14.77a.5.5 0 01.588-.394 6.536 6.536 0 002.254.05.5.5 0 11.15.989 7.559 7.559 0 01-2.598-.058.5.5 0 01-.394-.587z"
            ></path>
            <path fill="#000" d="M6 7.5a.5.5 0 000 1h4a.5.5 0 000-1H6z"></path>
        </svg>
    );
}

export default MidDottedCircleIcon;
