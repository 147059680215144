function CaretBoxIcon({ onClick }: { onClick?: () => void }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={() => onClick && onClick()}
        >
            <g
                fillRule="evenodd"
                clipPath="url(#clip0_3613_9796)"
                clipRule="evenodd"
            >
                <path d="M8.666 1.333c0-.368.299-.666.667-.666A3.333 3.333 0 0112.666 4a.667.667 0 01-1.333 0 2 2 0 00-2-2 .667.667 0 01-.667-.667z"></path>
                <path d="M14.666 2a2 2 0 00-2 2v8a2 2 0 002 2 .667.667 0 110 1.333A3.333 3.333 0 0111.333 12V4A3.333 3.333 0 0114.666.667a.667.667 0 110 1.333z"></path>
                <path d="M12 11.333c.368 0 .666.299.666.667a3.333 3.333 0 01-3.333 3.333.667.667 0 110-1.333 2 2 0 002-2c0-.368.299-.667.667-.667zM.667 4c0-.368.298-.667.666-.667h7.333a.667.667 0 010 1.334H2v6.666h6.666a.667.667 0 010 1.334H1.333A.667.667 0 01.667 12V4z"></path>
            </g>
            <defs>
                <clipPath id="clip0_3613_9796">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CaretBoxIcon;
