import requestAdapterInstance from '../helpers/requestAdapter';
import { sessionServices } from '../services';

export async function checkTokenExpiration(): Promise<
    | {
          sessionHasExpired: true;
          token: null;
      }
    | {
          sessionHasExpired: false;
          token: string;
      }
> {
    try {
        const sessionData = sessionServices.getUserData();

        if (!sessionData) return { sessionHasExpired: true, token: null };

        const { expires_in, refresh_expires_in, token } = sessionData;

        const hasExpired = expires_in < Date.now();
        const refreshTokenHasExpired = refresh_expires_in < Date.now();

        // if token has expired but the refresh token is still active, let's refresh the main token
        if (refreshTokenHasExpired) {
            return { token: null, sessionHasExpired: true };
        }
        if (hasExpired) {
            const { token } = await requestAdapterInstance.getNewSession();

            return {
                sessionHasExpired: false,
                token: token,
            };
        }

        return {
            sessionHasExpired: false,
            token,
        };
    } catch (ex) {
        console.log(ex);
    }
    return { token: null, sessionHasExpired: true };
}
