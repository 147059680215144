export function updateColumnsOrder(columns, movedColumnId, newPosition) {
    // Create a copy of the columns array
    const newColumns = [...columns];

    // Find the index of the moved column
    const movedColumnIndex = newColumns.findIndex(
        (column) => column.id === movedColumnId
    );

    // Remove the moved column from its current position
    const [movedColumn] = newColumns.splice(movedColumnIndex, 1);

    // Insert the moved column at its new position
    newColumns.splice(newPosition, 0, movedColumn);

    let newColumnPosition: number = 0;

    // Update the position of each column
    newColumns.forEach((column, index) => {
        column.position = index;
        if (column.id === movedColumnId) newColumnPosition = index;
    });

    return { newColumns, newColumnPosition };
}
