import { useState } from 'react';
import './QueryLinkButton.scss';
import IconButton from '../IconButton';
import { Link } from 'react-feather';
import { Tooltip } from 'react-tooltip';

const QueryLinkButton = ({ copiedText, onClick, ...props }) => {
    const [show, setShow] = useState(false);

    const handleCopy = () => {
        onClick();
        setShow(true);

        setTimeout(() => {
            setShow(false);
        }, 2500);
    };

    return (
        <div
            className="query-button"
            data-tooltip-id="second-search-info"
            data-tooltip-content={copiedText}
        >
            <Tooltip
                opacity={1}
                place="bottom"
                noArrow
                id="second-search-info"
                isOpen={show}
                hidden={!show}
            />
            <IconButton
                variant="secondary"
                icon={Link}
                onClick={handleCopy}
                {...props}
            />
        </div>
    );
};

export default QueryLinkButton;
