function StarMenuIcon({ onClick }: { onClick?: () => void }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
            onClick={() => onClick && onClick()}
        >
            <g
                fillRule="evenodd"
                clipPath="url(#clip0_2735_1836)"
                clipRule="evenodd"
            >
                <path d="M8 .993c.254 0 .486.144.598.372l1.905 3.859 4.26.619a.667.667 0 01.37 1.137l-2 1.95a.667.667 0 01-.932-.955l1.033-1.007-3.27-.475a.667.667 0 01-.502-.365L8 3.166 6.538 6.128a.667.667 0 01-.502.365l-3.27.475 2.366 2.307a.667.667 0 01.192.59l-.559 3.257 1.592-.837a.667.667 0 01.62 1.18L4.19 14.93a.666.666 0 01-.967-.703l.727-4.242L.868 6.98a.667.667 0 01.37-1.137l4.26-.619 1.904-3.86A.667.667 0 018 .995zM9.333 14.667c0-.368.299-.667.667-.667h5.333a.667.667 0 010 1.333H10a.667.667 0 01-.667-.666z"></path>
                <path d="M9.333 11.333c0-.368.299-.666.667-.666h5.333a.667.667 0 010 1.333H10a.667.667 0 01-.667-.667z"></path>
            </g>
        </svg>
    );
}

export default StarMenuIcon;
