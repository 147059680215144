import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { AuthContext } from '~/context/auth';
import {
    useEntityContext,
    useLastSelectedEntity,
    useRecentProjects,
} from '~/hooks';
import {
    AuthContextInterface,
    ProjectProviderInterface,
} from '~/interfaces/contexts';
import {
    ProjectInterface,
    ProjectInterfaceDetailed,
    ProjectMemberInterfaceDetailed,
    ProjectMemberInvitationInterface,
} from '~/modules/Projects/types/projects.interface';
import { BaseLinkInterface } from '~/interfaces/links/BaseLink.interface';
import useWorkspaces from '~/modules/Workspaces/hooks/useWorkspaces';
import useWorkspacesStore from '~/modules/Workspaces/stores';
import { projectsServices } from '~/services';
import { getQueryParams } from '~/utils/getQueryParams';
import replaceLocationState from '~/utils/replaceLocationState';

const queryParams: BaseLinkInterface | null = getQueryParams('params');

export const ProjectContext = createContext<ProjectProviderInterface | null>(
    null
);

ProjectContext.displayName = 'ProjectContext';

const ProjectProvider = ({ children }: PropsWithChildren) => {
    const initialSet = useRef(false);

    const lastProject = useRef<string | null>(null);
    const { addProject } = useRecentProjects();

    const { user } = useEntityContext<AuthContextInterface>(AuthContext);

    const { selectedWorkspace } = useWorkspaces();
    const handleSelectedWorkspace = useWorkspacesStore(
        (state) => state.handleSelectedWorkspace
    );

    const [showLibrary, setShowLibrary] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);

    // PROJECTS
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedProjectId, setSelectedProjectId] =
        useLastSelectedEntity<string>(
            'selectedProject',
            Boolean(queryParams?.project)
        );
    const [projects, setProjects] = useState<ProjectInterfaceDetailed[]>([]);

    // PROJECT MEMBERS
    const [loadingMembers, setLoadingMembers] = useState<boolean>(false);
    const [projectMembers, setProjectMembers] = useState<
        (ProjectMemberInterfaceDetailed | ProjectMemberInvitationInterface)[]
    >([]);

    const selectedProject = useMemo(
        () => projects.find((p) => p.id === selectedProjectId) || null,
        [selectedProjectId, projects]
    );

    useEffect(() => {
        if (!selectedWorkspace) return;

        if (!initialSet.current) {
            getProjects(true);
            initialSet.current = true;
        }
    }, [selectedWorkspace?.id]);

    useEffect(() => {
        if (!selectedProjectId) return;

        addProject(selectedProjectId);

        lastProject.current = selectedProjectId;
        getProjectMembers();
    }, [selectedProjectId]);

    /**
     * @param newProjectId If a new project was created by a previous operation, we need to select it when it returns anew from the backend.
     */
    const getProjects = async (initial?: boolean) => {
        const options = {
            all: true,
            sort_attribute: 'name',
            sort_order: SORT_TYPE_OPTIONS.ASC,
        };

        setLoading(true);
        const [error, response] = await projectsServices.getProjects(options);

        setLoading(false);

        if (error) return;

        const { data } = response;
        setProjects(data);

        // check if there is a query link
        if (queryParams?.project && initial) {
            // check if the project exists
            const queryProject = data.find(
                (p) => p.id === queryParams?.project
            );

            if (queryProject) {
                return setSelectedProjectId(queryProject.id);
            } else {
                replaceLocationState();
            }
        }

        if (selectedProjectId) {
            // check if the selected project still exists and if it's part of the selected workspace
            const stillExist = data.some(
                (p) =>
                    p.id === selectedProjectId &&
                    p.workspace_id === selectedWorkspace?.id
            );

            if (stillExist) return;
        }

        // select the first project of the selected workspace
        const project = data.find(
            (p) => p.workspace_id === selectedWorkspace?.id
        );

        setSelectedProjectId(project?.id || null);
    };

    const getProjectMembers = async () => {
        setLoadingMembers(true);

        if (!selectedProjectId) return;

        const [membersError, membersResponse] =
            await projectsServices.getAllMembers(selectedProjectId);
        let members: ProjectMemberInterfaceDetailed[] = [];

        if (!membersError) {
            members = membersResponse.data;
        }

        // we need the project invitations to be displayed
        const [invitationsError, invitationsResponse] =
            await projectsServices.getProjectInvitations(selectedProjectId);
        let invitations: ProjectMemberInvitationInterface[] = [];

        if (!invitationsError) {
            invitations = invitationsResponse.data;
        }
        setLoadingMembers(false);

        setProjectMembers([...members, ...invitations]);
    };

    // to check if the current user is a manager of the selected project
    const userIsManager = useCallback(
        (projectId: string) => {
            if (!user) return false;

            const userId = user.id;

            const pr = projects.find((p) => p.id === projectId);

            const isManager = pr?.details.administrators.some(
                (ad) => ad.id === userId
            );

            return Boolean(isManager);
        },
        [user, projects, selectedProject]
    );

    const workspaceProjects = useMemo(
        () => projects.filter((p) => p.workspace_id === selectedWorkspace?.id),
        [selectedWorkspace, projects]
    );

    // HAS USER GOT ANY PROJECT?
    const hasProjects = useMemo(
        () => Boolean(workspaceProjects?.length),
        [workspaceProjects]
    );

    const handleSelectedProject = (
        project: ProjectInterfaceDetailed | ProjectInterface
    ) => {
        if (selectedWorkspace?.id !== project.workspace_id)
            handleSelectedWorkspace(project.workspace_id);

        setSelectedProjectId(project.id);
    };

    return (
        <ProjectContext.Provider
            value={{
                loading,
                setLoading,
                allProjects: projects, // all workspaces projects
                projects: workspaceProjects, // only the projects of the selected workspace
                getProjects,

                selectedProject,

                loadingMembers,
                setLoadingMembers,
                projectMembers,
                getProjectMembers,

                userIsManager,
                hasProjects,
                handleSelectedProject,
                showLibrary,
                handleShowLibrary: setShowLibrary,
                showImportModal,
                handleShowImportModal: setShowImportModal,
            }}
        >
            {children}
        </ProjectContext.Provider>
    );
};

export default ProjectProvider;
