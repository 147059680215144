import { create } from 'zustand';
import { WorkspaceInterfaceDetailed } from '~/interfaces/entities';
import { WORKSPACE_STORAGE_KEY } from '~/modules/Workspaces/constants/workspaceStorage';
import {
    onFetchWorkspaceMembersAndInvitations,
    onFetchWorkspaces,
} from './workspacesStoreActions';
import { WorkspacesStoreInterface } from '~/modules/Workspaces/types/workspacesStore.interface';
import { setItemInLocalStorage } from '~/utils/handleItemInLocalStorage';

const useWorkspacesStore = create<WorkspacesStoreInterface>()((set, get) => ({
    selectedWorkspaceId: null,
    workspaces: [],
    loading: false,
    loadingMembers: false,
    members: [],
    invitations: [],
    handleWorkspaces: (workspaces: WorkspaceInterfaceDetailed[]) =>
        set({ workspaces }),
    handleSelectedWorkspace: (wsId: string) => {
        setItemInLocalStorage(WORKSPACE_STORAGE_KEY, wsId);
        set({ selectedWorkspaceId: wsId });
    },
    fetchWorkspaces: onFetchWorkspaces(set, get),
    fetchMembersAndInvitations: onFetchWorkspaceMembersAndInvitations(set, get),
}));

export default useWorkspacesStore;
