import { environment } from '~/config/env';
import requestAdapterInstance, {
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateUserDTO,
    LoginUserDTO,
    UserData,
    UserForgotPasswordDTO,
    UserPrivateInterface,
} from '~/interfaces/entities';
import { sessionServices, workspaceServices } from '~/services';

const authenServices = {
    createAccount,
    validateAccount,
    login,
    sendRecoveryEmail,
    resendConfirmationLink,
    createSession,
};
export default authenServices;

export const CreateSessionError = {
    bad_credentials: 'bad_credentials',
    disabled_user: 'disabled_user',
    workspace_error: 'workspace_error',
};

async function createAccount(accountData: CreateUserDTO) {
    const url = environment.apiUrl + '/users';
    return await requestAdapterInstance.publicPost<
        UnpagedResponse<UserPrivateInterface>,
        CreateUserDTO
    >(url, accountData);
}

async function validateAccount(token: string) {
    const url = environment.apiUrl + '/users/confirm_email';
    return await requestAdapterInstance.publicPost<null, { token: string }>(
        url,
        { token }
    );
}

async function login(credentials: LoginUserDTO) {
    const url = environment.apiUrl + '/auth/login';
    return await requestAdapterInstance.publicPost<
        UnpagedResponse<UserData>,
        LoginUserDTO
    >(url, credentials);
}
async function resendConfirmationLink(email: string = '') {
    const url = environment.apiUrl + '/users/resend_confirmation_email';
    return await requestAdapterInstance.publicPost<null, { email: string }>(
        url,
        { email }
    );
}

async function sendRecoveryEmail(credentials: UserForgotPasswordDTO) {
    const url = environment.apiUrl + '/users/forgot_password';
    return await requestAdapterInstance.publicPost<null, UserForgotPasswordDTO>(
        url,
        credentials
    );
}

/**
 * Complete session initialization flow. This includes login, user workspace fetch, local storage session store.
 * Returns non null internalError if it exists.
 */
async function createSession(credentials: LoginUserDTO) {
    const [error, response] = await authenServices.login(credentials);

    if (error && response.code === 'user_account_not_confirmed') {
        return {
            code: response.code,
            internalError: CreateSessionError.disabled_user,
        };
    }
    if (error) {
        return {
            code: response.code,
            internalError: CreateSessionError.bad_credentials,
        };
    }

    sessionServices.writeSessionData({ ...response.data });

    const { workspaceError } = await workspaceServices.getWorkspacesOnLogin(
        response.data.user.first_name
    );

    if (workspaceError) {
        sessionServices.cleanSessionData();
        return {
            code: null,
            internalError: CreateSessionError.workspace_error,
        };
    }
    const sessionData = {
        ...response.data,
    };

    sessionServices.writeSessionData(sessionData);

    return { code: null, internalError: null, sessionData };
}
