export enum IMPORT_STATUSES {
    PENDING = 'pending',
    PENDING_CANCELED = 'pending_canceled',
    STARTED = 'started',
    STARTED_CANCELED = 'started_canceled',
    FINISHED = 'finished',
}

export const IMPORT_STATUSES_TRANSLATION_MAP = {
    [IMPORT_STATUSES.PENDING]: 'pending',
    [IMPORT_STATUSES.PENDING_CANCELED]: 'canceled',
    [IMPORT_STATUSES.STARTED]: 'in_progress',
    [IMPORT_STATUSES.STARTED_CANCELED]: 'canceled',
    [IMPORT_STATUSES.FINISHED]: 'finished',
};
