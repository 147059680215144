import { useState } from 'react';

const useOpenFolders = (initialExpandedFolders: string[] = []) => {
    const [openedFolders, setOpenedFolders] = useState<string[]>(
        initialExpandedFolders
    );

    const openFolder = (folderId: string) => {
        const folderIsOpened = openedFolders.includes(folderId);
        if (folderIsOpened) {
            setOpenedFolders((prev) => prev.filter((id) => id !== folderId));
        } else {
            setOpenedFolders((prev) => [...prev, folderId]);
        }
    };

    return {
        openedFolders,
        openFolder,
        setOpenedFolders,
        resetOpenedFolders: () => setOpenedFolders([]),
    };
};

export default useOpenFolders;
