export const fixedColumnsMetadata = {
    source: {
        label: 'Source',
        key: 'document_name',
        i8n: 'source',
    },
    page: {
        label: 'Page',
        key: 'page_number',
        i8n: 'page',
    },
    snippet: {
        label: 'Quoted text',
        key: 'text',
        i8n: 'quoted_text',
    },
    topic: {
        label: 'Topic',
        key: 'topic_name',
        i8n: 'topic',
    },
    tag: {
        label: 'Tags',
        key: 'document_tag_name',
        i8n: 'tags',
    },
    topic_location: {
        label: 'Topic location',
        key: 'topic_location',
        i8n: 'topic_location',
    },
} as const;

export const mappedKeysToRows = {
    [fixedColumnsMetadata.tag.key]: 'document_tags',
    [fixedColumnsMetadata.source.key]: 'document',
} as const;

const FIXED_COLUMN_NAMES = [...Object.keys(fixedColumnsMetadata), 'project'];

export const defaultColumnsId = {
    ADD_NEW_COLUMN: 'dg-table-new-col',
    CHECKBOX_COLUMN: 'dg-table-checkbox',
    TOPIC_LOCATION: 'topic_location',
};

export default FIXED_COLUMN_NAMES;
