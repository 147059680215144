import styles from './MenuItem.module.scss';
import classNames from 'classnames';
import BasePopover from '~/components/UIElements/BasePopover';
import BasicMenuList from '~/components/UIElements/BasicMenuList';
import { Icon } from 'react-feather';
import { useFloatMenu } from '~/hooks';

export interface BasicMenuItemInterface {
    label: string;
    onClick: () => void;
    isSeparator?: boolean;
    icon?: Icon;
    iconAfter?: Icon;
    variant?: 'default' | 'distructive';
    children?: BasicMenuItemInterface[];
    childrenMenuClassName?: string;
    suffixLegend?: string;
}
const MenuItem = ({ item }: { item: BasicMenuItemInterface }) => {
    const childrenMenu = useFloatMenu();

    const onClickItem = (e: React.MouseEvent) => {
        if (item.children) {
            childrenMenu.setShowMenu(true);
        } else {
            e.stopPropagation();
            item.onClick();
        }
    };

    const includeSuffixElement = item.suffixLegend || item.iconAfter;

    if (item.isSeparator) return <div className={styles.cgMenuItemSeparator} />;

    const itemVariant = item.variant || 'default';

    return (
        <BasePopover
            isOpen={childrenMenu.showMenu}
            onClickOutside={childrenMenu.handleMenu}
            content={
                <BasicMenuList
                    className={item.childrenMenuClassName}
                    menus={item.children || []}
                />
            }
            align="start"
            positions={['right']}
        >
            <div
                className={classNames(
                    styles.cgMenuItem,
                    styles[`cgMenuItem__${itemVariant}`]
                )}
                onClick={onClickItem}
            >
                <span className={styles.cgMenuItem__content}>
                    {item.icon && <item.icon />}
                    {item.label}
                </span>

                {includeSuffixElement && (
                    <div className={styles.cgMenuItem__suffix}>
                        {item.suffixLegend && (
                            <small>{item.suffixLegend}</small>
                        )}
                        {item.iconAfter && <item.iconAfter />}
                    </div>
                )}
            </div>
        </BasePopover>
    );
};

export default MenuItem;
