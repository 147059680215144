import { useState, useEffect } from 'react';
import './index.scss';

interface SwitchInputProps {
    isOn: boolean;
    handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id: string;
    disabled?: boolean;
}

const SwitchInput: React.FC<SwitchInputProps> = ({
    isOn,
    handleToggle,
    id,
    disabled,
}) => {
    const [checked, setChecked] = useState(isOn);

    useEffect(() => {
        setChecked(isOn);
    }, [isOn]);

    return (
        <div
            className={`cg-switch-input ${
                disabled ? 'cg-switch-input__disabled' : ''
            }`}
        >
            <input
                checked={checked}
                onChange={(e) => {
                    setChecked(!checked);
                    handleToggle(e);
                }}
                className="react-switch-checkbox"
                id={id}
                type="checkbox"
                disabled={disabled}
            />
            <label className="react-switch-label" htmlFor={id}>
                <span className={`react-switch-button`} />
            </label>
        </div>
    );
};

export default SwitchInput;
