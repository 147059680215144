import { RefObject } from 'react';
import { useIntl } from 'react-intl';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import { useEntityContext } from '~/hooks';
import { TagsContext } from '~/context/tags';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import BasePopover from '~/components/UIElements/BasePopover';
import BaseFloatMenu from '~/components/UIElements/BaseFloatMenu';
import BasicFieldForm from '~/components/UIElements/BasicFieldForm';

import styles from './Dialogs.module.scss';

interface CreateTagDialogProps {
    createTagMenu: FloatMenuInterface;
    cardRef: RefObject<HTMLDivElement>;
    onCreateTag: (tagName: string) => void;
}

const CreateTagDialog = ({
    createTagMenu,
    cardRef,
    onCreateTag,
}: CreateTagDialogProps) => {
    const intl = useIntl();

    const { loading: loadingTags } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    if (!cardRef.current) return;

    return (
        <BasePopover
            isOpen={createTagMenu.showMenu}
            positions={['right']}
            align="start"
            onClickOutside={createTagMenu.handleMenu}
            parentContainer={cardRef.current}
            content={
                <BaseFloatMenu
                    className={styles.cgDialogFolder}
                    animated={false}
                >
                    <BasicFieldForm
                        confirmText={intl.formatMessage({
                            id: 'create',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'tag_name',
                        })}
                        intl={intl}
                        loading={loadingTags}
                        onSubmit={onCreateTag}
                        label={intl.formatMessage({
                            id: 'create_tag',
                        })}
                    />
                </BaseFloatMenu>
            }
        >
            <span></span>
        </BasePopover>
    );
};

export default CreateTagDialog;
