import { create } from 'zustand';
import DocumentViewerStoreInterface from '../types/documentViewerStore';

const useDocumentViewerStore = create<DocumentViewerStoreInterface>()(
    (set) => ({
        loading: false,

        viewerWidth: 650,
        showViewer: true,
        documentMetadata: null,
        documentDisplayData: null,
        handleShowViewer: (show) =>
            set({
                showViewer: show,
            }),
        onClose: () =>
            set({
                showViewer: false,
            }),
        resetViewer: () =>
            set({
                documentMetadata: null,
                documentDisplayData: null,
            }),
        handleViewerWidth: (width: number) =>
            set({
                viewerWidth: width,
            }),
        handleLoading: (loading: boolean) =>
            set({
                loading,
            }),
        handleDocumentDisplayData: (data) =>
            set({
                documentDisplayData: data,
            }),
        handleDocumentMetadata: (metadata) =>
            set({
                documentMetadata: metadata,
            }),
    })
);

export default useDocumentViewerStore;
