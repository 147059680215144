import { useCallback, useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
    EditableCell,
    CustomColumnHeader,
    TagCell,
    QuoteCell,
    MultiSelectCell,
} from '../components/Tables';
import {
    ColumnFilter,
    SwitchInput,
    TagsColumnFilter,
} from '../components/UIElements';
import {
    fixedColumnsMetadata,
    defaultColumnsId,
} from '../constants/insightTableColumns';
import { XCircle } from 'react-feather';
import { getErrorCode } from '../utils/getErrorCode';
import { updateColumnsOrder } from '../utils/orderColumnsFunctions';
import PermissionContext from '../context/permissions/PermissionContext';
import { ENTITIES } from '../constants/entities';
import { PERMISSIONS } from '../constants/memberPermissions';
import { StaticCellTypes } from '../constants/StaticCellTypes';
import {
    DynamicInsightValueDetailedInterface,
    DynamicPropertyInterfaceDetailed,
    FilledFolderTreeInterface,
    InsightInterfaceDetailed,
} from '~/interfaces/entities';
import { CellItemsPayloadInterface } from '~/components/Tables/MultiSelectCell';
import { commentsServices } from '../services';
import { normalizeInsightCell } from '~/helpers/normalizeDynamicInsightCell';
import { useEntityContext } from '~/hooks';
import { ImageInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TagsContext } from '~/context/tags';
import { DYNAMIC_PROPERTY_TYPES } from '~/constants/DynamicPropertyTypes.enum';
import { NewDynamicColumnInterface } from '~/components/Tables/AddColumnHeader';
import { useIntl } from 'react-intl';
import { useColumnsFilterContextInterface } from '~/hooks/useColumnsFilterContext';
import TopicColumnFilter from '~/components/UIElements/TopicColumnFilter';
import TopicLocationCell from '~/modules/Topics/components/TopicLocationCell';
import TopicCell from '~/modules/Topics/components/TopicCell';
import useDocumentViewerStore from '~/modules/Documents/stores/documentViewerStore';
import useDocumentViewer from '~/modules/Documents/hooks/useDocumentViewer';

interface CellDataInterface {
    data: DynamicInsightValueDetailedInterface;
    value: null | string | number | boolean;
}

interface UpdateCellDTO {
    rowData: InsightInterfaceDetailed;
    colId: string;
    cellType: DYNAMIC_PROPERTY_TYPES;
    newValue: string | number | boolean | null;
}

interface hookOptionsInterface {
    selectedProjectId: string;
    FIXED_COLUMN_NAMES: string[];
    results: InsightInterfaceDetailed[];
    allowEdit: boolean;
    allowCreation?: boolean;
    filtering: Pick<
        useColumnsFilterContextInterface,
        | 'columnFilters'
        | 'columnValues'
        | 'sortParams'
        | 'onApplyFilter'
        | 'resetFilterColumn'
    >;
    folderStructure: FilledFolderTreeInterface[];
    loadingScreenshots: boolean;
    loading: boolean;
    columnsMetadata: DynamicPropertyInterfaceDetailed[];
    setLoading: (loading: boolean) => void;
    getTableColumns: () => void;
    onUpdateTopic: () => void;
    onUpdateCellCallback: () => void;
    handleUpdateCell: (insights: InsightInterfaceDetailed[]) => void;
    handleViewImage: (payload: ImageInterface | null) => void;
    setColumnsMetadata: (columns: DynamicPropertyInterfaceDetailed[]) => void;
    onUpdateItems: () => void;
}

const useDynamicInsightColumns = ({
    selectedProjectId,
    FIXED_COLUMN_NAMES,
    results = [],
    allowCreation = true,
    filtering: {
        columnFilters,
        columnValues = {},
        sortParams,
        onApplyFilter,
        resetFilterColumn,
    },
    folderStructure,
    allowEdit = true,
    loadingScreenshots,
    loading,
    columnsMetadata,
    handleUpdateCell,
    setLoading,
    onUpdateTopic,
    onUpdateCellCallback,
    getTableColumns,
    handleViewImage,
    setColumnsMetadata,
    onUpdateItems,
}: hookOptionsInterface) => {
    const intl = useIntl();

    const [showTopicLocation, setShowTopicLocation] = useState(false);
    const { isAllowedTo } = useContext(PermissionContext);
    const { tags, folderTagsColors } =
        useEntityContext<TagsProviderInterface>(TagsContext);

    // the building of the columns is managed by useMemo and useCallbacks hooks which take some miliseconds to update
    const delayHandleLoading = () => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const canEdit = useMemo(
        () => isAllowedTo(ENTITIES.INSIGHTS, PERMISSIONS.EDIT),
        [isAllowedTo]
    );

    const { handleViewDocument } = useDocumentViewer();

    const documentMetadata = useDocumentViewerStore(
        (state) => state.documentMetadata
    );

    function onOpenFile(insight: InsightInterfaceDetailed) {
        const payload = {
            initialPage: insight.page_number,
            id: insight.document.id,
            text: '',
        };

        handleViewDocument(payload);
    }

    const model = {
        snippet: {
            header: intl.formatMessage({
                id: fixedColumnsMetadata.snippet.i8n,
            }),
            cell: (rowData: InsightInterfaceDetailed) => (
                <QuoteCell
                    cell={rowData}
                    loading={loadingScreenshots}
                    onClick={(e) => {
                        if (!window.getSelection()?.toString())
                            onOpenFile(rowData); // allow text selection before opening the file
                        e.stopPropagation();
                    }}
                    onOpenImage={(img) => handleViewImage({ blob: img })}
                />
            ),
            id: '99',
            field: fixedColumnsMetadata.snippet.key,
            style: { minWidth: '300px' },
        },
        source: {
            header: () => (
                <ColumnFilter
                    headerName={fixedColumnsMetadata.source.i8n}
                    intl={intl}
                    columnName={fixedColumnsMetadata.source.key}
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[fixedColumnsMetadata.source.key]}
                    sortParams={sortParams}
                />
            ),
            cell: (rowData: InsightInterfaceDetailed) => (
                <div
                    title={rowData.document.name}
                    className="capture-cell"
                    onClick={() => onOpenFile(rowData)}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <p>{rowData.document?.name}</p>
                </div>
            ),
            id: '4',
            field: fixedColumnsMetadata.source.key,
            headerStyle: { minWidth: '200px' },
        },
        page: {
            header: () => (
                <ColumnFilter
                    headerName={fixedColumnsMetadata.page.i8n}
                    intl={intl}
                    columnName={fixedColumnsMetadata.page.key}
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[fixedColumnsMetadata.page.key]}
                    sortParams={sortParams}
                />
            ),
            cell: (rowData: InsightInterfaceDetailed) => (
                <div
                    onClick={() => onOpenFile(rowData)}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    {rowData.page_number}
                </div>
            ),
            field: fixedColumnsMetadata.page.key,
            id: '5',
        },
        topic: {
            header: () => (
                <TopicColumnFilter
                    headerName={fixedColumnsMetadata.topic.i8n}
                    intl={intl}
                    columnName={fixedColumnsMetadata.topic.key}
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[fixedColumnsMetadata.topic.key]}
                    showTopicLocationIcon={!showTopicLocation}
                    onToggleTopicLocation={() =>
                        setShowTopicLocation(!showTopicLocation)
                    }
                />
            ),
            cell: (rowData: InsightInterfaceDetailed) => (
                <TopicCell
                    intl={intl}
                    insight={rowData}
                    onUpdate={onUpdateTopic}
                    folderStructure={folderStructure}
                    disabled={!allowEdit}
                />
            ),
            field: fixedColumnsMetadata.topic.key,
            id: '6',
            style: { minWidth: '200px' },
        },
        topic_location: {
            header: () => (
                <div className="dg-topic-location-header">
                    <span>
                        {intl.formatMessage({
                            id: fixedColumnsMetadata.topic_location.i8n,
                        })}
                    </span>

                    <XCircle onClick={() => setShowTopicLocation(false)} />
                </div>
            ),
            cell: (rowData: InsightInterfaceDetailed) => (
                <TopicLocationCell
                    topic={rowData.topic}
                    disabled={!allowEdit}
                    folderStructure={folderStructure}
                    onUpdate={onUpdateTopic}
                />
            ),
            field: fixedColumnsMetadata.topic_location.key,
            id: '7',
            style: { minWidth: '200px' },
            reorderable: false,
            hidden: !showTopicLocation,
        },
        tag: {
            header: () => (
                <TagsColumnFilter
                    headerName={fixedColumnsMetadata.tag.i8n}
                    intl={intl}
                    columnName={fixedColumnsMetadata.tag.key}
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[fixedColumnsMetadata.tag.key]}
                    sortParams={sortParams}
                />
            ),
            cell: (rowData: InsightInterfaceDetailed) => (
                <TagCell
                    documentId={rowData.document?.id}
                    selectedTags={rowData.document_tags}
                    disabled={!allowEdit}
                    onOpenFile={() => onOpenFile(rowData)}
                    folderTagsColors={folderTagsColors}
                    renderTree
                />
            ),
            id: '8',
            field: fixedColumnsMetadata.tag.key,
            style: { minWidth: '300px' },
        },
    };

    // order the columns based on the order property
    const orderedColumns = useMemo((): DynamicPropertyInterfaceDetailed[] => {
        if (!columnsMetadata.length) return [];

        const ordered = [...columnsMetadata].sort(
            (a, b) => a.position - b.position
        );

        // get the topic column index and add the topic location column just next to it
        const topicColumnIndex = ordered.findIndex(
            (c) => c.type === StaticCellTypes.topic
        );

        if (topicColumnIndex !== -1) {
            ordered.splice(topicColumnIndex + 1, 0, {
                id: defaultColumnsId.TOPIC_LOCATION,
                name: defaultColumnsId.TOPIC_LOCATION,
                type: defaultColumnsId.TOPIC_LOCATION,
                position: -100, // the position is not important because this column is inserted always after the topic column
            });
        }

        return ordered;
    }, [columnsMetadata]);

    const onRemoveColumn = async (columnId: string) => {
        setLoading(true);

        const [error, response] = await commentsServices.deleteColumn(columnId);

        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        toast.success(
            intl.formatMessage({ id: 'column_removed_successfully' })
        );

        // update the order of the columns.
        // update directly the state (without getting all the columns again) in order to avoid unnecessary re-renders and flickering
        const newOrder = orderedColumns
            .filter((c) => c.id !== columnId)
            .map((c, idx) => ({
                ...c,
                position: idx,
            }));

        setColumnsMetadata(newOrder);
    };

    const onUpdateColumnName = async ({
        name,
        id,
    }: {
        name: string;
        id: string;
    }) => {
        setLoading(true);

        const [error] = await commentsServices.updateColumn(id, {
            name,
        });

        setLoading(false);

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        const newArray = columnsMetadata.map(
            (c: DynamicPropertyInterfaceDetailed) => {
                if (c.id === id) {
                    c.name = name;
                }
                return c;
            }
        );

        setColumnsMetadata(newArray);
    };

    const getCell = useCallback(
        (
            rowData: InsightInterfaceDetailed,
            columnId: string
        ): CellDataInterface | null => {
            const cell = rowData.comment_dynamic_values.find(
                (c) => c.comment_dynamic_property_id === columnId
            );

            if (!cell) return null;

            // the value should be the first one that is not null
            const value = [
                cell.value_text,
                cell.value_number,
                cell.value_boolean,
            ].find((v) => v !== null);

            return {
                data: cell,
                // if all values are null, return null
                value: value === undefined ? null : value,
            };
        },
        []
    );

    const onUpdateCell = async (cellInfo: UpdateCellDTO): Promise<void> => {
        const { rowData, colId, cellType, newValue } = cellInfo;

        if (newValue === undefined || !rowData) return;

        const cell = getCell(rowData, colId);

        if (!cell && newValue === null) return;

        // if value didn't change, do nothing
        if (cell?.value === newValue) return;

        // store the changes in DB
        const valuePayload = {
            value_number:
                cellType === DYNAMIC_PROPERTY_TYPES.NUMBER
                    ? (newValue as number)
                    : null,
            value_text:
                cellType === DYNAMIC_PROPERTY_TYPES.TEXT
                    ? (newValue as string)
                    : null,
            value_boolean:
                cellType === DYNAMIC_PROPERTY_TYPES.BOOLEAN
                    ? (newValue as boolean)
                    : null,
        };
        // check if the cell already exist, if so, update it, otherwise create a new one
        const [error, response] = cell
            ? await commentsServices.updateDynamicValue(
                  cell?.data.id,
                  valuePayload
              )
            : await commentsServices.addDynamicValue({
                  comment_id: rowData.id,
                  comment_dynamic_property_id: colId,
                  ...valuePayload,
              });

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );
            return;
        }

        const { data } = response;
        // update the internal state (without requesting all the data again and lose the scroll position)
        if (cell) {
            rowData.comment_dynamic_values = rowData.comment_dynamic_values.map(
                (c) => {
                    if (c.comment_dynamic_property_id === colId) {
                        c = { ...c, ...data };
                    }
                    return c;
                }
            );
        } else {
            rowData.comment_dynamic_values.push({
                ...data,
                comment_select_options: [],
            });
        }

        // update results array
        const newResults = results.map((r: InsightInterfaceDetailed) => {
            if (r.id === rowData.id) {
                return rowData;
            }
            return r;
        });
        handleUpdateCell(newResults);
        onUpdateCellCallback();
    };

    // for multi select and single select cells
    const onAddCellItems = async (
        payload: CellItemsPayloadInterface,
        callback: () => void,
        commentId: string,
        columnId: string
    ) => {
        let cellId: string = payload.cellId;

        setLoading(true);
        // if the cell needs to be created (it doesn't exist yet)
        if (payload.createCell) {
            const [error, response] = await commentsServices.addDynamicValue({
                comment_id: commentId,
                comment_dynamic_property_id: columnId,
            });

            if (error) {
                toast.error(
                    intl.formatMessage({
                        id: getErrorCode(response.error_code),
                    })
                );

                delayHandleLoading();

                return;
            }

            cellId = response.data.id;
        }

        const itemsToAdd = payload.itemsToAdd ?? [];

        if (payload.itemsToCreate?.length) {
            const itemPromises = payload.itemsToCreate.map((t) =>
                commentsServices.createSelectOption({
                    comment_dynamic_property_id: columnId,
                    name: t,
                })
            );

            const response = await Promise.all(itemPromises);
            if (response?.length) {
                response.forEach(
                    ([error, response]) =>
                        !error && itemsToAdd.push(response.data.id)
                );
            }
        }

        const updatedPayload = {
            add_select_option_ids: itemsToAdd,
            remove_select_option_ids: payload.itemsToRemove || [],
        };

        const [error] = await commentsServices.updateCellItems(
            cellId,
            updatedPayload
        );

        delayHandleLoading();

        if (error)
            return toast.error(intl.formatMessage({ id: 'general_error' }));

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));

        callback();
        getTableColumns();
        onUpdateItems();
    };

    const onUpdateCellItem = async (itemId: string, newLabel: string) => {
        setLoading(true);

        const [error, response] = await commentsServices.updateSelectOption(
            itemId,
            {
                name: newLabel,
            }
        );

        delayHandleLoading();

        if (error)
            return toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));
        getTableColumns();
        onUpdateItems();
    };

    const onDeleteCellItem = async (itemId: string) => {
        setLoading(true);

        const [error, response] =
            await commentsServices.deleteSelectOption(itemId);

        delayHandleLoading();

        if (error)
            return toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );

        toast.success(intl.formatMessage({ id: 'items_updated_successfully' }));
        getTableColumns();
    };

    const getCustomCell = (col: DynamicPropertyInterfaceDetailed) => {
        const payload = {
            header: (
                <CustomColumnHeader
                    intl={intl}
                    column={col}
                    onRemove={() => onRemoveColumn(col.id)}
                    onEdit={onUpdateColumnName}
                    canEdit={canEdit}
                    resetFilterColumn={resetFilterColumn}
                    columnFilters={columnFilters}
                    onApplyFilter={onApplyFilter}
                    columnValues={columnValues[col?.id] || []}
                    sortParams={sortParams}
                    sortFieldName={col?.id}
                />
            ),
            field: col.id,
            id: col.id,
            style: { minWidth: '180px' },
        };

        let cell = {};

        if (
            col.type === DYNAMIC_PROPERTY_TYPES.TEXT ||
            col.type === DYNAMIC_PROPERTY_TYPES.NUMBER
        ) {
            cell = {
                cell: (rowData: InsightInterfaceDetailed) => (
                    <EditableCell
                        value={getCell(rowData, col.id)?.value}
                        type={col.type}
                        allowEdit={false}
                    />
                ),
                editor: ({ rowIndex }: { rowIndex: number }) => {
                    const row = results[rowIndex];
                    const inputValue = getCell(row, col.id)?.value;

                    return (
                        <EditableCell
                            value={inputValue}
                            type={col.type}
                            allowEdit={allowEdit && canEdit}
                            onCompleteEdit={(newValue: string | number) =>
                                onUpdateCell({
                                    rowData: row,
                                    colId: col.id,
                                    cellType: col.type,
                                    newValue: newValue === '' ? null : newValue,
                                })
                            }
                        />
                    );
                },
            };
        }

        if (col.type === DYNAMIC_PROPERTY_TYPES.BOOLEAN) {
            cell = {
                cell: (rowData: InsightInterfaceDetailed) => (
                    <SwitchInput
                        disabled={!allowEdit}
                        isOn={Boolean(getCell(rowData, col.id)?.value)}
                        id={col.id + rowData.id}
                        handleToggle={(e) => {
                            if (!canEdit) return;
                            onUpdateCell({
                                rowData,
                                colId: col.id,
                                cellType: col.type,
                                newValue: e.target.checked,
                            });
                        }}
                    />
                ),
                align: 'center',
            };
        }

        if (
            col.type === DYNAMIC_PROPERTY_TYPES.MULTI_SELECT ||
            col.type === DYNAMIC_PROPERTY_TYPES.SELECT
        ) {
            cell = {
                cell: (rowData: InsightInterfaceDetailed) => (
                    <MultiSelectCell
                        intl={intl}
                        columnItems={col.comment_select_options}
                        disabled={!allowEdit || !canEdit}
                        onApply={(items, callback) =>
                            onAddCellItems(items, callback, rowData.id, col.id)
                        }
                        onUpdateItems={onUpdateCellItem}
                        onDeleteCellItems={onDeleteCellItem}
                        cell={normalizeInsightCell(
                            getCell(rowData, col.id)?.data
                        )}
                        loading={loading}
                        singleItem={col.type === DYNAMIC_PROPERTY_TYPES.SELECT}
                    />
                ),
            };
        }

        return {
            ...payload,
            ...cell,
        };
    };

    const tableColumns = useMemo(() => {
        if (!orderedColumns.length) return [];

        const columnsToRender = orderedColumns.map((col) => {
            // check if the column is fixed (default one) or not
            const isFixed = FIXED_COLUMN_NAMES.includes(col.type);
            const toAdd = isFixed
                ? { ...model[col.name], id: col.id }
                : getCustomCell(col);
            return toAdd;
        });

        // if the user is allowed to create new columns, add the last column (with a plus icon)
        if (allowCreation && canEdit)
            columnsToRender.push({ id: defaultColumnsId.ADD_NEW_COLUMN });

        return columnsToRender;
    }, [
        results,
        columnsMetadata,
        selectedProjectId,
        columnValues,
        sortParams,
        showTopicLocation,
        folderStructure,
        loadingScreenshots,
        loading,
        tags,
        documentMetadata,
    ]);

    const onAddNewColumn = async (column: NewDynamicColumnInterface) => {
        setLoading(true);
        const payload = {
            project_id: selectedProjectId,
            name: column.name,
            type: column.type,
        };

        const [error, response] = await commentsServices.addColumn(payload);
        setLoading(false);

        if (error)
            return toast.error(
                intl.formatMessage({ id: getErrorCode(response.error_code) })
            );

        const { data } = response;
        setColumnsMetadata([
            ...columnsMetadata,
            { ...data, comment_select_options: [] },
        ]);
    };

    const handleColumnsOrder = async (
        columnToMoveId: string,
        newPosition: number
    ) => {
        const { newColumns } = updateColumnsOrder(
            orderedColumns,
            columnToMoveId,
            newPosition
        );

        const columnsWithoutLocation = newColumns.filter(
            (c) => c.id !== defaultColumnsId.TOPIC_LOCATION
        );

        // to get the exact position of the column, without taking into account the location column, we need to get it from the index
        const position = columnsWithoutLocation.findIndex(
            (c) => c.id === columnToMoveId
        );

        const [error, response] = await commentsServices.updateColumn(
            columnToMoveId,
            {
                position,
            }
        );

        if (error || !response.data) return;

        setColumnsMetadata(columnsWithoutLocation);
    };

    return {
        orderedColumns,
        tableColumns,
        onAddNewColumn,
        handleColumnsOrder,
    };
};

export default useDynamicInsightColumns;
