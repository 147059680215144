import { BaseModal, IconButton, Spinner } from '~/components/UIElements';
import './index.scss';
import { Download, X, ZoomIn, ZoomOut } from 'react-feather';
import toast from 'react-hot-toast';
import { IntlShape } from 'react-intl';
import {
    TransformWrapper,
    TransformComponent,
    useControls,
} from 'react-zoom-pan-pinch';
import triggerSaveFilePrompt from '~/utils/triggerSaveFilePrompt';

interface ImageViewerModalInterface {
    img: Blob;
    loading?: boolean;
    onClose: () => void;
    intl: IntlShape;
    hideDownload?: boolean;
}

const mainClass = 'image-viewer-modal';

const ImageViewerModal: React.FC<ImageViewerModalInterface> = ({
    img,
    onClose,
    loading,
    intl,
    hideDownload,
}) => {
    const imageUrl = URL.createObjectURL(img);

    const onDownload = async () => {
        const { triggerSuccessToast } = await triggerSaveFilePrompt(
            img,
            'cogny-screenshot'
        );

        if (triggerSuccessToast) {
            toast.success(
                intl.formatMessage({ id: 'image_downloaded_succesfully' })
            );
        }
    };

    const Controls = ({ hideDownload }) => {
        const { zoomIn, zoomOut } = useControls();

        return (
            <div className={mainClass + '__actions'}>
                <IconButton
                    onClick={() => zoomOut()}
                    variant="tertiary"
                    icon={ZoomOut}
                />
                <IconButton
                    onClick={() => zoomIn()}
                    variant="tertiary"
                    icon={ZoomIn}
                />
                {!hideDownload && (
                    <>
                        <div className={mainClass + '__actions__spacer'} />
                        <IconButton
                            onClick={onDownload}
                            variant="tertiary"
                            icon={Download}
                        />
                    </>
                )}
                <div className={mainClass + '__actions__spacer'} />
                <IconButton onClick={onClose} variant="tertiary" icon={X} />
            </div>
        );
    };

    return (
        <BaseModal handleClose={onClose} size="flex">
            <div className={mainClass}>
                {loading ? (
                    <div className={mainClass + '__loading'}>
                        <Spinner width={10} height={10} />
                    </div>
                ) : (
                    <>
                        <TransformWrapper maxScale={3}>
                            <TransformComponent>
                                <img src={imageUrl} alt="image" />
                            </TransformComponent>
                            <Controls hideDownload={hideDownload} />
                        </TransformWrapper>
                    </>
                )}
            </div>
        </BaseModal>
    );
};

export default ImageViewerModal;
