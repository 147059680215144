import { GroupedTags } from '~/helpers/groupTagsByFolder';
import { TagGroupInterfaceWithItems } from '~/interfaces/entities';
import { UncategorizedTagsGroup } from '../interfaces/entities/TagGroup.interface';
import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';

type TagGroupInterfaceWithLabelledItems = Omit<
    TagGroupInterfaceWithItems,
    'items'
> & { items: ColumnValueOption[] };

type UncategorizedTagsGroupWithLabelledItems = Omit<
    UncategorizedTagsGroup,
    'items'
> & { items: ColumnValueOption[] };

export type GroupedTagsWithLabelledItems = (
    | TagGroupInterfaceWithLabelledItems
    | UncategorizedTagsGroupWithLabelledItems
)[];

const transformTagGroupWithItems = (folders: GroupedTags = []) => {
    if (!folders) return undefined;

    const transformed: GroupedTagsWithLabelledItems[] = folders.map((f) => ({
        ...f,
        items: f.items.map((i) => ({ value: i.id, label: i.name })),
    }));

    return transformed;
};

export default transformTagGroupWithItems;
