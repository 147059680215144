import styles from './BasicMenuList.module.scss';
import classNames from 'classnames';
import MenuItem, {
    BasicMenuItemInterface,
} from '~/components/UIElements/BasicMenuList/MenuItem';

interface BasicMenuListInterface {
    menus: BasicMenuItemInterface[];
    legend?: string;
    className?: string;
}

const BasicMenuList: React.FC<BasicMenuListInterface> = ({
    menus,
    legend,
    className,
}) => {
    return (
        <div
            className={`${classNames(className, styles.cgBasicMenuList)} animate__animated animate__fadeIn animate__faster`}
        >
            {legend && <small className={styles.legend}> {legend} </small>}
            {menus.map((menuItem, idx) => (
                <MenuItem key={idx} item={menuItem} />
            ))}
        </div>
    );
};

export default BasicMenuList;
