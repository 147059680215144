import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Edit3, FolderPlus, Icon, Plus, Trash2 } from 'react-feather';
import { FolderItemTypes, FOLDER_ACTION } from '~/constants/folders';
import { useFloatMenu } from '~/hooks';

interface UseFolderMenuProps {
    folderItemsType?: FolderItemTypes;
    showActions?: boolean;
}

interface ActionMenuProps {
    id: string;
    onClick: () => void;
    icon: Icon;
    key: string;
}

const useItemMenu = ({ folderItemsType, showActions }: UseFolderMenuProps) => {
    const intl = useIntl();

    const renameMenu = useFloatMenu();
    const actionsMenu = useFloatMenu();
    const deleteMenu = useFloatMenu();

    const createSubfolderMenu = useFloatMenu();
    const createTagMenu = useFloatMenu();
    const createTopicMenu = useFloatMenu();

    const ACTION_MENUS = useMemo(() => {
        if (!showActions) return [];

        const createAction = ({ id, onClick, icon, key }: ActionMenuProps) => ({
            label: intl.formatMessage({ id }),
            onClick: () => {
                onClick();
                actionsMenu.setShowMenu(false);
            },
            icon,
            key,
        });

        const baseActions = [
            { isSeparator: true, label: '', onClick: () => {} },
            createAction({
                id: FOLDER_ACTION.rename.i8n,
                onClick: () => renameMenu.setShowMenu(true),
                icon: Edit3,
                key: FOLDER_ACTION.rename.key,
            }),
            createAction({
                id: FOLDER_ACTION.delete.i8n,
                onClick: () => deleteMenu.setShowMenu(true),
                icon: Trash2,
                key: FOLDER_ACTION.delete.key,
            }),
        ];

        const specificActions = {
            [FolderItemTypes.TAGS]: [
                createAction({
                    id: FOLDER_ACTION.createTag.i8n,
                    onClick: () => createTagMenu.setShowMenu(true),
                    icon: Plus,
                    key: FOLDER_ACTION.createTag.key,
                }),
            ],
            [FolderItemTypes.TOPICS]: [
                createAction({
                    id: FOLDER_ACTION.createTopic.i8n,
                    onClick: () => createTopicMenu.setShowMenu(true),
                    icon: Plus,
                    key: FOLDER_ACTION.createTopic.key,
                }),
                createAction({
                    id: FOLDER_ACTION.createSubfolder.i8n,
                    onClick: () => createSubfolderMenu.setShowMenu(true),
                    icon: FolderPlus,
                    key: FOLDER_ACTION.createSubfolder.key,
                }),
            ],
        };

        return folderItemsType
            ? [...specificActions[folderItemsType], ...baseActions]
            : baseActions;
    }, [showActions, folderItemsType]);

    const someMenuIsOpen = [
        renameMenu.showMenu,
        actionsMenu.showMenu,
        createSubfolderMenu.showMenu,
        createTagMenu.showMenu,
        createTopicMenu.showMenu,
    ].some(Boolean);

    return {
        ACTION_MENUS,
        someMenuIsOpen,
        renameMenu,
        actionsMenu,
        deleteMenu,
        createSubfolderMenu,
        createTopicMenu,
        createTagMenu,
    };
};

export default useItemMenu;
