import { PillVariants } from '~/components/UIElements/Pill/constants';

const availableColors = [
    PillVariants.TURQUOISE,
    PillVariants.ALIZARIN,
    PillVariants.CARROT,
    PillVariants.ELECTRIC,
    PillVariants.AMETHYST,
    PillVariants.SUNFLOWER,
    PillVariants.EMERALD,
    PillVariants.PETER,
    PillVariants.PINK,
];

export interface EntityColorMap {
    [key: string]: PillVariants;
}

interface EntityColorCountMap {
    [key: string]: number; // keys are PillVariants
}

export const assignColorsToEntities = (
    entityIds: string[],
    initialColors: EntityColorMap
): EntityColorMap => {
    const usedColors = Object.values(initialColors);
    const colorCounts: EntityColorCountMap = {}; // Object to keep track of color counts
    const assignedColors: EntityColorMap = {}; // Object to store assigned colors for each folder

    // initializing each default color
    for (const availableColor of availableColors) {
        colorCounts[availableColor] = 0;
    }

    // Initialize color counts from initialColors
    usedColors.forEach((color) => {
        colorCounts[color]++;
    });

    // Function to find the least repeated color
    function findLeastRepeatedColor() {
        const colorsAndFrequencies = Object.entries(colorCounts);
        const minimumCount = Math.min(...colorsAndFrequencies.map((c) => c[1]));
        const associatedColor = colorsAndFrequencies.findIndex(
            ([, count]) => count === minimumCount
        );

        return colorsAndFrequencies[associatedColor][0] as PillVariants;
    }

    // Assign colors to folders
    entityIds.forEach((folderId) => {
        if (initialColors[folderId]) {
            // Folder already has a color assigned
            assignedColors[folderId] = initialColors[folderId];
            colorCounts[initialColors[folderId]]++;
        } else {
            // Assign the least repeated color
            const leastRepeatedColor = findLeastRepeatedColor();
            assignedColors[folderId] = leastRepeatedColor;
            colorCounts[leastRepeatedColor]++;
        }
    });

    return assignedColors;
};
