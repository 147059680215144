import './index.scss';
import { RadioButton } from 'primereact/radiobutton';

const RadioButtonGroup = ({ label, options, onChange, checkedOption }) => {
    function renderOptions() {
        return options.map(({ label, name, disabled }) => {
            const shortenedOptionLabel = label.replace(/\s+/g, '');
            const optionId = `radio-option-${shortenedOptionLabel}`;

            return (
                <div key={optionId} className="cg-radio-group__item">
                    <RadioButton
                        value={label}
                        inputId={optionId}
                        name={name}
                        disabled={disabled}
                        checked={checkedOption === name}
                        onChange={onChange}
                    />
                    <label htmlFor={optionId}>{label}</label>
                </div>
            );
        });
    }

    return (
        <div className="cg-radio-group">
            <span className="cg-radio-group__legend">{label}</span>

            <div className="cg-radio-group__options">{renderOptions()}</div>
        </div>
    );
};

export default RadioButtonGroup;
