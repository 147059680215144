import './index.scss';
import TextInput, { TextInputInterface } from '../TextInput';
import { Eye, EyeOff, Icon } from 'react-feather';
import { useState } from 'react';

interface TextFieldInterface {
    hint?: string;
    error?: string | null;
    label?: string;
    className?: string;

    iconAfter?: Icon;
    iconBefore?: Icon;
    inputProps: TextInputInterface;
    isPassword?: boolean;
    isOptional?: boolean;
}

const TextField: React.FC<TextFieldInterface> = ({
    hint,
    label,
    error,
    className = '',
    iconAfter: IconAfter,
    iconBefore: IconBefore,
    inputProps = {},
    isPassword,
    isOptional,
    ...rest
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const inputContainerClasses = () => {
        let classes = 'dg-field-input ';

        if (IconAfter) classes += 'dg-field-input--icon-after ';
        if (IconBefore) classes += 'dg-field-input--icon-before ';
        if (error) classes += 'dg-field-input--error ';
        if (isPassword) classes += 'dg-field-input--password ';

        return classes;
    };

    return (
        <div className={`dg-text-field ${className} `} {...rest}>
            {label && (
                <div>
                    <label>{label}</label>
                    {isOptional && <span>Optional</span>}
                </div>
            )}
            <div className={inputContainerClasses()}>
                {IconBefore && <IconBefore />}

                <TextInput
                    error={error}
                    type={!isPassword || showPassword ? 'text' : 'password'}
                    {...inputProps}
                />

                {IconAfter && <IconAfter />}

                {isPassword && (
                    <>
                        {showPassword ? (
                            <EyeOff onClick={() => setShowPassword(false)} />
                        ) : (
                            <Eye onClick={() => setShowPassword(true)} />
                        )}
                    </>
                )}
            </div>
            {hint && <span className="dg-text-field-hint">{hint}</span>}
            {error && <span className="dg-text-field-error">{error}</span>}
        </div>
    );
};

export default TextField;
