import React from 'react';

function StarPlusIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            viewBox="0 0 16 16"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
        >
            <g
                fillRule="evenodd"
                clipPath="url(#clip0_2735_1834)"
                clipRule="evenodd"
            >
                <path d="M8 .653c.254 0 .486.144.598.372l1.905 3.859 4.26.619a.667.667 0 01.344 1.16l-1.334 1.171a.667.667 0 11-.88-1.002l.248-.217-3.177-.462a.667.667 0 01-.502-.365L8 2.826 6.538 5.788a.667.667 0 01-.502.365l-3.27.475 2.366 2.307a.667.667 0 01.192.59l-.53 3.09.784-.508a.667.667 0 11.724 1.12l-2.06 1.333a.667.667 0 01-1.019-.673l.727-4.242L.868 6.64a.667.667 0 01.37-1.137l4.26-.619 1.904-3.86A.667.667 0 018 .655z"></path>
                <path d="M11.333 8.667c.369 0 .667.298.667.666v5.334a.667.667 0 01-1.333 0V9.333c0-.368.298-.666.666-.666z"></path>
                <path d="M8 12c0-.368.299-.667.667-.667H14a.667.667 0 010 1.334H8.667A.667.667 0 018 12z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2735_1834">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default StarPlusIcon;
