import queryString from 'query-string';
import { environment } from '~/config/env';
import {
    PROJECT_MEMBER_INVITATION_ROLES,
    PROJECT_MEMBER_ROLES,
} from '~/constants/memberRoles';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import { FolderTreeInterface } from '~/interfaces/entities';
import {
    CopyProjectDTO,
    CreateProjectDTO,
    CreateProjectMemberInvitationDTO,
    ProjectInterface,
    ProjectInterfaceDetailed,
    ProjectMemberInterface,
    ProjectMemberInterfaceDetailed,
    ProjectMemberInvitationInterface,
    UpdateProjectDTO,
    UpdateProjectMemberDTO,
} from '~/modules/Projects/types/projects.interface';

const API_URL = environment.apiUrl + '/projects';

const projectsServices = {
    getProjects,
    createProject,
    deleteProject,
    updateProject,
    removeUserFromProject,
    getAllMembers,
    sendInvitation,
    updateMemberRole,
    getProjectInvitations,
    updateInvitationRole,
    deleteProjectInvitation,
    duplicateProject,
    getProjectFolderStructure,
};
export default projectsServices;

async function getProjects(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<PagedResponse<ProjectInterfaceDetailed>>(
        API_URL + '/detailed?' + queryString.stringify(options)
    );
}

async function deleteProject(projectId: string) {
    const url = API_URL + `/${projectId}?force=true`;
    return requestAdapterInstance.delete<null, null>(url);
}

async function updateProject(projectId: string, project: UpdateProjectDTO) {
    const url = API_URL + `/${projectId}`;
    return requestAdapterInstance.put<
        UnpagedResponse<ProjectInterface>,
        UpdateProjectDTO
    >(url, project);
}

async function createProject(project: CreateProjectDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<ProjectInterface>,
        CreateProjectDTO
    >(API_URL, project);
}

async function removeUserFromProject(projectId: string) {
    const url = environment.apiUrl + '/project_members/' + projectId;
    return requestAdapterInstance.delete<null, null>(url);
}

async function getAllMembers(projectId: string) {
    return requestAdapterInstance.get<
        PagedResponse<ProjectMemberInterfaceDetailed>
    >(
        `${environment.apiUrl}/project_members/detailed?page_size=1000&project_ids=${projectId}`
    );
}

async function getProjectInvitations(projectId: string) {
    return requestAdapterInstance.get<
        PagedResponse<ProjectMemberInvitationInterface>
    >(
        `${environment.apiUrl}/project_member_invitations?page_size=1000&project_ids=${projectId}`
    );
}

async function sendInvitation(member: CreateProjectMemberInvitationDTO) {
    const url = environment.apiUrl + '/project_member_invitations';
    return requestAdapterInstance.post<
        UnpagedResponse<ProjectMemberInvitationInterface>,
        CreateProjectMemberInvitationDTO
    >(url, member);
}

async function updateMemberRole(memberId: string, role: PROJECT_MEMBER_ROLES) {
    const url = environment.apiUrl + '/project_members/' + memberId;
    return requestAdapterInstance.put<
        UnpagedResponse<ProjectMemberInterface>,
        UpdateProjectMemberDTO
    >(url, { role });
}

async function updateInvitationRole(
    memberId: string,
    role: PROJECT_MEMBER_INVITATION_ROLES
) {
    const url = environment.apiUrl + '/project_member_invitations/' + memberId;
    return requestAdapterInstance.put<
        UnpagedResponse<ProjectMemberInvitationInterface>,
        UpdateProjectMemberInvitationDTO
    >(url, { role });
}

async function deleteProjectInvitation(memberId: string) {
    const url = environment.apiUrl + '/project_member_invitations/' + memberId;
    return requestAdapterInstance.delete<null, null>(url);
}

async function duplicateProject(projectId: string, project: CopyProjectDTO) {
    const url = `${environment.apiUrl}/projects/${projectId}/copy`;
    return requestAdapterInstance.post<
        UnpagedResponse<ProjectInterface>,
        CopyProjectDTO
    >(url, project);
}

async function getProjectFolderStructure(
    projectId: string,
    options: Record<string, unknown>
) {
    const url = `${
        environment.apiUrl
    }/projects/${projectId}/folder_tree?${queryString.stringify(options)}`;

    const response =
        await requestAdapterInstance.get<
            UnpagedResponse<FolderTreeInterface[]>
        >(url);

    return response;
}
