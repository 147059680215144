import { X } from 'react-feather';
import './SelectorItemPill.scss';
import React from 'react';
import { PillVariants } from '~/components/UIElements/Pill/constants';

const mainClass = 'cg-selector-item-pill';

interface SelectorItemPillProps {
    label: string;
    tooltip?: string;
    onRemove: () => void;
    variant?: PillVariants;
}

const SelectorItemPill: React.FC<SelectorItemPillProps> = ({
    label,
    onRemove,
    tooltip,
    variant,
}) => {
    const composedClass =
        `${mainClass}` + (variant ? ` ${mainClass}-${variant}` : '');
    return (
        <div className={composedClass} title={tooltip || label}>
            <span>{label}</span>
            <X
                onClick={(e) => {
                    e.stopPropagation();
                    onRemove();
                }}
            />
        </div>
    );
};

export default SelectorItemPill;
