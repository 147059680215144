import { WORKSPACE_STORAGE_KEY } from '~/modules/Workspaces/constants/workspaceStorage';

const checkLegacyWorkspaceStorage = (): void => {
    const selectedWorkspace = localStorage.getItem(WORKSPACE_STORAGE_KEY);

    // check if the 'selectedWorkspace' key in the localstorage is equal to an object. If it is, remove it.
    if (selectedWorkspace && selectedWorkspace[0] === '{') {
        localStorage.removeItem(WORKSPACE_STORAGE_KEY);
    }
};

export default checkLegacyWorkspaceStorage;
