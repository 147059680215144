import { PropsWithChildren, useContext } from 'react';
import { ENTITIES } from '~/constants/entities';
import { PERMISSIONS } from '~/constants/memberPermissions';
import PermissionContext from '~/context/permissions/PermissionContext';

interface RestrictedComponentInterface {
    entity: ENTITIES;
    to: PERMISSIONS;
}

// This component is meant to be used everywhere a restriction based on user permission is needed
const RestrictedUI: React.FC<
    PropsWithChildren<RestrictedComponentInterface>
> = ({ entity, to, children }) => {
    // We "connect" to the provider thanks to the PermissionContext
    const { isAllowedTo } = useContext(PermissionContext);
    // If the user has that permission, render the children
    if (isAllowedTo(entity, to)) {
        return <>{children}</>;
    }

    // Otherwise, do not render anything
    return null;
};

export default RestrictedUI;
