import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateWorkspaceDTO,
    CreateWorkspaceMemberInvitationDTO,
    CreateWorkspaceMemberInvitationResponseDTO,
    UpdateWorkspaceConfigurationDTO,
    UpdateWorkspaceDTO,
    UpdateWorkspaceMemberDTO,
    UpdateWorkspaceMemberInvitationDTO,
    WorkspaceConfigurationInterface,
    WorkspaceInterface,
    WorkspaceInterfaceDetailed,
    WorkspaceMemberInterface,
    WorkspaceMemberInterfaceDetailed,
    WorkspaceMemberInvitationInterface,
} from '~/interfaces/entities';

const mainUrl = environment.apiUrl;

// receiving the token by params is temp
const getWorkspaces = async (options: Record<string, unknown> = {}) => {
    const url =
        mainUrl + '/workspaces/detailed?' + queryString.stringify(options);

    const [error, response] =
        await requestAdapterInstance.get<
            PagedResponse<WorkspaceInterfaceDetailed>
        >(url);

    if (error) {
        return { error, data: [] };
    }
    return { ...response, error };
};

// receiving the token by params is temp
const createWorkspace = async (values: CreateWorkspaceDTO) => {
    const url = mainUrl + '/workspaces';
    return await requestAdapterInstance.post<
        UnpagedResponse<WorkspaceInterface>,
        CreateWorkspaceDTO
    >(url, values);
};

/**
 * Temporary function create a workspace when the user logins for the first time and has NO workspaces
 * (that is, they have not been invited to the app to another one's workspace),
 */
const getWorkspacesOnLogin = async (firstName: string) => {
    const { data: workspaces, error: wsError } = await getWorkspaces();

    if (!workspaces.length && !wsError) {
        // IF THERE AREN'T WS CREATED YET, CREATE ONE
        const [creationError, response] = await createWorkspace({
            name: `${firstName}'s workspace`,
        });

        if (!creationError) {
            return { workspaces: [response.data], workspaceError: wsError };
        }

        throw new Error('Could not create default workspace');
    }

    return { workspaces, workspaceError: wsError };
};

const updateWorkspaceSettings = async (
    id: string,
    settings: UpdateWorkspaceConfigurationDTO
) => {
    const url = mainUrl + '/workspace_configurations/' + id;

    return await requestAdapterInstance.put<
        UnpagedResponse<WorkspaceConfigurationInterface>,
        UpdateWorkspaceConfigurationDTO
    >(url, settings);
};

const updateWorkspace = async (id: string, workspace: UpdateWorkspaceDTO) => {
    const url = mainUrl + '/workspaces/' + id;

    return await requestAdapterInstance.put<
        UnpagedResponse<WorkspaceInterface>,
        UpdateWorkspaceDTO
    >(url, workspace);
};

const getMembers = async (options: Record<string, unknown> = {}) => {
    const url =
        mainUrl +
        '/workspace_members/detailed?' +
        queryString.stringify(options);

    return await requestAdapterInstance.get<
        PagedResponse<WorkspaceMemberInterfaceDetailed>
    >(url);
};

const getInvitations = async (options: Record<string, unknown> = {}) => {
    const url =
        mainUrl +
        '/workspace_member_invitations?' +
        queryString.stringify(options);

    return await requestAdapterInstance.get<
        PagedResponse<WorkspaceMemberInvitationInterface>
    >(url);
};

const getMembersAndInvitations = async (id: string) => {
    const [membersError, membersResponse] = await getMembers({
        workspace_ids: id,
    });
    const [invitationsError, invitationsResponse] = await getInvitations({
        workspace_ids: id,
    });

    return {
        members: !membersError ? membersResponse.data : [],
        invitations: !invitationsError ? invitationsResponse.data : [],
        error: membersError || invitationsError,
    };
};

const inviteMember = async (payload: CreateWorkspaceMemberInvitationDTO) => {
    return await requestAdapterInstance.post<
        UnpagedResponse<CreateWorkspaceMemberInvitationResponseDTO>,
        CreateWorkspaceMemberInvitationDTO
    >(mainUrl + '/workspace_member_invitations', payload);
};

const deleteMemberInvitation = async (id: string) => {
    const url = mainUrl + '/workspace_member_invitations/' + id;

    return await requestAdapterInstance.delete<null, null>(url);
};

const updateMemberInvitation = async (
    id: string,
    payload: UpdateWorkspaceMemberInvitationDTO
) => {
    const url = mainUrl + '/workspace_member_invitations/' + id;

    return await requestAdapterInstance.put<
        UnpagedResponse<WorkspaceMemberInvitationInterface>,
        UpdateWorkspaceMemberInvitationDTO
    >(url, payload);
};

const updateMember = async (id: string, payload: UpdateWorkspaceMemberDTO) => {
    const url = mainUrl + '/workspace_members/' + id;

    return await requestAdapterInstance.put<
        UnpagedResponse<WorkspaceMemberInterface>,
        UpdateWorkspaceMemberDTO
    >(url, payload);
};

const deleteMember = async (id: string) => {
    const url = mainUrl + '/workspace_members/' + id;

    return await requestAdapterInstance.delete<null, null>(url);
};

const workspaceServices = {
    getWorkspaces,
    getWorkspacesOnLogin,
    updateWorkspaceSettings,
    updateWorkspace,
    getMembersAndInvitations,
    inviteMember,
    deleteMemberInvitation,
    updateMemberInvitation,
    updateMember,
    deleteMember,
};

export default workspaceServices;
