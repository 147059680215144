import { useMemo } from 'react';
import { Popover } from 'react-tiny-popover';
import { useFloatMenu } from '../../../hooks';
import TagsPicker from '../TagsPicker';
import { compareStringsAlphabetically } from '../../../utils/orderStringAlphabetically';
import Pill from '../Pill';
import './index.scss';

const ItemSelectorField = ({
    intl,
    options = [],
    onChange,
    colName,
    value,
    singleItem,
}) => {
    const menu = useFloatMenu();

    const orderedOptions = useMemo(() => {
        return options
            .map((t) => ({ value: t.id, label: t.name }))
            .sort((a, b) => compareStringsAlphabetically(a?.label, b?.label));
    }, []);

    return (
        <Popover
            isOpen={menu.showMenu}
            align={'start'}
            positions={['bottom', 'top']}
            content={
                <TagsPicker
                    intl={intl}
                    tags={orderedOptions}
                    selectedTags={value}
                    onApply={(items) => {
                        onChange(items);
                        menu.handleMenu();
                    }}
                    autofocus
                    maxTags={singleItem ? 1 : 0}
                />
            }
            onClickOutside={menu.handleMenu}
        >
            <div className="item-selector-field" onClick={menu.handleMenu}>
                <label>{colName}</label>
                <div className="item-selector-field__tags">
                    {value.map((item) => (
                        <Pill variant="primary" key={item.value}>
                            {item.label}
                        </Pill>
                    ))}
                </div>
            </div>
        </Popover>
    );
};

export default ItemSelectorField;
