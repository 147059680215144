import React from 'react';
import './index.scss';

const mainClass = 'cg-three-dots-loader';

const ThreeDotsLoader = ({ width = 20, height = 20 }) => {
    return (
        <div className={mainClass}>
            <div
                style={{
                    width,
                    height,
                }}
                className={`${mainClass}__bounce first`}
            ></div>
            <div
                style={{
                    width,
                    height,
                }}
                className={`${mainClass}__bounce second`}
            ></div>
            <div
                style={{
                    width,
                    height,
                }}
                className={`${mainClass}__bounce third`}
            ></div>
        </div>
    );
};

export default ThreeDotsLoader;
