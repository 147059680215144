import { useState, useMemo, useEffect } from 'react';
import './index.scss';
import { Search } from 'react-feather';
import FolderTree from '../FolderTree';
import Button from '../Button';
import { searchFolders } from '../../../utils/traverseFolderTreeFunctions';
import TopicItem from '../../../modules/Topics/components/TopicItem';
import { UNCATEGORIZED_FOLDER_NAME } from '../../../constants/folders';
import { useIntl } from 'react-intl';
import { FilledFolderTreeInterface } from '~/interfaces/entities';

interface FolderSelectorProps {
    structure: FilledFolderTreeInterface[];
    value?: string;
    onConfirm: (selectedItem: string) => void;
    onCancel: () => void;
    expandedFolders?: string[];
    onExpandFolder: (folderId: string) => void;
    showUncategorizedFolder?: boolean;
    showFiles?: boolean;
    placeholder?: string;
    confirmButtonText?: string;
    loading?: boolean;
    onSelectNewItem?: () => void;
    customSearchValue?: string;
    showSearchBar?: boolean;
    size?: 'small' | 'regular' | 'large';
    selectDirectly?: boolean;
}

const mainClass = 'cg-folder-selector';

const FolderSelector: React.FC<FolderSelectorProps> = ({
    structure,
    value = null,
    onConfirm,
    onCancel,
    expandedFolders = [],
    onExpandFolder,
    showUncategorizedFolder = false,
    showFiles = false,
    placeholder = '',
    confirmButtonText = '',
    loading,
    onSelectNewItem,
    customSearchValue,
    showSearchBar = true,
    size = 'regular',
    selectDirectly = false,
}) => {
    const intl = useIntl();
    const [search, setSearch] = useState('');
    const [selectedFolder, setSelectedFolder] = useState(value);
    const [selectedFile, setSelectedFile] = useState(value);

    // if a custom search value is provided, then we should use it
    useEffect(() => {
        if (customSearchValue !== undefined) {
            setSearch(customSearchValue);
        }
    }, [customSearchValue]);

    const filteredFolders = useMemo(() => {
        let newStructure = [...structure];

        if (showUncategorizedFolder) {
            const uncategorizedFolder = newStructure.find(
                (fol) => fol.id === UNCATEGORIZED_FOLDER_NAME
            );

            if (!uncategorizedFolder && !showFiles) {
                newStructure.push({
                    id: UNCATEGORIZED_FOLDER_NAME,
                    name: UNCATEGORIZED_FOLDER_NAME,
                    items: [],
                });
            }
        } else {
            newStructure = newStructure.filter(
                (fol) => fol.id !== UNCATEGORIZED_FOLDER_NAME
            );
        }

        const filtered = searchFolders(newStructure, search, showFiles);

        return filtered;
    }, [search, structure]);

    // if there is no folders created then we should display a flat list (without the tree)
    const shouldRenderTree = useMemo(() => {
        const foldersWithoutUncategorized = structure.filter(
            (f) => f.id !== UNCATEGORIZED_FOLDER_NAME
        );

        return foldersWithoutUncategorized.length > 0;
    }, [structure]);

    // when a flat list is rendered, then we need to shou the UNCATEGORIZED items
    const uncategorizedFolderItems = useMemo(() => {
        if (shouldRenderTree || !showFiles) return [];
        const uncategorizedFolder = structure.find(
            (fol) => fol.id === UNCATEGORIZED_FOLDER_NAME
        );
        if (!search) return uncategorizedFolder?.items || [];

        if (!uncategorizedFolder) return [];

        const filtered = uncategorizedFolder.items.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );

        return filtered;
    }, [structure, search, shouldRenderTree]);

    const showActionButtons =
        !selectDirectly && (selectedFolder || selectedFile);
    const showItemCreation = search && onSelectNewItem;

    const handleSelection = (itemId: string) => {
        if (selectDirectly) return onConfirm(itemId);

        // folder selector can be used as for selecting files or folders. If showFiles is true, then we should select files
        if (showFiles) {
            setSelectedFile(itemId);
        } else {
            setSelectedFolder(itemId);
        }
    };

    const selectedFoldersFolderTree = selectedFolder ? [selectedFolder] : [];

    return (
        <div className={mainClass + ` ${mainClass}__${size}`}>
            <div className={mainClass + '__search'}>
                {showSearchBar && (
                    <>
                        <Search />
                        <input
                            type="text"
                            placeholder={placeholder}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </>
                )}
            </div>

            {!shouldRenderTree ? (
                <>
                    {uncategorizedFolderItems.length ? (
                        <div className={mainClass + '__list'}>
                            {uncategorizedFolderItems.map((item, idx) => (
                                <TopicItem
                                    key={idx}
                                    topic={item}
                                    isSelected={selectedFile === item.id}
                                    lite
                                    onClick={(item) => {
                                        selectDirectly
                                            ? onConfirm(item.id)
                                            : setSelectedFile(item.id);
                                    }}
                                    showInsightsCount={false}
                                    tooltipId="folder-selector"
                                />
                            ))}
                        </div>
                    ) : (
                        <div className={mainClass + '__empty'}>
                            <span>
                                {intl.formatMessage({
                                    id: 'no_results_were_found',
                                })}
                            </span>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {filteredFolders.length === 0 ? (
                        <div className={mainClass + '__empty'}>
                            <span>
                                {intl.formatMessage({
                                    id: 'no_results_were_found',
                                })}
                            </span>
                        </div>
                    ) : (
                        <div className={mainClass + '__tree'}>
                            <FolderTree
                                structure={filteredFolders}
                                customItem={
                                    showFiles &&
                                    ((itemProps: TopicInterface) => (
                                        <TopicItem
                                            topic={itemProps}
                                            isSelected={
                                                selectedFile === itemProps.id
                                            }
                                            lite
                                            onClick={(topic) =>
                                                handleSelection(topic.id)
                                            }
                                            showInsightsCount={false}
                                            tooltipId="folder-selector"
                                            colored
                                        />
                                    ))
                                }
                                showActions={false}
                                expandAllFolders={Boolean(search)}
                                expandedFolders={expandedFolders}
                                showItemsCount={false}
                                showFiles={showFiles}
                                selectedFolders={selectedFoldersFolderTree}
                                onSelectFolder={(folder) =>
                                    !showFiles && handleSelection(folder.id)
                                }
                                onExpandFolder={onExpandFolder}
                            />
                        </div>
                    )}
                </>
            )}

            {showItemCreation && (
                <div
                    className={mainClass + '__creation'}
                    onClick={(e) => {
                        onSelectNewItem();
                        e.stopPropagation();
                    }}
                >
                    <div>
                        <span>{intl.formatMessage({ id: 'create' })}</span>
                        <span>{search}</span>
                    </div>
                </div>
            )}

            {showActionButtons && (
                <div className={mainClass + '__actions'}>
                    <Button
                        variant="secondary"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            onCancel();
                        }}
                        disabled={loading}
                    >
                        {intl.formatMessage({ id: 'cancel' })}
                    </Button>
                    <Button
                        variant="accent"
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            onConfirm(
                                showFiles ? selectedFile : selectedFolder
                            );
                        }}
                        disabled={
                            loading ||
                            (showFiles ? !selectedFile : !selectedFolder)
                        }
                    >
                        {confirmButtonText}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FolderSelector;
