import { useState } from 'react';
import checkLegacyWorkspaceStorage from '~/modules/Workspaces/helpers/checkLegacyWorkspaceStorage';
/*
    Legacy local storage data, stored the entire workspace object, which is no longer necessary.
    So remove legacy workspace keys (if exist) from existing local storage before the hook gets mounted.
*/
checkLegacyWorkspaceStorage();

/**
 * Used by Workspace and Project
 */
// avoid param is to avoid setting an inital value if it's not needed by some reason. i.e. when user is entering using a query link (project is set by the query)

const useLastSelectedEntity = <T>(key: string, avoid?: boolean | undefined) => {
    const storedValue = localStorage.getItem(key);
    let initialState;

    if (avoid || !storedValue) {
        initialState = null;
    } else {
        try {
            initialState = JSON.parse(storedValue);
        } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
            initialState = null; // Or set to a default value if appropriate
        }
    }

    const [value, setValue] = useState<T | undefined | null>(initialState);

    const setValueWrapper = (value: T | null) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
            setValue(value);
        } catch (error) {
            console.error('Error saving JSON to localStorage:', error);
        }
    };

    return [value, setValueWrapper] as [
        T | undefined | null,
        (value: T | null) => void,
    ];
};

export default useLastSelectedEntity;
