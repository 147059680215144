import { useCallback, useEffect, useRef } from 'react';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './BaseTable.scss';
import { DataTable, DataTableHeaderTemplateType } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useInfiniteScroll } from '~/hooks';
import { defaultColumnsId } from '~/constants/insightTableColumns';
import AddColumnHeader from './AddColumnHeader';
import { Radio } from 'react-feather';
import { useIntl } from 'react-intl';

export interface TableColumnModelInterface {
    header: DataTableHeaderTemplateType<any>;
    cell: (row: any) => JSX.Element;
    id: string;
    field: string;
    hidden?: boolean;
    style?: { [key: string]: string };
    align?: 'center' | 'left' | 'right';
}

interface BaseTableProps {
    data: any[];
    columns: TableColumnModelInterface[];
    selectedItems: string[];
    loading: boolean;
    reorderableColumns?: boolean;
    tableHeight?: number;
    minWidth?: number;
    onSelectionChange: (items: string[]) => void;
    onScrollToBottom?: () => void;
    onAddNewColumn?: () => void;
    handleColumnsOrder?: (columnId: string, dropIndex: number) => void;
}

const BaseTable: React.FC<BaseTableProps> = ({
    data = [],
    columns = [],
    selectedItems,
    loading,
    reorderableColumns,
    tableHeight = 0,
    minWidth,
    onSelectionChange,
    onScrollToBottom,
    onAddNewColumn,
    handleColumnsOrder,
}) => {
    const intl = useIntl();
    const tableRef = useRef(null);

    const { onScroll } = useInfiniteScroll({
        callback: () => {
            onScrollToBottom && onScrollToBottom();
        },
        targetClassName: 'p-datatable-wrapper',
    });

    useEffect(() => {
        // this method is necessary when 'add new column' is enabled. In order to keep the 'plus' column in the last position
        // this is necessary because the table is not re-rendered when a new column is added, so restoring the internal
        // table state will keep the 'plus' column at the end
        if ((!tableRef?.current || !columns.length) && onAddNewColumn) return;

        tableRef.current?.resetColumnOrder();
    }, [columns, tableRef]);

    const selection = data.filter((item) => selectedItems.includes(item?.id));

    const onColReorder = (newOrder) => {
        const moved = newOrder.columns[newOrder.dropIndex];

        const columnId = columns.find(
            (c) => c.id === moved.props.columnKey
        )?.id;

        // remove first column (checkbox)
        const dropIndex = newOrder.dropIndex - 1; // remove first column (checkbox)

        newOrder.originalEvent.preventDefault();

        return handleColumnsOrder(columnId, dropIndex);
    };

    const onChangeCheckbox = (e) => {
        const { value: items } = e;
        const formattedItems = items.map((item) => item.id);
        onSelectionChange(formattedItems.length ? formattedItems : '');
    };

    const getColumn = useCallback(
        ({ id, cell, ...rest }) => {
            if (id === defaultColumnsId.ADD_NEW_COLUMN && onAddNewColumn)
                return (
                    <Column
                        key={defaultColumnsId.ADD_NEW_COLUMN}
                        columnKey={defaultColumnsId.ADD_NEW_COLUMN}
                        headerStyle={{ width: '3rem' }}
                        frozen
                        alignFrozen="right"
                        align="center"
                        body={<div className="dg-blank-cell"></div>}
                        header={() => (
                            <AddColumnHeader
                                onAdd={onAddNewColumn}
                                intl={intl}
                            />
                        )}
                        className="dg-base-table__add-column-cell"
                    />
                );
            return <Column key={id} columnKey={id} body={cell} {...rest} />;
        },
        [columns]
    );

    return (
        <>
            {columns.length > 0 ? (
                <DataTable
                    ref={tableRef}
                    loading={loading}
                    value={data}
                    editMode="cell"
                    tableStyle={{
                        minWidth: minWidth ? `${minWidth}px` : 'unset',
                        minHeight: '150px',
                    }}
                    selectionMode={'checkbox'}
                    selection={selection}
                    onSelectionChange={onChangeCheckbox}
                    scrollable
                    scrollHeight={`${tableHeight}px`}
                    reorderableColumns={reorderableColumns}
                    onColReorder={onColReorder}
                    onScrollCapture={onScroll}
                    showGridlines
                    className="dg-base-table"
                >
                    <Column
                        selectionMode="multiple"
                        columnKey={defaultColumnsId.CHECKBOX_COLUMN}
                        align="center"
                        frozen
                        alignFrozen="left"
                        className="dg-base-table__checkbox-cell"
                    />
                    {columns.length > 0 && columns.map(getColumn)}
                </DataTable>
            ) : (
                <div className="dg-base-table__empty">
                    <Radio />
                    {intl.formatMessage({ id: 'no_results_to_show' })}
                </div>
            )}
        </>
    );
};

export default BaseTable;
