import { FilledFolderTreeInterface } from '~/interfaces/entities';

// the highest level of the tree should contain the count of all items in the sub-folders, and so on
const addFolderInsightsCount = (
    folderStructure: FilledFolderTreeInterface[]
) => {
    const modifiedStructure = [...folderStructure];
    return modifiedStructure.map((folder) => {
        let count = 0;

        folder.items.forEach((item) => {
            count += item.details.comment_count;
        });

        if (folder.children) {
            let childrenCount = 0;
            folder.children = addFolderInsightsCount(folder.children);

            folder.children.forEach((child) => {
                childrenCount += child.items_count;
            });

            count += childrenCount;
        }

        folder.items_count = count;

        return folder;
    });
};

export default addFolderInsightsCount;
