import { useIntl } from 'react-intl';
import {
    BaseFloatMenu,
    FolderSelector,
    TextInput,
} from '~/components/UIElements';
import './index.scss';
import { useMemo, useRef, useState } from 'react';
import { useFloatMenu, useOpenFolders } from '~/hooks';
import { Popover } from 'react-tiny-popover';
import { FilledFolderTreeInterface } from '~/interfaces/entities';
import SelectorItemPill from '~/components/UIElements/FolderSelectorInput/SelectorItemPIll';
import { findFolderPath } from '~/utils/traverseFolderTreeFunctions';

const mainClass = 'cg-folder-selector-menu';

interface FolderSelectorInputProps {
    selectedFolder: string | null;
    onSelectFolder: (folderId: string | null) => void;
    structure: FilledFolderTreeInterface[];
    placeholder?: string;
    error?: string | null;
    label?: string;
    isOptional?: boolean;
    disabled?: boolean;
}

const FolderSelectorInput: React.FC<FolderSelectorInputProps> = ({
    selectedFolder,
    onSelectFolder,
    error,
    placeholder,
    label,
    isOptional = false,
    structure,
    disabled,
}) => {
    const intl = useIntl();
    const selectorMenu = useFloatMenu();
    const menuRef = useRef();

    const [folderName, setFolderName] = useState('');
    const { openedFolders, openFolder } = useOpenFolders();

    const handleSelectFolder = (folderId: string) => {
        selectorMenu.setShowMenu(false);

        // const folderOb = structure.find((t) => t.id === folderId);
        // if (!folderOb) return;

        setFolderName('');

        onSelectFolder(folderId);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (selectedFolder && !folderName && e.key === 'Backspace') {
            onSelectFolder(null);
        }
    };

    const folderPath: {
        shortPath: string;
        fullPath: string;
    } = useMemo(() => {
        if (!selectedFolder) return { shortPath: '', fullPath: '' };

        const maxLength = 16;
        const path = findFolderPath(
            structure,
            selectedFolder,
            false
        )?.pathLabel;

        const shortPath = path?.slice(-maxLength);

        return {
            shortPath: `${path?.length > maxLength ? '...' : ''}${shortPath}`,
            fullPath: path,
        };
    }, [selectedFolder]);

    return (
        <div className={mainClass} ref={menuRef}>
            <label>
                {label ||
                    intl.formatMessage({
                        id: 'select_folder',
                    })}

                {isOptional && (
                    <small>{intl.formatMessage({ id: 'optional' })}</small>
                )}
            </label>
            <Popover
                isOpen={selectorMenu.showMenu && !disabled}
                positions={['bottom', 'left', 'right']}
                align="start"
                onClickOutside={() => selectorMenu.setShowMenu(false)}
                parentElement={menuRef.current}
                content={
                    <BaseFloatMenu
                        animated={false}
                        className={`${mainClass}__popup`}
                    >
                        <FolderSelector
                            structure={structure}
                            value={selectedFolder}
                            onConfirm={handleSelectFolder}
                            onCancel={() => selectorMenu.setShowMenu(false)}
                            expandedFolders={openedFolders}
                            onExpandFolder={openFolder}
                            placeholder={intl.formatMessage({
                                id: 'search_folders',
                            })}
                            confirmButtonText={intl.formatMessage({
                                id: 'select',
                            })}
                            showUncategorizedFolder
                            showSearchBar={false}
                            customSearchValue={folderName}
                            loading={false}
                            size="small"
                        />
                    </BaseFloatMenu>
                }
            >
                <div
                    className={`${mainClass}__input ${mainClass}__${selectedFolder ? 'input-selected' : ''}`}
                >
                    {selectedFolder && (
                        <SelectorItemPill
                            label={folderPath.shortPath}
                            tooltip={folderPath.fullPath}
                            onRemove={() => !disabled && onSelectFolder(null)}
                        />
                    )}
                    <TextInput
                        value={folderName}
                        onFocus={() => selectorMenu.setShowMenu(true)}
                        onChange={(e) => setFolderName(e.target.value)}
                        onKeyDown={onKeyDown}
                        onClick={() =>
                            !disabled && selectorMenu.setShowMenu(true)
                        }
                        disabled={disabled}
                        placeholder={!selectedFolder ? placeholder : ''}
                    />
                </div>
            </Popover>
            {error && <span className={mainClass + '__error'}>{error}</span>}
        </div>
    );
};

export default FolderSelectorInput;
