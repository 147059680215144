import queryString from 'query-string';
import { environment } from '~/config/env';
import { UPDATE_IMPORT_ACTIONS } from '~/constants/ImportActions.enum';
import { IMPORT_STATUSES } from '~/constants/ImportStatuses.enum';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CGPublicDocumentInterfaceDetailed,
    CreateFileFormData,
    CreateImportDTO,
    CreatePublicImportDTO,
    CreateWeblinkFile,
    ImportInterface,
    ImportInterfaceDetailed,
    ImportStageInterface,
    UpdateImportDTO,
    UploadedFile,
} from '~/interfaces/entities';

const importServices = {
    uploadFile,
    launchImport,
    uploadWebURL,
    createImport,
    getImports,
    getImportFilesStatus,
    getUserRunningImport,
    abortImport,
    importPublicDocuments,
};
export default importServices;

// TODO?: verify if files that user wants to upload can be uploaded on the cloud storage
async function launchImport(importId: string) {
    const url = `${environment.apiUrl}/imports/${importId}`;
    return requestAdapterInstance.put<
        UnpagedResponse<ImportInterface>,
        UpdateImportDTO
    >(url, {
        action: UPDATE_IMPORT_ACTIONS.START,
    });
}

async function uploadFile(file: File, importId: string) {
    const fileForm: CreateFileFormData = new FormData();

    fileForm.append('file', file);
    fileForm.append('import_id', importId);
    const url = environment.apiUrl + '/files/upload';
    return requestAdapterInstance.postFiles<
        UnpagedResponse<UploadedFile>,
        FormData
    >(url, fileForm);
}

async function uploadWebURL(info: CreateWeblinkFile) {
    const url = `${environment.apiUrl}/files/upload_web_link`;
    return requestAdapterInstance.post<
        UnpagedResponse<UploadedFile>,
        CreateWeblinkFile
    >(url, info);
}

async function createImport(projectId: string) {
    const url = `${environment.apiUrl}/imports`;
    return requestAdapterInstance.post<
        UnpagedResponse<ImportInterface>,
        CreateImportDTO
    >(url, {
        project_id: projectId,
    });
}

async function getImports(options: Record<string, unknown> = {}) {
    const url = `${environment.apiUrl}/imports/detailed?${queryString.stringify(
        options
    )}`;
    return requestAdapterInstance.get<PagedResponse<ImportInterfaceDetailed>>(
        url
    );
}

async function getImportFilesStatus(options: Record<string, unknown> = {}) {
    const url = `${environment.apiUrl}/import_stages/detailed?${queryString.stringify(
        options
    )}`;
    return requestAdapterInstance.get<PagedResponse<ImportStageInterface>>(url);
}

// check if the user has a running import and return it
async function getUserRunningImport(
    userId: string,
    projectId: string
): Promise<ImportInterfaceDetailed | undefined> {
    const [error, response] = await getImports({
        project_ids: projectId,
        owner_ids: userId,
        statuses: [IMPORT_STATUSES.STARTED, IMPORT_STATUSES.PENDING],
    });

    if (error) return undefined;

    return response.data[0];
}

async function abortImport(importId: string) {
    const url = `${environment.apiUrl}/imports/${importId}`;
    return requestAdapterInstance.put<
        UnpagedResponse<ImportInterfaceDetailed>,
        UpdateImportDTO
    >(url, {
        action: UPDATE_IMPORT_ACTIONS.CANCEL_ALL,
    });
}

async function importPublicDocuments(
    documentIds: string[],
    copyTags: boolean,
    projectId: string
) {
    const url = `${environment.apiUrl}/projects/${projectId}/import_public_documents`;

    return requestAdapterInstance.post<
        UnpagedResponse<CGPublicDocumentInterfaceDetailed[]>,
        CreatePublicImportDTO
    >(url, {
        public_document_ids: documentIds,
        copy_tags_and_folders: copyTags,
    });
}
