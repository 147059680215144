import React, { PropsWithChildren } from 'react';
import './index.scss';
import { createPortal } from 'react-dom';

const mainClass = 'cg-full-modal';

interface FullModalProps {
    isOpen: boolean;
    children: React.ReactNode;
    className?: string;
    onClose: () => void;
}

const FullModal: React.FC<PropsWithChildren<FullModalProps>> = ({
    isOpen,
    children,
    className = '',
    onClose,
}) => {
    if (!isOpen) return null;

    const handleOverlayClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return createPortal(
        <div
            className={`${mainClass} ${className}`}
            onClick={handleOverlayClick}
        >
            <div className={mainClass + '__body'}>
                {children}
                {/* <div className={`${mainClass}__content`}>{children}</div> */}
            </div>
        </div>,
        document.body
    );
};

export default FullModal;
