import React from 'react';

function SparkIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="24"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0.1}
            viewBox="0 0 16 16"
        >
            <g clipPath="url(#clip0_2977_17443)">
                <g fill="#161816" fillRule="evenodd" clipRule="evenodd">
                    <path d="M6.333 4.667c.369 0 .667.298.667.666a4.333 4.333 0 004.333 4.334.667.667 0 110 1.333A4.333 4.333 0 007 15.333a.667.667 0 11-1.333 0A4.334 4.334 0 001.333 11a.667.667 0 010-1.333 4.333 4.333 0 004.334-4.334c0-.368.298-.666.666-.666zm0 3.333A5.666 5.666 0 014 10.333a5.67 5.67 0 012.333 2.334 5.664 5.664 0 012.334-2.334A5.663 5.663 0 016.333 8zM12 0c.368 0 .667.298.667.667a2.667 2.667 0 002.666 2.666.667.667 0 110 1.334 2.667 2.667 0 00-2.666 2.666.667.667 0 01-1.334 0 2.667 2.667 0 00-2.666-2.666.667.667 0 010-1.334A2.667 2.667 0 0011.333.667c0-.369.299-.667.667-.667zm0 2.878A3.994 3.994 0 0110.878 4 4 4 0 0112 5.122 3.994 3.994 0 0113.122 4 4 4 0 0112 2.878z"></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2977_17443">
                    <path fill="#fff" d="M0 0H16V16H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SparkIcon;
