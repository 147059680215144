import queryString from 'query-string';
import { environment } from '~/config/env';
import requestAdapterInstance, {
    PagedResponse,
    UnpagedResponse,
} from '~/helpers/requestAdapter';
import {
    CreateTagGroupDTO,
    PublicTagGroupInterfaceDetailed,
    TagGroupInterface,
    TagGroupInterfaceDetailed,
    UpdateTagGroupDTO,
} from '~/interfaces/entities';
import analytics, { EVENT_NAMES_ENUM } from '~/helpers/analytics';
import {
    CreateTagDTO,
    TagInterface,
    UpdateTagDTO,
    PublicTagInterfaceDetailed,
    TagInterfaceDetailed,
} from '~/modules/Tags/types/Tag.interface';

const API_URL = environment.apiUrl + '/tags';
const tagsServices = {
    getTags,
    createTag,
    updateTag,
    deleteTag,
    getTagFolders,
    createTagFolder,
    updateTagFolder,
    deleteTagFolder,
    getPublicTags,
    getPublicTagFolders,
};
export default tagsServices;

async function getTags(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<PagedResponse<TagInterfaceDetailed>>(
        API_URL + `/detailed?${queryString.stringify(options)}`
    );
}

async function createTag(data: CreateTagDTO) {
    analytics.trackGenericEvent(EVENT_NAMES_ENUM.CREATE_TAG);

    return requestAdapterInstance.post<
        UnpagedResponse<TagInterface>,
        CreateTagDTO
    >(API_URL, data);
}

async function updateTag(id: string, data: UpdateTagDTO) {
    return requestAdapterInstance.put<
        UnpagedResponse<TagInterface>,
        UpdateTagDTO
    >(API_URL + `/${id}`, data);
}

async function deleteTag(id: string) {
    return requestAdapterInstance.delete<null, null>(API_URL + `/${id}`);
}

// TAG FOLDERS
async function getTagFolders(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<PagedResponse<TagGroupInterfaceDetailed>>(
        environment.apiUrl +
            `/tag_groups/detailed?${queryString.stringify(options)}`
    );
}

async function createTagFolder(data: CreateTagGroupDTO) {
    return requestAdapterInstance.post<
        UnpagedResponse<TagGroupInterface>,
        CreateTagGroupDTO
    >(environment.apiUrl + `/tag_groups`, data);
}

async function updateTagFolder(id: string, data: UpdateTagGroupDTO) {
    return requestAdapterInstance.put<
        UnpagedResponse<TagGroupInterface>,
        UpdateTagGroupDTO
    >(environment.apiUrl + `/tag_groups/${id}`, data);
}

async function deleteTagFolder(id: string) {
    return requestAdapterInstance.delete<null, null>(
        environment.apiUrl + `/tag_groups/${id}?force=true`
    );
}

async function getPublicTags(options: Record<string, unknown> = {}) {
    const url =
        environment.apiUrl + '/public_tags?' + queryString.stringify(options);

    return requestAdapterInstance.get<
        PagedResponse<PublicTagInterfaceDetailed>
    >(url);
}

async function getPublicTagFolders(options: Record<string, unknown> = {}) {
    return requestAdapterInstance.get<
        PagedResponse<PublicTagGroupInterfaceDetailed>
    >(
        environment.apiUrl +
            `/public_tag_groups?${queryString.stringify(options)}`
    );
}
