import React, { useRef, useState } from 'react';
import { Icon, MoreHorizontal } from 'react-feather';
import { useIntl } from 'react-intl';
import toast from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicInterfaceDetailed } from '~/modules/Topics/types/Topic.interface';
import useTopicActionsMenu from '~/modules/Topics/hooks/useTopicActionsMenu';
import getTopicStatusIcon from '~/modules/Topics/helpers/getTopicStatusIcon';
import TOPIC_STATUSES from '~/modules/Topics/constants/TopicStatuses.enum';
import { TopicsContext } from '~/context/topics/index';
import { getErrorCode } from '~/utils/getErrorCode';
import { contentServices } from '~/services';
import { useEntityContext, useFloatMenu, useDraggable } from '~/hooks';
import PinIcon from '~/assets/icons/shared/PinIcon';
import CreateUpdateTopicForm from '../CreateUpdateTopicForm';
import {
    BaseModal,
    BasePopover,
    BasicMenuList,
    DeleteConfirmationDialog,
    IconButton,
} from '~/components/UIElements';
import classNames from 'classnames';

import styles from './TopicItem.module.scss';

interface TopicItemProps {
    topic: TopicInterfaceDetailed;
    isSelected: boolean;
    selectedIcon?: Icon;
    showInsightsCount?: boolean;
    tooltipId?: string;
    lite?: boolean;
    colored?: boolean; // when topic item needs to be colored
    onClick?: (topic: TopicInterfaceDetailed) => void;
    onPin?: (topic: TopicInterfaceDetailed) => void;
    onClickIcon?: (topic: TopicInterfaceDetailed) => void;
    onUpdateTopic?: () => void;
    handleSearchesList?: (type: TopicInterfaceDetailed) => void;
}

const TopicItem = ({
    topic,
    isSelected,
    lite,
    showInsightsCount = true,
    tooltipId = '',
    colored,
    onClick,
    onPin,
    onUpdateTopic,
    onClickIcon,
    handleSearchesList,
}: TopicItemProps) => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const [isDuplicating, setIsDuplicating] = useState(false);

    const cardContainer = useRef();
    const { dragItemRef, isDragging } = useDraggable();

    const { getTopicColor } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const color = colored ? getTopicColor(topic) : null;

    const menu = useFloatMenu();
    const editMenu = useFloatMenu();
    const deleteMenu = useFloatMenu();

    const handleSelection = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick && onClick(topic);
    };

    const handleRemoveTopic = async () => {
        setLoading(true);

        const [error, response] = await contentServices.deleteTopic(
            topic.id as string
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
            return;
        }

        toast.success(
            intl.formatMessage({
                id: `topic_removed_successfully`,
            })
        );

        onUpdateTopic && onUpdateTopic();
    };

    const onChangeStatus = async (status: TOPIC_STATUSES) => {
        setLoading(true);

        const [error, response] = await contentServices.updateTopic(
            topic.id as string,
            {
                status,
            }
        );

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({
                    id: getErrorCode(response.error_code),
                })
            );
            return;
        }

        toast.success(
            intl.formatMessage({
                id: `topic_updated_successfully`,
            })
        );

        onUpdateTopic && onUpdateTopic();
    };

    const { menuTabs } = useTopicActionsMenu({
        topic,
        isSelected,
        handleDuplication: setIsDuplicating,
        onChangeStatus: onChangeStatus,
        onOpenSearchesList: handleSearchesList,
        onPin: onPin!,
        closeMainMenu: () => menu.setShowMenu(false),
        openEditMenu: () => editMenu.setShowMenu(true),
        openDeleteMenu: () => deleteMenu.setShowMenu(true),
    });

    const handleIconClick = () => {
        if (onClickIcon) {
            onClickIcon(topic);
            return;
        }

        handleSelection();
    };

    const TopicStatusIcon = getTopicStatusIcon(topic.status);

    return (
        <div
            key={topic.id}
            className={classNames(styles.cgTopicItem, {
                [styles.cgTopicItem__dragged]: isDragging,
            })}
        >
            <Tooltip
                id={`selected-topic-${topic.id + tooltipId}`}
                className={styles.cgTopicItem__tooltip}
                place="bottom"
                opacity={1}
            />

            <div
                ref={dragItemRef}
                draggable={true}
                id={topic.id}
                onClick={handleSelection}
                data-tooltip-id={`selected-topic-${topic.id + tooltipId}`}
                data-tooltip-content={topic.name}
                data-tooltip-delay-show={1000}
                className={classNames(styles.cgTopicItem__card, {
                    [styles.cgTopicItem__cardSelected]: isSelected,
                    [styles.cgTopicItem__cardMenuOpen]: menu.showMenu,
                    [styles[`cgTopicItem__card-${color}`]]: color,
                })}
            >
                <div className={styles.cgTopicItem__icons}>
                    <TopicStatusIcon />
                    {isSelected && <PinIcon onClick={handleIconClick} />}
                </div>

                <p
                    className={styles.cgTopicItem__name}
                    onClick={handleSelection}
                >
                    {topic.name}
                </p>

                {!lite && (
                    <BasePopover
                        isOpen={menu.showMenu}
                        onClickOutside={menu.handleMenu}
                        content={<BasicMenuList menus={menuTabs} />}
                        align="start"
                        parentContainer={cardContainer.current}
                    >
                        <div
                            className={classNames(styles.cgTopicItem__menu, {
                                [styles.cgTopicItem__menuVisible]:
                                    menu.showMenu,
                            })}
                        >
                            <IconButton
                                className={classNames(
                                    styles.cgTopicItem__button,
                                    {
                                        [styles.cgTopicItem__buttonVisible]:
                                            menu.showMenu,
                                    }
                                )}
                                icon={MoreHorizontal}
                                onClick={menu.handleMenu}
                                variant="secondary"
                            />
                        </div>
                    </BasePopover>
                )}
            </div>
            {showInsightsCount && (
                <span className={styles.cgTopicItem__count}>
                    {topic.details.comment_count}
                </span>
            )}

            {editMenu.showMenu && (
                <BaseModal
                    handleClose={() => {
                        editMenu.handleMenu();
                        setIsDuplicating(false);
                    }}
                >
                    <CreateUpdateTopicForm
                        topicToEdit={topic}
                        callback={editMenu.handleMenu}
                        duplicate={isDuplicating}
                    />
                </BaseModal>
            )}

            {deleteMenu.showMenu && (
                <DeleteConfirmationDialog
                    handleClose={deleteMenu.handleMenu}
                    callback={handleRemoveTopic}
                    buttonText={intl.formatMessage({ id: 'delete' })}
                    text={intl.formatMessage({
                        id: 'this_action_cannot_be_undone',
                    })}
                    title={
                        intl.formatMessage({
                            id: 'delete_topic',
                        }) + '?'
                    }
                    loading={loading}
                />
            )}
        </div>
    );
};

export default TopicItem;
