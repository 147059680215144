import { useState, useMemo } from 'react';
import { MoreHorizontal } from 'react-feather';
import {
    useEntityContext,
    useFloatMenu,
    useTemporaryErrorDisplay,
} from '~/hooks';
import { Popover } from 'react-tiny-popover';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import BasicMenuList from '../BasicMenuList';
import PillInputText from '../PillInputText';
import { TagsContext } from '~/context/tags';
import Pill from '../Pill';
import './TagItem.scss';

const TagItem = ({
    item,
    onClick,
    intl,
    onDelete,
    onUpdate,
    showActions,
    disabled,
    getTagColorCustomFn,
}) => {
    const { getTagColor } =
        useEntityContext<TagsProviderInterface>(TagsContext);
    const menu = useFloatMenu();

    const [isEditing, setIsEditing] = useState(false);
    const [label, setLabel] = useState(item.label);

    const { handleError, error } = useTemporaryErrorDisplay();

    const handleUpdate = () => {
        if (!label.trim()) return handleError(true);

        handleError(false);
        setIsEditing(false);

        onUpdate(item.value, label);
    };

    const MENUS = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'rename' }),
                onClick: () => {
                    menu.setShowMenu(false);
                    setIsEditing(true);
                },
            },
            {
                label: intl.formatMessage({ id: 'delete' }),
                onClick: () => {
                    menu.setShowMenu(false);
                    onDelete(item.value);
                },
                variant: 'distructive',
            },
        ],
        []
    );

    const getTagColorFn = getTagColorCustomFn || getTagColor;

    return (
        <Popover
            isOpen={menu.showMenu}
            positions={['bottom', 'left', 'right']}
            align="end"
            onClickOutside={() => menu.setShowMenu(false)}
            content={<BasicMenuList menus={MENUS} />}
        >
            <div
                className={`cg-tagspicker-item ${
                    disabled ? 'cg-tagspicker-item__disabled' : ''
                }`}
                onClick={(e) => !disabled && onClick(e)}
            >
                {isEditing ? (
                    <PillInputText
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        onBlur={() => {
                            setIsEditing(false);
                            setLabel(item.label);
                        }}
                        onPressEscape={() => {
                            setIsEditing(false);
                            setLabel(item.label);
                        }}
                        onPressEnter={handleUpdate}
                        error={error}
                    />
                ) : (
                    <Pill variant={getTagColorFn(item.value)}>
                        <p title={item.label}>{item.label}</p>
                    </Pill>
                )}

                {showActions && (
                    <MoreHorizontal
                        onClick={(e) => {
                            e.stopPropagation();
                            menu.setShowMenu(true);
                        }}
                    />
                )}
            </div>
        </Popover>
    );
};

export default TagItem;
