// @ts-nocheck
import { MONTHS } from '~/constants/date';

export const timeUtils = {
    getFormattedHour,
    getFormattedDate,
    getFormattedDateWithHour,
    getShortFormatDate,
    getShortMonthAndYear,
    getShortMonthDateAndHour,
    getShortMonthDateHoursAndMinutes,
    getShortMonthAndDate,
};

/**
 * @param date
 * @returns {string}
 */
function getFormattedDate(date = new Date()) {
    const todayTime = new Date(date);
    let month = todayTime.getMonth() + 1;
    let day = todayTime.getDate();
    const year = todayTime.getFullYear();
    if (month < 10) month = '0' + month;

    if (day < 10) day = '0' + day;
    return year + '-' + month + '-' + day;
}

function getFormattedHour(date = new Date()) {
    const todayTime = new Date(date);
    let hours = todayTime.getHours();
    let minutes = todayTime.getMinutes();
    let seconds = todayTime.getSeconds();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    if (seconds < 10) seconds = '0' + seconds;

    return hours + ':' + minutes + ':' + seconds;
}

function getFormattedDateWithHour(date = new Date()) {
    const d = getFormattedDate(date);
    const time = getFormattedHour(date);

    return `${d} ${time}`;
}

function getShortFormatDate(date = new Date()) {
    // receive a date and return a string with the format: Jan 01, 16:22
    const todayTime = new Date(date);
    const month = MONTHS[todayTime.getMonth()];
    const day = todayTime.getDate();
    let hours = todayTime.getHours();
    let minutes = todayTime.getMinutes();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    return `${month} ${day}, ${hours}:${minutes}`;
}
function getShortMonthAndYear(date = new Date()) {
    // receive a date and return a string with the format: May 5, 2022
    const todayTime = new Date(date);
    const month = MONTHS[todayTime.getMonth()];
    const day = todayTime.getDate();
    const year = todayTime.getFullYear();

    return `${month} ${day}, ${year}`;
}

function getShortMonthDateAndHour(date = new Date()) {
    // receive a date and return a string with the format: Jan 01, 2023 16:00
    const todayTime = new Date(date);
    const year = todayTime.getFullYear();
    const month = MONTHS[todayTime.getMonth()];
    const day = todayTime.getDate();
    let hours = todayTime.getHours();

    if (hours < 10) hours = '0' + hours;

    return `${month} ${day}, ${year} ${hours}:00`;
}

function getShortMonthDateHoursAndMinutes(date: string | Date) {
    // receive a date and return a string with the format: Jan 01, 2023 16:22
    const todayTime = new Date(date);
    const year = todayTime.getFullYear();
    const month = MONTHS[todayTime.getMonth()];
    const day = todayTime.getDate();
    let hours = todayTime.getHours();
    let minutes = todayTime.getMinutes();

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    return `${month} ${day}, ${year} ${hours}:${minutes}`;
}

export function getShortMonthAndDate(date = new Date()) {
    // retrieve something like: 01 Jan
    const todayTime = new Date(date);
    const month = MONTHS[todayTime.getMonth()];
    const day = todayTime.getDate();

    return `${day} ${month}`;
}
