import { useMemo, useState, forwardRef, useEffect } from 'react';
import './index.scss';
import { PillVariants } from '~/components/UIElements/Pill/constants';
import Button from '../Button';
import TagsInput from './TagsInput';
import Spinner from '../Spinner';
import FolderTree from '../FolderTree';
import TagItem from './TagItem';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { useIntl } from 'react-intl';
import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { LabelValueOptionInterface } from '~/types/labelValueOption.interface';
import { GroupedTagsWithLabelledItems } from '~/helpers/transformTagGroupWithItems';
import { useOpenFolders } from '~/hooks';
import Pill from '~/components/UIElements/Pill';

const mainClass = 'tags-picker';

// This tags picker can render a list of tags/items or a folder tree. If folderStructure prop is present,
// then the component will render a folder tree, otherwise it will render a list of tags/items

interface TagsPickerInterface {
    tags: LabelValueOptionInterface[];
    selectedTags: LabelValueOptionInterface[];
    disabled?: boolean;
    loading?: boolean;
    showCancelButton?: boolean;
    allowCreation?: boolean;
    showItemActions?: boolean;
    autofocus?: boolean;
    maxTags?: number;
    folderStructure?: GroupedTagsWithLabelledItems[];
    renderTree?: boolean;
    showApplyButton?: boolean;
    onApply?: (tags: LabelValueOptionInterface[]) => void;
    onCancel?: () => void;
    onDeleteItem?: (item: any) => void;
    onUpdateItem?: (item: any) => void;
    getTagColorCustomFn?: (tagId: string) => PillVariants;
    onChange?: (tags: ColumnValueOption[]) => void;
}

const TagsPicker = forwardRef(
    (
        {
            tags = [],
            selectedTags = [],
            disabled,
            loading,
            allowCreation = true,
            autofocus,
            maxTags,
            folderStructure,
            renderTree = true,
            showCancelButton,
            showItemActions = false,
            showApplyButton = true,
            onApply,
            onCancel,
            onDeleteItem,
            onUpdateItem,
            getTagColorCustomFn,
            onChange,
        }: TagsPickerInterface,
        ref
    ) => {
        const intl = useIntl();

        const [search, setSearch] = useState('');
        const [value, setValue] = useState(selectedTags);
        const [displayApplyButton, setDisplayApplyButton] = useState(false);
        const { openedFolders, openFolder } = useOpenFolders();

        const canAddNewTag = useMemo(
            () =>
                !!search &&
                [...tags, ...value].findIndex(
                    (tag) => tag?.label?.toLowerCase() === search.toLowerCase()
                ) === -1,
            [tags, value, search]
        );

        useEffect(() => {
            setValue(selectedTags);
        }, [selectedTags]);

        const onAddTag = (tag: ColumnValueOption) => {
            const alreadyIncluded = value.some((t) => t.label === tag.label);

            if (alreadyIncluded) return;

            setDisplayApplyButton(true);

            setSearch('');
            if (maxTags && value.length >= maxTags) {
                // set the tag as the last one
                const newValue = [...value];
                newValue[maxTags - 1] = tag;
                return setValue(newValue);
            }

            const newValues = [...value, tag];

            onChange?.(newValues);

            setValue(newValues);
        };

        const onSelectTag = (e) => {
            if (canAddNewTag) {
                const newTag = {
                    value: search,
                    label: search,
                    isNew: true,
                };
                onAddTag(newTag);
                setDisplayApplyButton(true);
            }

            e.stopPropagation();
        };

        const handleTags = (newTags: ColumnValueOption[]) => {
            if (maxTags && newTags.length > maxTags) {
                // remove the first tag of newTags
                newTags.shift();
            }
            setDisplayApplyButton(true);
            setValue(newTags);
            onChange?.(newTags);
        };

        // to run searches on folders
        const filteredFolders = useMemo(() => {
            if (!folderStructure) return;

            const filtered = folderStructure.map((folder) => ({
                ...folder,
                items: folder.items.filter(
                    (item) =>
                        // don't include items that are already added
                        !value.some((val) => val.value === item.value) &&
                        item.label.toLowerCase().includes(search.toLowerCase())
                ),
            }));

            // don't show empty folders
            const foldersWithItems = filtered.filter((f) => f.items.length);
            return foldersWithItems;
        }, [search, folderStructure, value]);

        const shouldRenderTree = useMemo(() => {
            if (!folderStructure || !renderTree) return false;

            return (
                folderStructure.filter(
                    (f) => f.id !== UNCATEGORIZED_FOLDER_NAME
                ).length > 0
            );
        }, [folderStructure]);

        // to run searches on tags (With no folders)
        const filteredTags = useMemo(() => {
            if (shouldRenderTree) return [];

            const notIncludedYet = tags.filter(
                (option) => !value.some((t) => t.value === option.value)
            );

            if (!search) return notIncludedYet;

            const filtered = notIncludedYet.filter((option) =>
                option.label.toLowerCase().includes(search.toLowerCase())
            );

            return filtered;
        }, [search, tags, value, shouldRenderTree]);

        return (
            <div
                className={`${mainClass} animate__animated animate__fadeIn animate__faster`}
                ref={ref}
            >
                <div className={mainClass + '__form'}>
                    <TagsInput
                        canAddNewTag={canAddNewTag}
                        value={value}
                        onChange={handleTags}
                        placeholder={
                            intl.formatMessage({ id: 'search' }) + '...'
                        }
                        inputValue={search}
                        onChangeInputValue={setSearch}
                        disabled={disabled || loading}
                        disableEnterKey={!allowCreation}
                        autofocus={autofocus}
                        getTagColorCustomFn={getTagColorCustomFn}
                    />
                </div>
                <div className={mainClass + '__body'}>
                    {loading ? (
                        <div className="text-center">
                            <Spinner width="15" height="15" weight="2" />
                        </div>
                    ) : (
                        <span>
                            {intl.formatMessage({
                                id: allowCreation
                                    ? 'select_item_or_create_one'
                                    : 'select_item',
                            })}
                        </span>
                    )}

                    <div className={mainClass + '__list'}>
                        {shouldRenderTree ? (
                            <FolderTree
                                structure={filteredFolders}
                                customItem={(option) => (
                                    <TagItem
                                        key={option.value}
                                        item={option}
                                        intl={intl}
                                        onClick={(e) => {
                                            onAddTag(option);
                                            e.stopPropagation();
                                        }}
                                        showActions={showItemActions}
                                        onDelete={onDeleteItem}
                                        onUpdate={onUpdateItem}
                                        disabled={loading}
                                        getTagColorCustomFn={
                                            getTagColorCustomFn
                                        }
                                    />
                                )}
                                showActions={false}
                                expandAllFolders={Boolean(search)}
                                expandedFolders={openedFolders}
                                onExpandFolder={openFolder}
                            />
                        ) : (
                            filteredTags.map((option, index) => (
                                <TagItem
                                    key={index}
                                    item={option}
                                    intl={intl}
                                    onClick={(e) => {
                                        onAddTag(option);
                                        e.stopPropagation();
                                    }}
                                    showActions={showItemActions}
                                    onDelete={onDeleteItem}
                                    onUpdate={onUpdateItem}
                                    disabled={loading}
                                />
                            ))
                        )}

                        {allowCreation && canAddNewTag ? (
                            <div
                                className={mainClass + '__new-tag'}
                                onClick={onSelectTag}
                            >
                                <span>
                                    {intl.formatMessage({ id: 'create' })}:{' '}
                                </span>
                                <Pill> {search} </Pill>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={mainClass + '__footer'}>
                    {displayApplyButton && showApplyButton && (
                        <Button
                            disabled={disabled || loading}
                            variant="secondary"
                            onClick={(e) => {
                                onApply?.(value);
                                e.stopPropagation();
                            }}
                        >
                            {intl.formatMessage({ id: 'apply' })}
                        </Button>
                    )}
                    {showCancelButton && (
                        <Button
                            disabled={disabled || loading}
                            variant="tertiary"
                            onClick={() => onCancel && onCancel()}
                        >
                            {intl.formatMessage({ id: 'remove_filters' })}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
);

export default TagsPicker;
