import { useEffect, useMemo, useState } from 'react';
import { useFloatMenu, useOpenFolders } from '../../../../hooks';
import { UNCATEGORIZED_FOLDER_NAME } from '../../../../constants/folders';
import { findFolderPath } from '../../../../utils/traverseFolderTreeFunctions';
import { BaseModal, FolderSelector } from '../../../../components/UIElements';
import { contentServices } from '../../../../services';
import toast from 'react-hot-toast';
import { TopicInterfaceDetailed } from '../../types/Topic.interface';
import { FilledFolderTreeInterface } from '~/interfaces/entities';
import { useIntl } from 'react-intl';

interface TopicLocationProps {
    topic: TopicInterfaceDetailed;
    onUpdate: () => void;
    folderStructure: FilledFolderTreeInterface[];
    disabled: boolean;
}

const TopicLocationCell = ({
    topic,
    onUpdate,
    folderStructure,
    disabled,
}: TopicLocationProps) => {
    const intl = useIntl();
    const menu = useFloatMenu();
    const { openedFolders, openFolder } = useOpenFolders();

    useEffect(() => {
        let parentIds = [];

        if (!topic.folder_id) {
            parentIds = [UNCATEGORIZED_FOLDER_NAME];
        } else {
            parentIds = findFolderPath(folderStructure, topic.id, true)?.ids;
        }

        openFolder(parentIds);
    }, [folderStructure]);

    const topicFolder = useMemo(() => {
        if (openedFolders.length === 0) return '';

        return openedFolders.at(-1);
    }, [openedFolders]);

    const onConfirm = async (folderId: string) => {
        const payload = {
            folder_id: folderId === UNCATEGORIZED_FOLDER_NAME ? null : folderId,
        };

        const [error] = await contentServices.updateTopic(topic.id, payload);

        if (error) {
            toast.error(intl.formatMessage({ id: 'general_error' }));
            return;
        }

        menu.handleMenu();

        toast.success(
            intl.formatMessage({ id: 'insight_updated_successfully' })
        );

        onUpdate();
    };

    return (
        <>
            {menu.showMenu && (
                <BaseModal
                    showCloseIcon
                    handleClose={menu.handleMenu}
                    noPadding
                >
                    <FolderSelector
                        structure={folderStructure}
                        value={topicFolder}
                        onConfirm={(folderId) => {
                            onConfirm(folderId);
                            menu.handleMenu();
                        }}
                        onCancel={menu.handleMenu}
                        expandedFolders={openedFolders}
                        onExpandFolder={openFolder}
                        placeholder={intl.formatMessage({
                            id: 'search_folders_and_topics',
                        })}
                        confirmButtonText={intl.formatMessage({ id: 'save' })}
                        showUncategorizedFolder
                    />
                </BaseModal>
            )}

            <div
                className="cg-topic-cell"
                onClick={() => {
                    if (disabled) return;
                    menu.setShowMenu(true);
                }}
            >
                {topic.folder_id
                    ? findFolderPath(folderStructure, topic.id, true).pathLabel
                    : UNCATEGORIZED_FOLDER_NAME}
            </div>
        </>
    );
};

export default TopicLocationCell;
