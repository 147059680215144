export const getAvailableSpaceForTable = (
    tableContainerClassName = '',
    offSet
) => {
    // Get the container element
    const container = document.getElementsByClassName(
        tableContainerClassName
    )[0];

    if (!container) return 0;

    // Calculate the visible height
    const viewportHeight = window.innerHeight; // Viewport height

    const containerTop = container.getBoundingClientRect().top; // Distance from the top of the container to the viewport top

    const containerBottom = containerTop + container.clientHeight; // Distance from the top of the container to the bottom

    const visibleHeight =
        Math.min(viewportHeight, containerBottom) - Math.max(0, containerTop);

    return visibleHeight - offSet;
};
