export const PUBLIC_ROUTES = {
    login: '/login',
    signup: '/signup',
    recover_password: '/recover-password/:token',
    validate_account: '/validate-account/:token',
    trigger_error: '/trigger-error',
};

export const PRIVATE_ROUTES = {
    root: '/',
    search: '/search',
    collection_topics: '/insights',
    collection_tags: '/collection_tags', // this route actually doesn't exist but it's needed to redirect existing query links
    reports: '/reports',
};
