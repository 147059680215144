import { useState } from 'react';

function getTextWidth(el: HTMLInputElement | HTMLTextAreaElement): number {
    // uses a cached canvas if available
    const canvas =
        getTextWidth.canvas ||
        (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    // Set font attr in canvas by getting the full font style property
    context.font = window.getComputedStyle(el, null).getPropertyValue('font');
    // set the font attr for the canvas text
    const textMeasurement = context.measureText(el.value);
    return textMeasurement.width;
}

const useIncrementalInputWidth = (props = {}) => {
    const defaultPx = `${props.defaultWidth || 0}px`;
    const [width, setWidth] = useState(defaultPx);

    const setInputWidth = (
        element: HTMLInputElement | HTMLTextAreaElement | null | undefined
    ) => {
        if (!element) return;
        const calculatedWidth = Math.floor(getTextWidth(element));

        const width = calculatedWidth > 50 ? calculatedWidth : 50;
        const widthInPx = width + 10 + 'px';

        setWidth(widthInPx);
    };
    return { setInputWidth, width, reset: () => setWidth(defaultPx) };
};

export default useIncrementalInputWidth;
