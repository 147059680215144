import React, { useMemo, useRef } from 'react';
import { ThreeDotsLoader } from '../UIElements';
import { InsightInterfaceDetailed } from '~/interfaces/entities';
import './QuoteCell.scss';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
interface QuoteCellInterface {
    loading: boolean;
    cell: InsightInterfaceDetailed;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onOpenImage?: (img: Blob) => void;
}

const QuoteCell: React.FC<QuoteCellInterface> = ({
    loading,
    cell,
    onClick,
    onOpenImage,
}) => {
    const image = cell.image && URL.createObjectURL(cell.image);

    const imgRef = useRef(null);

    // if image exceeds the parent size, we need to translate it to the center
    const imgStyle = useMemo(() => {
        if (!imgRef.current) return {};

        if (imgRef.current.height > 80) {
            return {
                transform: `translateY(-${(imgRef.current.height - 80) / 2}px)`,
            };
        }
    }, [imgRef.current]);

    return (
        <div
            className="cg-quote-cell"
            onClick={(e) => {
                if (cell.image) {
                    onOpenImage(cell.image);
                } else {
                    onClick(e);
                }
            }}
        >
            {cell.screenshot ? (
                <>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <ThreeDotsLoader width={15} height={15} />
                        </div>
                    ) : (
                        <TransformWrapper disabled maxScale={3}>
                            <TransformComponent>
                                <img
                                    ref={imgRef}
                                    src={image}
                                    alt="snippet-image"
                                    style={imgStyle}
                                />
                            </TransformComponent>
                        </TransformWrapper>
                    )}
                </>
            ) : (
                <p title={cell.text}>{cell.text}</p>
            )}
        </div>
    );
};

export default QuoteCell;
