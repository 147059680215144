import axios, {
    isAxiosError,
    AxiosInstance,
    AxiosError,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from 'axios';

import { environment } from '~/config/env';
import { ERRORS } from '../constants/errors';
import { sessionServices } from '~/services';
import { UserData } from '~/interfaces/entities';
interface ErrorResponse {
    error: true;
    status_code: number;
    error_code: string;
    message: string;
    timestamp: string;
    path: string;
    code: string;
}

interface Paging {
    page: number;
    page_size: number;
    page_count: number;
    total: number;
}

export interface PagedResponse<T> {
    data: T[];
    paging: Paging;
}

export interface PagedSearchResponse<T> {
    data: T;
    paging: Paging;
}

export interface UnpagedResponse<T> {
    data: T;
}

export enum AvailableHTTPMethods {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export enum AvailableContentTypes {
    // application/json goes by default
    ZIP = 'application/zip',
    PDF = 'application/pdf',
}

export enum AvailableResponseTypes {
    JSON = 'json',
    BLOB = 'blob',
}

type RequestHeaders = Record<string, string>;
export class RequestAdapter {
    #handler: AxiosInstance;
    #nonInterceptedHandler: AxiosInstance;
    #logout: () => void;
    #sessionService: typeof sessionServices;
    #sessionRefreshURL: string;
    #isRefreshingToken = false;

    /**
     * See https://axios-http.com/docs/interceptors
     */
    static configureInterceptors(
        adapterInstance: RequestAdapter,
        requestInterceptor?: (
            value: InternalAxiosRequestConfig
        ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
        requestInterceptorErrorHandler?:
            | ((error: unknown) => unknown)
            | undefined,
        responseInterceptor?: (
            value: AxiosResponse
        ) => AxiosResponse | Promise<AxiosResponse>,
        responseInterceptorErrorHandler?: (error: AxiosError) => AxiosError
    ) {
        adapterInstance._handler.interceptors.request.use(
            requestInterceptor,
            requestInterceptorErrorHandler
        );
        adapterInstance._handler.interceptors.response.use(
            responseInterceptor,
            responseInterceptorErrorHandler
        );
    }

    constructor(
        handler: AxiosInstance,
        nonInterceptedHandler: AxiosInstance,
        logout: () => void,
        sessionService: typeof sessionServices,
        sessionRefreshURL: string
    ) {
        this.#handler = handler;
        this.#nonInterceptedHandler = nonInterceptedHandler;
        this.#logout = logout;
        this.#sessionService = sessionService;
        this.#sessionRefreshURL = sessionRefreshURL;
    }

    /**
     * Only intended to be used to configure interceptors.
     */
    get _handler() {
        return this.#handler;
    }

    get logout() {
        return this.#logout;
    }

    get sessionService() {
        return this.#sessionService;
    }

    get sessionRefreshURL() {
        return this.#sessionRefreshURL;
    }

    get isRefreshingToken() {
        return this.#isRefreshingToken;
    }

    set isRefreshingToken(value) {
        this.#isRefreshingToken = Boolean(value);
    }

    /**
     * Internal function.
     * The handler could be either the intercepted handler (for private requests) or the non intercepted one.
     */
    async #request<T, V>(
        handler: AxiosInstance,
        url: string,
        method: AvailableHTTPMethods = AvailableHTTPMethods.GET,
        headers: RequestHeaders = {},
        data?: V,
        responseType: AvailableResponseTypes = AvailableResponseTypes.JSON
    ): Promise<[false, T] | [true, ErrorResponse]> {
        try {
            const requestPayload: {
                url: string;
                method: AvailableHTTPMethods;
                headers: RequestHeaders;
                responseType: AvailableResponseTypes;
                data?: V;
            } = { url, method, headers, responseType };

            if (data) {
                requestPayload.data = data;
            }

            const response = await handler<T>(requestPayload);

            return [false, response.data];
        } catch (error) {
            if (isAxiosError<ErrorResponse>(error)) {
                if (error.response && error.response.data) {
                    return [
                        true,
                        {
                            ...error.response.data,
                            code:
                                error.response.data.error_code ||
                                ERRORS.GENERAL_ERROR,
                        },
                    ];
                } else {
                    return [
                        true,
                        {
                            ...error,
                            error_code: error.code,
                        },
                    ];
                }
            }
            throw error;
        }
    }

    /**
     * Used to call public endpoints.
     */
    async #publicRequest<T, V>(
        url: string,
        method: AvailableHTTPMethods,
        headers: RequestHeaders,
        data?: V,
        responseType?: AvailableResponseTypes
    ) {
        return this.#request<T, V>(
            this.#nonInterceptedHandler,
            url,
            method,
            headers,
            data,
            responseType
        );
    }

    /**
     * Used to call private endpoints. This request is intercepted before sending to check token availability.
     */
    async #authenticatedRequest<T, V>(
        url: string,
        method: AvailableHTTPMethods,
        headers: RequestHeaders = {},
        data?: V,
        responseType?: AvailableResponseTypes
    ) {
        const sessionData = this.sessionService.getUserData();

        const sanitisedHeaders = { ...headers };

        if (sessionData) {
            sanitisedHeaders['Authorization'] ??= `Bearer ${sessionData.token}`;
        }

        return this.#request<T, V>(
            this.#handler,
            url,
            method,
            sanitisedHeaders,
            data,
            responseType
        );
    }

    async getNewSession(): Promise<UserData> {
        const sessionData = this.sessionService.getUserData();

        const payload = {
            refresh_token: sessionData ? sessionData.refresh_token : '',
        };

        const [error, response] = await this.#publicRequest<
            UnpagedResponse<UserData>,
            typeof payload
        >(
            this.sessionRefreshURL,
            AvailableHTTPMethods.POST,
            {},
            payload,
            AvailableResponseTypes.JSON
        );

        if (error) {
            throw response;
        }

        const responseJson = {
            ...response.data,
        };

        return responseJson;
    }

    async get<T>(url: string, headers?: RequestHeaders) {
        return this.#authenticatedRequest<T, undefined>(
            url,
            AvailableHTTPMethods.GET,
            headers || {}
        );
    }

    async getFiles(url: string) {
        return this.#authenticatedRequest<Blob, undefined>(
            url,
            AvailableHTTPMethods.GET,
            {},
            undefined,
            AvailableResponseTypes.BLOB
        );
    }

    async getZipBlob(url: string, body: Record<string, unknown> = {}) {
        const headers = {
            ResponseType: AvailableContentTypes.ZIP,
        };

        return this.#authenticatedRequest<Blob, unknown>(
            url,
            AvailableHTTPMethods.GET,
            headers,
            body,
            AvailableResponseTypes.BLOB
        );
    }

    async post<T, V>(url: string, body: V, headers?: RequestHeaders) {
        return this.#authenticatedRequest<T, V>(
            url,
            AvailableHTTPMethods.POST,
            headers || {},
            body
        );
    }

    async postFiles<T, V extends FormData>(url: string, body: V) {
        return this.#authenticatedRequest<T, V>(
            url,
            AvailableHTTPMethods.POST,
            {},
            body
        );
    }

    async put<T, V>(url: string, body: V) {
        return this.#authenticatedRequest<T, V>(
            url,
            AvailableHTTPMethods.PUT,
            {},
            body
        );
    }

    async putFiles<T, V extends FormData>(url: string, body: V) {
        return this.#authenticatedRequest<T, V>(
            url,
            AvailableHTTPMethods.PUT,
            {},
            body
        );
    }

    async delete<T, V>(url: string, body?: V) {
        return this.#authenticatedRequest<T, V>(
            url,
            AvailableHTTPMethods.DELETE,
            {},
            body
        );

        // if (error) {
        //     return {
        //         error,
        //         code: response?.code || ERRORS.GENERAL_ERROR,
        //         ...response,
        //     } as ErrorResponse;
        // }

        // if (!response) {
        //     return { error: false, data: null };
        // }

        // return { error: false, data: response };
    }

    async publicGet<T>(url: string, headers: RequestHeaders) {
        return this.#publicRequest<T, undefined>(
            url,
            AvailableHTTPMethods.GET,
            headers || {}
        );
    }

    async publicPost<T, V>(url: string, body: V, headers?: RequestHeaders) {
        return this.#publicRequest<T, V>(
            url,
            AvailableHTTPMethods.POST,
            headers || {},
            body
        );
    }
}

const forceLogout = () => {
    sessionServices.cleanSessionData();
    location.reload();
};

const requestAdapterInstance = new RequestAdapter(
    axios.create(),
    axios.create(),
    forceLogout,
    sessionServices,
    `${environment?.apiUrl}/auth/refresh_token`
);

const sleep = (miliseconds: number) =>
    new Promise((r) => setTimeout(r, miliseconds));

/**
 * Checks if token is refreshing.
 *
 * If so, awaits some seconds in an interval. If after that interval token is not refreshed, then aborts request.
 *
 * If not, checks if it is close to expire. If so, tries to refresh token (on failure it logouts the user).
 *
 * @param config axios request current configuration.
 * @returns axios request new configuration.
 */
async function handleTokenIntegrity(config: InternalAxiosRequestConfig) {
    const maximumRetries = 10;
    const sleepTimeMs = 300;
    const requestController = new AbortController();

    // await for token to be refreshed by another request or timeout.
    if (requestAdapterInstance.isRefreshingToken) {
        let retry = 0;

        while (requestAdapterInstance.isRefreshingToken) {
            if (retry == maximumRetries) {
                requestController.abort(
                    'Request awaited too long for token to be refreshed'
                );
                break;
            }
            await sleep(sleepTimeMs);
            retry++;
        }

        const sessionData = requestAdapterInstance.sessionService.getUserData();

        let refreshedToken: string = '';

        if (sessionData) {
            refreshedToken = sessionData.token;
        }

        return {
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${refreshedToken}`,
            } as InternalAxiosRequestConfig['headers'],
            signal: requestController.signal,
        };
    }

    const sessionData = requestAdapterInstance.sessionService.getUserData();
    let accessTokenExpiration: number = 0;
    let refreshTokenExpiration: number = 0;
    const sessionThreshold = 1 * 60 * 1000; // one minute

    if (sessionData) {
        accessTokenExpiration = sessionData.expires_in;
        refreshTokenExpiration = sessionData.refresh_expires_in;
    }

    const now = Date.now();
    const tokenExpired = accessTokenExpiration - now <= sessionThreshold;
    const refreshTokenExpired =
        refreshTokenExpiration - now <= sessionThreshold;

    // session has been completely run out.
    if (refreshTokenExpired) {
        requestController.abort('Refresh token has expired');
        requestAdapterInstance.logout();
        return { ...config, signal: requestController.signal };
    }

    // session still possible to renew.
    if (tokenExpired) {
        try {
            requestAdapterInstance.isRefreshingToken = true;

            const { token, ...restOfSessionData } =
                await requestAdapterInstance.getNewSession();

            requestAdapterInstance.sessionService.writeSessionData({
                token,
                ...restOfSessionData,
            });

            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                } as InternalAxiosRequestConfig['headers'],
            };
        } catch (error) {
            // not logged in
            requestAdapterInstance.logout();
            throw error;
        } finally {
            requestAdapterInstance.isRefreshingToken = false;
        }
    }

    // session ok.
    return config;
}

async function handleTokenIntegrityError(error: unknown) {
    return Promise.reject(error);
}

RequestAdapter.configureInterceptors(
    requestAdapterInstance,
    handleTokenIntegrity,
    handleTokenIntegrityError
);

export default requestAdapterInstance;
