import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { ProjectContext } from '~/context/project';
import {
    assignColorsToEntities,
    EntityColorMap,
} from '~/helpers/assignColorsToEntities';
import groupTagsByFolder from '~/helpers/groupTagsByFolder';
import transformTagGroupWithItems from '~/helpers/transformTagGroupWithItems';
import { useEntityContext, useFloatMenu, useOpenFolders } from '~/hooks';
import { EntitySortingParameters } from '~/interfaces/common';
import { ProjectProviderInterface } from '~/interfaces/contexts';
import { TagsProviderInterface } from '~/modules/Tags/types/TagsContext.interface';
import { TagGroupInterfaceDetailed } from '~/interfaces/entities';
import { TagInterfaceDetailed } from '~/modules/Tags/types/Tag.interface';
import { tagsServices } from '~/services';
import { getTagColor as getTagColorUtil } from '../../helpers/getTagColor';
import { UNCATEGORIZED_FOLDER_NAME } from '~/constants/folders';
import { ColumnValueOption } from '~/interfaces/columnValues/ColumnFilterContext.interface';

export const TagsContext = createContext<TagsProviderInterface | null>(null);

TagsContext.displayName = 'TagsContext';

const TagsProvider = ({ children }: PropsWithChildren) => {
    const { selectedProject } =
        useEntityContext<ProjectProviderInterface>(ProjectContext);

    const [tags, setTags] = useState<TagInterfaceDetailed[]>([]);

    const [tagsLoaded, setTagsLoaded] = useState(false);

    const [folderStructure, setFolderStructure] = useState<
        TagGroupInterfaceDetailed[] | null
    >(null);
    const { openedFolders, openFolder } = useOpenFolders();

    const [sorting, setSorting] = useState<EntitySortingParameters>({
        field: 'name',
        direction: SORT_TYPE_OPTIONS.ASC,
    });

    const [loading, setLoading] = useState<boolean>(true);

    const [folderTagsColors, setFolderTagsColors] = useState<EntityColorMap>(
        {}
    );
    // dialog to create new tag
    const tagMenu = useFloatMenu();

    useEffect(() => {
        if (!selectedProject) {
            setLoading(false);

            // edge case: when user changes the workspace and the selected one has no projects --> selectedProject is null
            // but states as tags, etc. are filled up
            if (tags.length) resetMainStates();
            return;
        }
        getTags();
        getFolderStructure({});
        setFolderTagsColors({});
    }, [selectedProject?.id]);

    const getTags = async (appliedSorting?: EntitySortingParameters) => {
        setLoading(true);
        setTagsLoaded(false);

        if (!selectedProject) return;

        const [error, response] = await tagsServices.getTags({
            all: true,
            sort_order: appliedSorting
                ? appliedSorting.direction
                : sorting.direction,
            sort_attribute: appliedSorting
                ? appliedSorting.field
                : sorting.field,
            project_ids: selectedProject.id,
        });

        await new Promise<void>((resolve) =>
            setTimeout(() => {
                setLoading(false); // add a very little delay to avoid flickering
                resolve();
            }, 200)
        );

        if (error) return;
        const { data } = response;
        setTagsLoaded(true);
        setTags(data);
    };

    const getFolderStructure = async (prevFolderColors?: EntityColorMap) => {
        if (!selectedProject) return;

        const [error, response] = await tagsServices.getTagFolders({
            project_ids: selectedProject.id,
            all: true,
            sort_order: SORT_TYPE_OPTIONS.ASC,
            sort_attribute: 'name',
        });

        if (error) return;

        const { data } = response;

        setFolderStructure(data);

        const folderIds = data.map((f) => f.id);

        const alreadyAppliedColors = prevFolderColors || folderTagsColors;

        const colorsToApply = assignColorsToEntities(
            [...folderIds, UNCATEGORIZED_FOLDER_NAME],
            alreadyAppliedColors
        );

        setFolderTagsColors(colorsToApply);
    };

    const handleSorting = (sort: EntitySortingParameters) => {
        if (
            sort.field === sorting.field &&
            sort.direction === sorting.direction
        )
            return;

        setSorting(sort);
        getTags(sort);
    };

    const tagOptions: ColumnValueOption[] = useMemo(
        () =>
            tags.map((t) => ({
                label: t.name,
                value: t.id,
            })),
        [tags]
    );

    const resetMainStates = () => {
        setTags([]);
        setFolderStructure(null);
    };

    const groupedTags = useMemo(() => {
        if (!folderStructure) return [];
        const groupedTags = groupTagsByFolder(tags, folderStructure);

        return groupedTags;
    }, [folderStructure, tags]);

    const getTagColor = useCallback(
        (tagId: string) => getTagColorUtil(folderTagsColors, tags, tagId),
        [tags, folderTagsColors]
    );

    // we have to modify the items interface to match the one from the TagsPicker. {value, label}
    const transformedFolderStructure = useMemo(
        () => transformTagGroupWithItems(groupedTags),
        [groupedTags]
    );

    return (
        <TagsContext.Provider
            value={{
                loading,
                tags,
                sorting,
                tagMenu,
                tagOptions,
                folderTagsColors,
                groupedTags,
                openedFolders,
                transformedFolderStructure,
                tagsLoaded,
                handleTags: setTags,
                setLoading,
                getTags,
                handleSorting,
                getTagColor,
                getFolderStructure,
                openFolder,
            }}
        >
            {children}
        </TagsContext.Provider>
    );
};

export default TagsProvider;
