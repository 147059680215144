import './FilterMenu.scss';
import { Checkbox, TextField } from '../UIElements';
import { ArrowDown, ArrowUp, Search, X } from 'react-feather';
import { IntlShape } from 'react-intl';
import { SORT_TYPE_OPTIONS } from '~/constants/SortTypeOptions.enum';
import { FloatMenuInterface } from '~/hooks/useFloatMenu';
import { ColumnValueOption } from '../../interfaces/columnValues/ColumnFilterContext.interface';

const mainClass = 'cg-filter-menu';

interface FilterMenuParams {
    intl: IntlShape;
    sortFilter: SORT_TYPE_OPTIONS;
    onSort: (sort: SORT_TYPE_OPTIONS) => void;
    applyFilters: () => void;
    filterMenu: FloatMenuInterface;
    search: string;
    onChangeSearch: (search: string) => void;
    onChangeCheckbox: (option: ColumnValueOption, checked: boolean) => void;
    filteredValues: ColumnValueOption[];
    selectedValues: ColumnValueOption[];
    onSelectAll: (select: boolean) => void;
    showApplyButton: boolean;
    isFiltered: boolean;
    onResetFilter: () => void;
    isSortable?: boolean;
}

const FilterMenu: React.FC<FilterMenuParams> = ({
    intl,
    sortFilter,
    onSort,
    applyFilters,
    filterMenu,
    search,
    onChangeSearch,
    onChangeCheckbox,
    filteredValues,
    selectedValues,
    onSelectAll,
    showApplyButton,
    isFiltered,
    onResetFilter,
    isSortable = true,
}) => {
    return (
        <>
            <div className={`arrow-filter`} />
            <div className={mainClass}>
                <div className="filter-body">
                    {isSortable ? (
                        <div className="filter-sorting">
                            <div onClick={() => onSort(SORT_TYPE_OPTIONS.DESC)}>
                                <ArrowDown />
                                <span
                                    className={
                                        sortFilter === SORT_TYPE_OPTIONS.DESC
                                            ? 'filter-sorting__selected'
                                            : ''
                                    }
                                >
                                    {intl.formatMessage({
                                        id: 'sort_descending',
                                    })}
                                </span>
                            </div>
                            <div onClick={() => onSort(SORT_TYPE_OPTIONS.ASC)}>
                                <ArrowUp />
                                <span
                                    className={
                                        sortFilter === SORT_TYPE_OPTIONS.ASC
                                            ? 'filter-sorting__selected'
                                            : ''
                                    }
                                >
                                    {intl.formatMessage({
                                        id: 'sort_ascending',
                                    })}
                                </span>
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`search${
                            isSortable ? ' search__top-border' : ''
                        }`}
                    >
                        <form
                            onSubmit={() => {
                                applyFilters();
                                filterMenu.handleMenu();
                            }}
                        >
                            <TextField
                                iconBefore={Search}
                                inputProps={{
                                    value: search,
                                    placeholder:
                                        intl.formatMessage({
                                            id: 'search',
                                        }) + '...',
                                    onChange: (e) =>
                                        onChangeSearch(e.target.value),
                                }}
                            />
                        </form>

                        {filteredValues.length > 0 ? (
                            <>
                                <div className="extra-actions">
                                    <div className="extra-actions__select-deselect">
                                        <span onClick={() => onSelectAll(true)}>
                                            {intl.formatMessage({
                                                id: 'select_all',
                                            })}
                                        </span>
                                        <span
                                            onClick={() => onSelectAll(false)}
                                        >
                                            {intl.formatMessage({
                                                id: 'deselect_all',
                                            })}
                                        </span>
                                    </div>
                                    <div className="extra-actions__metadata">
                                        {selectedValues.length ? (
                                            <span>
                                                {`${selectedValues.length} ${intl.formatMessage(
                                                    {
                                                        id: 'selected_lowercase',
                                                    }
                                                )}`}
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="values-list">
                                    {filteredValues.map((val, idx) => {
                                        const isChecked = selectedValues.some(
                                            (v) => v.__id === val.__id
                                        );

                                        return (
                                            <div key={idx}>
                                                <Checkbox
                                                    checked={isChecked}
                                                    value={val.__id}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) =>
                                                        onChangeCheckbox(
                                                            val,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <p
                                                    onClick={() =>
                                                        onChangeCheckbox(
                                                            val,
                                                            !isChecked
                                                        )
                                                    }
                                                    title={val.label}
                                                >
                                                    {val.label}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <div className="text-center mt-3">
                                <span>
                                    {intl.formatMessage({
                                        id: 'no_matches_found',
                                    })}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="apply-cancel">
                    {showApplyButton && (
                        <div
                            className="apply"
                            onClick={() => {
                                applyFilters();
                                filterMenu.handleMenu();
                            }}
                        >
                            <span>
                                {intl.formatMessage({
                                    id: 'apply_filters',
                                })}
                            </span>
                        </div>
                    )}
                    {isFiltered && (
                        <div className="remove" onClick={onResetFilter}>
                            <span>
                                {intl.formatMessage({
                                    id: 'remove_filters',
                                })}
                            </span>
                        </div>
                    )}
                </div>

                <div onClick={filterMenu.handleMenu} className="filter-footer">
                    <span>{intl.formatMessage({ id: 'close' })}</span>
                    <X />
                </div>
            </div>
        </>
    );
};

export default FilterMenu;
