import { useEffect, useState } from 'react';
import './index.scss';
import { IntlShape, injectIntl } from 'react-intl';
import { authenServices } from '~/services';
import { Button, Spinner } from '../../components/UIElements';
import { ArrowLeft, Check, LogOut, X } from 'react-feather';
import { CognyIcon } from '../../assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../components/Router/paths';
import { AuthContextInterface } from '~/interfaces/contexts';
import { useEntityContext } from '~/hooks';
import { AuthContext } from '~/context/auth';
import { EXISTING_STATUSES } from '~/constants/existing_statuses.enum';

const AccountValidation = ({ intl }: { intl: IntlShape }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const { token } = useParams();
    const { updateUser, user } =
        useEntityContext<AuthContextInterface>(AuthContext);

    useEffect(() => {
        validate();
    }, []);

    const validate = async () => {
        if (!token) return;

        setLoading(true);

        const [error] = await authenServices.validateAccount(token);

        setLoading(false);

        if (error) {
            setError(true);
            return;
        }

        // update the localstorage in order to change user account status (to remove the banner)
        if (user) {
            const payload = {
                existing_status: EXISTING_STATUSES.ACTIVE,
                email_confirmed: true,
            };

            updateUser(payload);
        }
    };

    const homeLink = user ? PRIVATE_ROUTES.search : PUBLIC_ROUTES.login;

    return (
        <div className="dg-account-validation animate__animated animate__fadeIn">
            <CognyIcon />

            {loading ? (
                <Spinner width="70" height="70" />
            ) : (
                <div className="dg-account-validation__container">
                    <div className="dg-account-validation__status animate__animated animate__heartBeat">
                        <div>{error ? <X /> : <Check />}</div>
                    </div>

                    <h2>
                        {intl.formatMessage({
                            id: error ? 'general_error' : 'account_confirmed',
                        })}
                    </h2>

                    {!error && (
                        <p>
                            {intl.formatMessage({
                                id: 'account_confirmed_aclaration',
                            })}
                        </p>
                    )}

                    <div>
                        {user ? (
                            <Button
                                iconBefore={ArrowLeft}
                                variant="secondary"
                                onClick={() =>
                                    (window.location.href = homeLink)
                                }
                            >
                                {intl.formatMessage({ id: 'back_to_home' })}
                            </Button>
                        ) : (
                            <Button
                                iconBefore={LogOut}
                                variant="secondary"
                                onClick={() => navigate('/login')}
                            >
                                {intl.formatMessage({ id: 'go_to_sign_in' })}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const WrappedComponent = injectIntl(AccountValidation);

export default WrappedComponent;
