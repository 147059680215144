import React, { useEffect, useMemo, useRef, useState } from 'react';
import './index.scss';
import { useColumnsFilter, useEntityContext, useOpenFolders } from '~/hooks';
import { ChevronDown, Filter, Folder } from 'react-feather';
import { IntlShape } from 'react-intl';
import {
    ColumnValueOption,
    onApplyFilter,
    ColumnFilter as ColumnFilterInterface,
    resetFilterColumn,
} from '~/interfaces/columnValues/ColumnFilterContext.interface';
import { TopicsProviderInterface } from '~/modules/Topics/types/TopicsContext.interface';
import { TopicsContext } from '~/context/topics';
import FolderSelector from '~/components/UIElements/FolderSelector';
import BaseFloatMenu from '~/components/UIElements/BaseFloatMenu';
import BasePopover from '~/components/UIElements/BasePopover';
import { filterFoldersByItems } from '~/utils/traverseFolderTreeFunctions';
import Button from '~/components/UIElements/Button';
import TagsInput from '~/components/UIElements/TagsPicker/TagsInput';
import { PillVariants } from '~/components/UIElements/Pill/constants';

interface TopicColumnFilterParams {
    intl: IntlShape;
    resetFilterColumn: resetFilterColumn;
    columnFilters: ColumnFilterInterface[];
    onApplyFilter: onApplyFilter;
    columnValues: ColumnValueOption[];
    // sortParams: SortingParameters;
    sortFieldName?: string;
    headerName: string;
    columnName: string;
    showTopicLocationIcon: boolean;
    onToggleTopicLocation: () => void;
}

const mainClass = 'topic-column-filter';

const TopicColumnFilter: React.FC<TopicColumnFilterParams> = ({
    headerName,
    intl,
    columnName,
    resetFilterColumn,
    columnFilters,
    onApplyFilter,
    columnValues = [],
    // sortParams = {},
    sortFieldName,
    showTopicLocationIcon,
    onToggleTopicLocation,
}) => {
    const menuRef = useRef();

    const { filledFolderTree, topics, getTopicColor } =
        useEntityContext<TopicsProviderInterface>(TopicsContext);

    const { isFiltered, onResetFilter, filterMenu } = useColumnsFilter({
        columnName,
        resetFilterColumn,
        columnFilters,
        onApplyFilter,
        columnValues,
        sortParams: {},
        sortFieldName,
        isDynamicColumnFilter: false,
    });

    const [preselectedTopics, setPreselectedTopics] = useState<
        ColumnValueOption[]
    >([]);

    const [search, setSearch] = useState<string>('');
    const { openedFolders, openFolder } = useOpenFolders();

    const showApplyButton = preselectedTopics.length > 0;

    useEffect(() => {
        if (!isFiltered) {
            setPreselectedTopics([]);
            return;
        }

        // items that are already applied as filters
        const alreadyAppliedValues = columnFilters.find(
            (filter) => filter.column === columnName
        )?.value as ColumnValueOption[];
        setPreselectedTopics(alreadyAppliedValues);
    }, [filterMenu.showMenu]);

    const onApply = () => {
        onApplyFilter({
            column: columnName,
            values: preselectedTopics,
            sortBy: sortFieldName,
            isDynamicColumnFilter: false,
        });
        filterMenu.handleMenu();
    };

    // filter folder structure in order to show only the topics that are present in the column values
    const filteredFolderStructure = useMemo(() => {
        if (!filledFolderTree || !columnValues.length) return [];

        // also exclude the topics that are already preselected
        const topicsToFilterWith = columnValues
            .map((t) => t.value)
            .filter(
                (topId) =>
                    !preselectedTopics.some((preTop) => preTop.value === topId)
            );

        const filtered = filterFoldersByItems(
            structuredClone(filledFolderTree),
            topicsToFilterWith
        );

        return filtered;
    }, [filledFolderTree, columnValues, preselectedTopics]);

    const onSelectTopic = (topicId: string) => {
        const isAlreadyIncluded = preselectedTopics.some(
            (t) => t.value === topicId
        );

        if (isAlreadyIncluded) return;

        const topic = topics.find((t) => t.id === topicId);

        if (!topic) return;

        setPreselectedTopics([
            ...preselectedTopics,
            {
                label: topic.name,
                value: topic.id,
                __id: topic.id,
            },
        ]);

        setSearch('');
    };

    const getTopiColorById = (topicId: string) => {
        const topic = topics.find((t) => t.id === topicId);

        if (!topic) return PillVariants.SECONDARY;

        return getTopicColor(topic);
    };

    return (
        <div ref={menuRef} className={mainClass}>
            <BasePopover
                isOpen={filterMenu.showMenu}
                positions={['bottom', 'left', 'right']}
                align="start"
                content={
                    <BaseFloatMenu animated={false} top={15}>
                        <div className={mainClass + '__body'}>
                            <div className={mainClass + '__body__preselection'}>
                                <TagsInput
                                    canAddNewTag={false}
                                    value={preselectedTopics}
                                    onChange={setPreselectedTopics}
                                    placeholder={
                                        intl.formatMessage({ id: 'search' }) +
                                        '...'
                                    }
                                    inputValue={search}
                                    onChangeInputValue={setSearch}
                                    // disabled={disabled || loading}
                                    disableEnterKey={true}
                                    getTagColorCustomFn={getTopiColorById}
                                />
                            </div>
                            <FolderSelector
                                structure={filteredFolderStructure}
                                // value={null}
                                onConfirm={onSelectTopic}
                                onCancel={() => filterMenu.setShowMenu(false)}
                                expandedFolders={openedFolders}
                                onExpandFolder={openFolder}
                                placeholder={intl.formatMessage({
                                    id: 'search_folders_and_topics',
                                })}
                                confirmButtonText={intl.formatMessage({
                                    id: 'select',
                                })}
                                showFiles
                                showUncategorizedFolder
                                showSearchBar={false}
                                loading={false}
                                size="small"
                                selectDirectly
                                customSearchValue={search}
                            />
                            <div className={mainClass + '__body__btns'}>
                                {isFiltered && (
                                    <Button
                                        onClick={() => {
                                            onResetFilter();
                                            filterMenu.setShowMenu(false);
                                        }}
                                        variant="secondary"
                                    >
                                        {intl.formatMessage({
                                            id: 'remove_filters',
                                        })}
                                    </Button>
                                )}
                                {showApplyButton && (
                                    <Button onClick={onApply}>
                                        {intl.formatMessage({ id: 'apply' })}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </BaseFloatMenu>
                }
                parentContainer={menuRef.current}
                onClickOutside={() => filterMenu.setShowMenu(false)}
            >
                <div className={mainClass + '__header'}>
                    <span> {intl.formatMessage({ id: headerName })} </span>
                    <div>
                        {showTopicLocationIcon && (
                            <div
                                title={intl.formatMessage({
                                    id: 'show_topic_location_column',
                                })}
                            >
                                <Folder onClick={onToggleTopicLocation} />
                            </div>
                        )}
                        {isFiltered ? (
                            <Filter onClick={filterMenu.handleMenu} />
                        ) : (
                            <ChevronDown onClick={filterMenu.handleMenu} />
                        )}
                    </div>
                </div>
            </BasePopover>
        </div>
    );
};

export default TopicColumnFilter;
