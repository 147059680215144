export function compareStringsAlphabetically(a: string, b: string) {
    const normalisedA = a.toLowerCase();
    const normalisedB = b.toLowerCase();
    {
        if (normalisedA < normalisedB) {
            return -1;
        }
        if (normalisedA > normalisedB) {
            return 1;
        }
        return 0;
    }
}
